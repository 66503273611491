import { ChatChannelsProvider } from './../../providers/chat-channels/chat-channels';
import { ImagesProvider } from './../../providers/images/images';
import { Child } from './../../models/child';
import { Component, Input } from '@angular/core';
import { ChatChannel } from '../../models/chat-channel';
import { Image } from '../../models/image';
import { Person } from '../../models/person';
import { PeopleProvider } from '../../providers/people/people';
import * as moment from 'moment';
import { SyncedDataProvider } from "../../providers/synced-data/synced-data";


/**
 * Generated class for the ChildBubbleComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'child-bubble',
  templateUrl: 'child-bubble.html',
  styleUrls: ['./child-bubble.scss'],
})
export class ChildBubbleComponent {

  // Required
  @Input('child')
  public child: Child = new Child();

  // Mode can be
  // blank - regular child
  // 'add' - add new child button
  @Input('mode')
  public mode: string = '';

  // Required for showing notification counts
  @Input('chatChannel')
  public chatChannel: ChatChannel = new ChatChannel();

  @Input('image')
  public image: Image = new Image();

  @Input('person')
  public person: Person = new Person();

  text: string;
  public notificationCount: number = 0;

  constructor( public images : ImagesProvider,
               public people: PeopleProvider,
               public chatChannels: ChatChannelsProvider,
               public syncedData: SyncedDataProvider ) {
  }

  ngOnInit(){
    var vm = this;
    if(this.child.id > 0){
      // Retrieve image if required
      if(this.chatChannel.id == null){
        this.getChatChannel();
      }
      if(this.person.id == null){
        this.getPerson().then( person => {
          vm.getImage();
        });
      }else{
        vm.getImage();
      }
    }
  }


  private getPerson(){
    var vm = this;
    return this.people.relation(this.child.person).then( person => {
      vm.person = person;
      return person;
    })
  }

  private getChatChannel(){
    var vm = this;
    console.log(this.child);
    let chatChannel = this.syncedData.getChannel(this.child["chat-channel"].id);
    vm.chatChannel = chatChannel || vm.chatChannel;
    this.notificationCount = vm.chatChannel['unread-count'];
    return chatChannel;
  }

  private getImage(){
    var vm = this;
    if(vm.person.image) {
      return this.images.relationCached(vm.person.image, moment().add(1, 'hour').toDate()).then(image => {
        vm.image = image;
        console.log(image);
        return image;
      })
    }
  }
}
