import { Image } from './../../models/image';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PhotoDetailPage } from '../../pages/photo-detail/photo-detail';

/**
 * Generated class for the PhotoComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'photo',
  templateUrl: 'photo.html'
})
export class PhotoComponent {

  @Input('image')
  image: Image;

  constructor(public modalCtrl: ModalController) {
  }


  async showDetail(){
    let modal = await this.modalCtrl.create({

      component: PhotoDetailPage, 
      componentProps: {'image' : this.image} 
    });
    // modal.onDidDismiss(() => {
    //   // console.log("Closed");
    // });
    await modal.present();
  }
}
