import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';

/*
 Generated class for the CacheProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */

/*
 TODO: Implement expiry into storage
 TODO: Check for expiration date when getting an item
 TODO: Clear item from cache if expire date has been reached
 TODO: clearAll() should only clear namespace
 TODO: clearAll(true) should clear all cache elements
 TODO: implement saneKey for all keys to generate human-readable keys

 LOW PRIORITY:
 TODO: Implement counters for cache hits / cache misses
 */

@Injectable()
export class CacheProvider {

  // This almost always becomes jsonapi-provider since that class never releases the namespace.
  private namespace: String   = "default";
  public uniquePrefix: string = '';

  constructor(private store: Storage) {
  }


  public get(key: string) {
    return this.expired(key).then((expired) => {
      let rc = new Promise((res, rej) => {
        res(null)
      });
      if (!expired) {
        rc = this.store.get(this.getKey(key));
      }
      return rc;
    })

  }

  public set(key: string, data: any, expiration: Date) {
    let vm = this;
    console.debug("Setting cache item " + this.getKey(key));

    // this.setExpiration(key, data);
    return this.store.set(this.getKey(key), data).then((data) => {
      // Set expiration
      return vm.setExpiration(key, expiration).then(() => {
        return data;
      });
    });
  }

  public exists(key: string) {
    return this.store.get(this.getKey(key)).then((data) => {
      return (data) != null;
    });
  }

  public clear(key: string) {
    return this.store.remove(this.getKey(key));
  }

  /**
   * Clears everything from storage if everything = true.
   * @param everything
   */
  public clearAll(everything: boolean) {
    if (everything) {
      return this.store.clear();
    }
  }

  public setNamespace(namespace: string) {
    this.namespace = namespace;
  }

  private getKey(key: string) {
    return this.uniquePrefix + "_" + this.namespace + "_" + key;
  }

  // Remove any unwanted characters and replace them with others
  public saneKey(key: string) {
    return key;
  }


  // Check cache records
  private expired(key) {
    return this.getItem(key).then((item) => {
      let rc = true;

      if (item['expiration'] != null) {
        rc = moment(item['expiration']).isBefore(moment());
      }
      return rc;
    })
  }

  private setExpiration(key, date) {
    let vm = this;
    return vm.getItem(key).then((item) => {
      item['expiration'] = date;
      return vm.setItem(key, item).then(() => {
        return true;
      });
    });

  }

  /* Cache related information */
  private getItem(key) {
    return this.getCacheInfo().then((cacheInfo) => {
      let rc = {};
      if (cacheInfo[key] != null) {
        rc = cacheInfo[key];
      }
      return rc;
    })
  }

  private setItem(key, data) {
    return this.getCacheInfo().then((cacheInfo) => {
      cacheInfo[key] = data;
      return this.storeCacheInfo(cacheInfo);
    })
  }


  private storeCacheInfo(cacheInfo) {
    return this.store.set(this.getKey('__cache'), cacheInfo);
  }

  private getCacheInfo() {
    return this.store.get(this.getKey('__cache')).then((data) => {
      let rc = {};
      if (data != null) {
        rc = data;
      }
      return rc;
    })
  }
}
