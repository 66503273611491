import { ChildAbsence } from './../../models/child-absence';
import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Transformer } from './../../interface/transformer';
import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import * as moment from 'moment';

/*
Generated class for the ChildPresenceProvider provider.

See https://angular.io/guide/dependency-injection for more info on providers
and Angular DI.
*/
@Injectable()
export class ChildAbsenceProvider extends JsonApiHelpProvider implements Transformer {
  
  constructor( public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/child-absences";
  }
  
  transform(data: JsonAPI){
    
    let rc;
    if(data.multiple()){
      // Transform multiple objects
      rc = [];
      data.data.forEach(channel => {
        
        let tmp = this.generate(channel);
        rc.push(tmp);
      });
      
    }else{
      // Transform one object
      rc = this.generate(data.data);
    }
    
    return rc;
  }
  
  // Report a child absence
  public report( absence : ChildAbsence) {
    // Convert into object
    let vm = this;
    let data = {
      "data": {
        "type": "child-absences",
        "attributes": {
          "from-date": moment(absence['from-date']).format("YYYY-MM-DD"),
          "to-date": moment(absence['to-date']).format("YYYY-MM-DD"),
          "from-time": moment(absence['from-date']).format("HH:mm:ss"),
          "to-time": moment(absence['to-date']).format("HH:mm:ss"),
          "absence-type": absence["absence-type"]
        },
        "relationships": {
          "child": {
            "data": {
              "type": "children",
              "id": absence.child.id
            }
          },
          "group": {
            "data": {
              "type": "groups",
              "id": absence.group.id.toString()
            }
          },
          "day-line":{
            "data": {
              "type": "day-lines",
              "id": absence.dayline.id
            }
          }
        }
      }
    }
        
    return this.api.post("/v1/request-child-absence-v2", data).then(data => {
      return vm.transform(data);
    }, err => {
      console.log(err)
      alert(err)
      return err;
    })
    
  }
  
  // Fill based on input
  protected generate(data){
    // let chatchannel = new ChatChannel();
    // let attributes = data.attributes;
    // attributes.id = data.id;
    // chatchannel.fill(attributes);
    
    // Set relationships for each
    //child.person = new HasOne(this.api, data.relationships.person);
    // user.relation = new HasOne(this.api, data.relationships.relation);
    return;
  }
  
  
}
