import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import { Transformer } from '../../interface/transformer';
import { Image } from '../../models/image';
import { Person } from '../../models/person';
import * as moment from 'moment';

/*
  Generated class for the ImagesProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ImagesProvider extends JsonApiHelpProvider implements Transformer {

  constructor( public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/images";
  }

  public getDiaryEventImageCached( id : number ){
    return this.api.getCached(this.baseEndpoint + "/" + id, moment().add(1,'hour').toDate()).then((data) => {
      return this.transform(data);
    })
  }

  transform(data: JsonAPI){

      let rc;
      if(data.multiple()){
        // Transform multiple objects
        rc = [];
        data.data.forEach(image => {

          let tmp = this.generate(image);
          rc.push(tmp);
        });

      }else{
        // Transform one object
        rc = this.generate(data.data);
      }

      return rc;
    }

    // Fill based on input
    protected generate(data){
      let image = new Image();
      let attributes = data.attributes;
      attributes.id = data.id;
      image.fill(attributes);

      return image;
    }

    public getImage(person : Person) {
      if(person.image) {
        return this.relationCached(person.image, moment().add(1, 'hour').toDate()).then(image => {
          if(image) {
            return image;
          }else{
            return '/assets/imgs/person-placeholder.png';
          }
        })
      }else {
        return new Promise(function(resolve) {
          resolve('/assets/imgs/person-placeholder.png');
        });
      }
    }

}
