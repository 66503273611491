import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import * as moment from 'moment';
import Relator from '../../helpers/relator/relator';
import { Availability } from '../../models/availability';
import { Child, ChildAPIInterface } from '../../models/child';
import { ExternalCredit } from '../../models/external-credit';
import { Group } from '../../models/group';
import { JsonAPI } from '../../models/jsonapi/jsonapi';
import { Movie } from '../../models/movie';
import { StrippenkartCredits } from '../../models/strippenkart-credits';
import { ApiProvider } from '../api/api';
import { ChildDayLinesProvider } from '../child-day-lines/child-day-lines';
import { GroupsProvider } from '../groups/groups';
import { ChildRequestInterface } from '@core/models/child-request';
import EmployeeDayLine from '@core/models/employee-day-line';
import { ConfigProvider } from '@core/providers/config/config';
import { DOCUMENT } from '@angular/common';
import { NavService } from '@core/providers/nav/nav.service';

/*
   Basically queries the WDC API. Can be replaced with a mock.
   You can convert JSON API data to a normal object using processApiData and processSingleApiData
 */
@Injectable()
export class WdcApiProvider {
  private locations: any = [];

  constructor(
    private http: HttpClient,
    public api: ApiProvider,
    private groups: GroupsProvider,
    private daylines: ChildDayLinesProvider,
    private groupsProvider: GroupsProvider,
    private config: ConfigProvider,
    @Inject(DOCUMENT) private document: Document,
    private navService: NavService,
  ) {
  }

  public getShard() {
    return this.config.get('API_SHARD');
  }

  public getGroup(id) {
    return this.groupsProvider.getFromId(id).then(data => {
      return data;
    });
  }

  public getCombinedChildGroups(child) {
    return new Promise(resolve => {
      let group1 = [];
      let group2 = [];
      let combined = [];
      this.getChildGroups(child.id).then(data => {
        group1 = data;
        this.getChildGroupsFromDaylines(child).then(data2 => {
          group2 = data2;
          combined = [...group1];

          // Combine
          Relator.mergeOnceBy(group2, combined, 'name');
          resolve(combined);
        });
      });
    });
  }

  public getChildGroups(child_id) {
    return this.api.get('/v1/children/' + child_id + '/groups').then((data) => {
      const japi = new JsonAPI().fill(data);
      // Uncomment If you want to test child as TSO
      // japi.data[0]['attributes']['care-type'] = 'tso';
      return this.groups.transform(japi);
    });
  }

  public getChildGroupsFromDaylines(child) {
    // Load the daylines from 1 month ago to 1 month later.
    const oneMonthAgo = moment().subtract(1, 'months').startOf('month');
    const oneMonthLater = moment().add(1, 'months').endOf('month');

    return this.daylines.forChild(child, oneMonthAgo.toDate(), oneMonthLater.toDate()).then(data => {
      if (data) {
        return this.fillGroupsByGuessing(data).then((groups) => {
          let returned_groups = groups as Array<Group>;
          return returned_groups;
        });
      } else {
        console.log('This is not a real user, and thus, has no calendar support.');
        return [];
      }
    });
  }

  private fillGroupsByGuessing(data) {
    const vm = this;

    let rc = new Promise((res, rej) => {
      // Get all id's
      let groupIds = [];

      data.forEach((line) => {
        // Check each line if the group is already in the array
        if (groupIds.indexOf(line['group-id']) < 0) {
          groupIds.push(line['group-id']);
        }
      });

      let p = [];
      groupIds.forEach((id) => {
        p.push(this.groupsProvider.getFromId(id));
      });

      // Retrieve all groups we don't know info about yet
      Promise.all(p).then((data) => {
        res(data);

        // Retrieve all locations we don't know about yet
        vm.api.get('/v1/locations').then(data => {
          vm.locations = data['data'];
        });
      });
    });

    return rc;
  }

  public getStrippenKartCredits(child: Child) {
    return this.api.get('/v1/children/' + child.id + '/strip-credits').then(data => {
      if (data['data']) {
        return data['data'] as Array<StrippenkartCredits>;
      } else {
        return [];
      }
    });
  }

  /**
   * @return Promise<Array<School>>
   */
  public getSchools() {
    return this.api.get('/v1/schools').then(data => {
      return this.processApiData(data);
    });
  }

  /**
   * Converts a JSON data mapping with attributes to a normal one.
   * @param data
   * @return Array
   */
  private processApiData(data) {
    if (data['data']) {
      console.log('here');
      let contents = [];
      for (const datum of data['data']) {
        datum['attributes']['id'] = datum['id'];
        contents.push(datum['attributes']);
      }
      return contents;
    } else {
      return [];
    }
  }

  private processApiDataWithFrontId(data) {
    if (data['data']) {
      console.log('here');
      let contents = [];
      for (const datum of data['data']) {
        contents.push(datum['attributes']);
      }
      return contents;
    } else {
      return [];
    }
  }

  private processSingleApiData(data) {
    if (data['data']) {
      data['data']['attributes']['id'] = data['data']['id'];
      return data['data']['attributes'];
    }
    return [];
  }

  public getChildRaw(child_id) {
    return this.api.get('/v1/child/' + child_id);
  }

  public getParentRaw(child_id) {
    return this.api.get('/v1/child/' + child_id);
  }

  public getChild(child_id): Promise<ChildAPIInterface> {
    return this.api.get('/v1/child/' + child_id).then(data => {
      return data['data'];
    });
  }

  /**
   *
   * @param movie_id
   * @return Promise<<Movie>
   */
  public getMovie(movie_id) {
    return this.api.get('/v1/movies/' + movie_id).then(data => {
      return this.processSingleApiData(data);
    });
  }

  public getChildRequest(id): Promise<ChildRequestInterface> {
    return this.api.get('/v1/child-requests/' + id).then(data => {
      return this.processSingleApiData(data);
    });
  }

  public getMovies(input, cursor, page = 1, direction = 'desc', field = 'created_at', limit = 10) {
    let vm = this;
    let filter = this.buildFilter(input);
    let _cursor = cursor;
    let _direction = direction || 'desc';
    let _field = field || 'created_at';
    let _limit = limit || 10;
    let _page = page || 1;

    return this.api.get('/v1/movies/' + '?cursor=' + encodeURIComponent(_cursor) + '&cursor_direction=' + _direction + '&cursor_field=' + _field + '&limit=' + _limit + '&page=' + _page + '&' + filter).then(filterData => {
      return vm.processApiData(filterData) as Array<Movie>;
    }, err => {
      return [];
    });
  }

  public buildFilter(input) {
    let items = [];

    for (let key in input) {
      let keyname = '[' + key + ']';
      if (input[key] instanceof Array) {
        input[key].join(',');
      }
      let value = input[key];

      let str = 'filter' + encodeURIComponent(keyname) + '=' + encodeURIComponent(value);
      items.push(str);
    }

    return items.join('&');
  }

  public getAvailabilitiesForLocationId(location_id: number) {
    return this.api.get('/v1/locations/' + location_id + '/availabilities').then(data => {
      return this.processApiData(data) as Array<Availability>;
    });
  }

  public getSwapSettings() {
    return this.api.get('/v1/swap-settings');
  }

  public getTermsAndConditions(child) {
    return this.api.get(`/v1/children/${child.id}/organisation`).then(res => {
      return res['data']['attributes']['terms-and-conditions'];
    });
  }

  public guessTermsAndConditions() {
    return this.api.get('/v1/children/').then(data => {
      const id = data['data'][0]['id'];
      return this.api.get(`/v1/children/${id}/organisation`).then(res => {
        return res['data']['attributes']['terms-and-conditions'];
      });
    });
  }

  public getCredits(child_id, group_id): Promise<ExternalCredit[]> {
    return this.api.get(`/v1/children/${child_id}/credits?group-id=${group_id}`).then((data) => {
      return this.processApiDataWithFrontId(data) as Array<ExternalCredit>;
    });
  }

  public getEmployeeDaylines(group_id, date_from, date_to) {
    return this.api.get(`/v1/groups/${group_id}/employee-day-lines?date-from=${date_from}&date-to=${date_to}`).then((data) => {
      return this.processApiData(data) as Array<EmployeeDayLine>;
    });
  }

  public getEmployeesFromArray(emparr: number[] = []) {
    return this.api.get(`/v1/employees?filter[id]=${emparr.join()}&include=people`).then(data => {
      return this.processApiData(data);
    });
  }

  public getPeopleFromArray(peoplearr: number[] = []) {
    return this.api.get(`/v1/people?filter[id]=${peoplearr.join()}&include=image`).then(data => {
      for (let i = 0; i < data['data'].length; i++) {
        data['data'][i]['attributes']['image'] = data['included'][i];
      }
      return this.processApiData(data);
    });
  }

  public populateImagesFromPerson(person) {
    this.api.get(`/v1/people/${person.id}/image`).then(data => {
      console.log(data);
    });
  }

  public getChatChannel(id) {
    return this.api.get(`/v1/chat-channels/${id}`).then(data => {
      return this.processSingleApiData(data);
    });
  }

  public getPerson(id) {
    return this.api.get(`/v1/people/${id}`).then(data => {
      return this.processSingleApiData(data);
    });
  }

  private marginFix = '44px';

  processHeader() {
    console.log('here');
    if (this.navService.isCordova) {
      console.log('here');
      const header = this.document.querySelector('ion-header');
      if (header) {
        this.marginFix = `${header.offsetHeight}px`;
        const routerOutler = this.document.querySelector('ion-router-outlet');
        routerOutler.style.marginTop = this.marginFix
        window.setTimeout(() => {
          routerOutler.style.marginTop = this.marginFix
        }, 2000);
        window.setTimeout(() => routerOutler.style.marginTop = this.marginFix, 3000);
        window.setTimeout(() => routerOutler.style.marginTop = this.marginFix, 4000);
      }
    }
  }
}
