import * as moment from "moment";
import { ExternalFileAttachment } from "./external-file-attachment";

export class ExternalFile{

  public 'id'           : number;
  public 'name'         : string;

  public 'date'             : Date; // 20-09-2016 DD-MM-YYYY
  public 'document-type'    : string;
  public 'external-id'      : number;
  public 'relation-id'      : number;

  public attachment         : ExternalFileAttachment;

  public fill(data){
    this.id 						      = data.id;
    this['name'] 			    	  = data['name'];
    this['date']              = moment(data['date'], 'DD-MM-YYYY').toDate();
    this['document-type']     = data['document-type'];
    this['external-id']       = data['external-id'];
    this['relation-id']       = data['relation-id'];

    return this;
  }

}
