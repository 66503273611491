// import { Events } from '@ionic/angular';
// import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Injectable, OnDestroy } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, PushNotificationSchema, ActionPerformed, Token } from '@capacitor/push-notifications';
import { WdcApiProvider } from '@core/providers/wdc-api/wdc-api';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Subject, Subscription } from 'rxjs';
import { Notification } from 'src/app/models/notification';
import { AuthenticationProvider } from '../authentication/authentication';
import { ChildrenProvider } from '../children/children';
import { JsonapiProvider } from '../jsonapi/jsonapi';
import { LoadingProvider } from '../loading/loading';
import { NavService } from '../nav/nav.service';
import { PeopleProvider } from '../people/people';
import { SyncedDataProvider } from '../synced-data/synced-data';
import { UsersProvider } from '../users/users';

@Injectable()
export class NotificationProvider implements OnDestroy {
  /* tslint:disable:no-unused-variable */
  public token: string;
  /* tslint:enable:no-unused-variable */
  private isApp: boolean;
  public notifications = new Subject<Notification>();

  private notificationsSub = new Subscription();
  private fcmSub           = new Subscription();

  private isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

  constructor(
    // private fcm: FirebaseX,
    private platform: Platform,
    // private events: Events,
    private api: JsonapiProvider,
    private users: UsersProvider,
    private authentication: AuthenticationProvider,
    private storage: Storage,
    private loading: LoadingProvider,
    private children: ChildrenProvider,
    private people: PeopleProvider,
    private wdcapi: WdcApiProvider,
    public navService: NavService,
    private syncedData: SyncedDataProvider
  ) {
    let vm = this;
    vm.platform.ready().then((platform) => {
      vm.isApp = platform.indexOf['browser'];
    });
  }

  handleTokenRefresh() {
    let vm = this;

    // if (true) {
    //   vm.platform.ready().then(() => {
    //     vm.fcm.onTokenRefresh().subscribe((token) => {
    //       vm.users.self().then((user) => {
    //         if (user != null) {
    //           vm.bindTokenToUser(token, user.id);
    //         }
    //       });
    //     });
    //   });
    // }
  }

  registerDevice() {
    const vm = this;
    vm.platform.ready().then(() => {
      this.setupNotificationsHook();
      // console.info("platform ready!");
      // console.info("fcm register device");
      // this.fcm.hasPermission().then((data) => {
      //   if (data) {
      //     console.info("already has fcm permissions");
      //     vm.registerSyncedUser();
      //   } else {
      //     this.fcm.grantPermission().then((_) => {
      //       console.info("granted fcm permissions");
      //       vm.registerSyncedUser();
      //     });
      //   }
      // });
      console.info('----PUSHNOTIFICATIONPERMISSION----');

    });
  }

  registerSyncedUser(token: any) {
    const vm = this;
    console.info('Getting FCM token');
    // return vm.fcm.getToken().then(function (token) {
    //   console.info("FCM Token Received", token);
    vm.token = token;
    vm.storage.set('notificationToken', token);
    console.info('binding token to user');
    vm.users.self().then((user) => {
      if(user && user.hasOwnProperty('id')) {
        vm.bindTokenToUser(token, user.id).then((data) => {
          // vm.setupNotificationsHook();
        });
      }
    });
    // });
  }

  log(msg) {
    console.info(msg);
  }

  setupNotificationsHook() {
    const vm = this;
    vm.fcmSub.unsubscribe();
    this.isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        console.info('Push registration success, token: ' + token.value);
        this.registerSyncedUser(token.value);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.info('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.info('Push received: ' + JSON.stringify(notification));
        if (notification.data.type != null) {
          vm.notifications.next(
            new Notification('notifications:' + notification.data.type, notification.data)
          );
        } else {
          vm.notifications.next(new Notification('notifications:unknown', notification.data));
        }

      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.info(JSON.stringify(notification.notification));
        if (notification.notification.data.type != null) {
          notification.notification.data.wasTapped = true;
          vm.notifications.next(
            new Notification('notifications:' + notification.notification.data.type, notification.notification.data)
          );
        } else {
          vm.notifications.next(new Notification('notifications:unknown', notification.notification.data));
        }
        console.info('Push action performed: ' + JSON.stringify(notification));
      }
    );

    if (this.isPushNotificationsAvailable) {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive == "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        console.info('⭐️registering push notifs');
        PushNotifications.register().then(
          () => {
            vm.log('Push Notifications Success');
          }
        ).catch((e) => {
          console.info(e);
        });
      } else {
        // Show some error
        console.info('EEEEE: ERROR WITH PUSH NOTIFICATIONS', JSON.stringify(result))
      }
    });

    console.info('🐻setting up notifications hook');
    // vm.fcmSub = vm.fcm.onMessageReceived().subscribe((data) => {
    //   console.info("message received", data);
    //   console.info("FCM Notification received");
    //   // We shouldn't care if the data was tapped. Event should do something depending if the data was tapped or not
    //   // TOD: Fix this
    //   if (data.type != null) {
    //     vm.notifications.next(
    //       new Notification("notifications:" + data.type, data)
    //     );
    //   } else {
    //     vm.notifications.next(new Notification("notifications:unknown", data));
    //   }
    // });
    }
  }

  bindTokenToUser(token, user_id) {
    return this.api.post('/v1/fcm/register-token', {
      token  : token,
      user_id: user_id,
    }).then(() => {
      this.log('Bound token to user ' + token + ' ' + user_id);
    });
  }

  /**
   * If the user is logged in
   * and the user does not have a FCM token
   * Register FCM token for the user
   */
  ensureFCMTokenRegistered() {
    let vm = this;
    vm.authentication.isAuthenticated().then((authenticated) => {
      if (authenticated && !this.token) {
        vm.registerDevice();
      }
    });
  }

  ngOnDestroy(): void {
    this.notificationsSub.unsubscribe();
    this.fcmSub.unsubscribe();
    PushNotifications.removeAllListeners();
  }

  handlePushNotifications() {
    console.info('handling push notifications...');
    this.notificationsSub.unsubscribe();

    const vm = this;

    this.notificationsSub = this.notifications.subscribe((notif) => {
      console.info('NOTIFICATION:');
      console.info(notif);
      if (!notif) {
        return;
      }
      const notification = notif.data;
      window['notification'] = notif;
      vm.loading.showLoading('Bezig met laden...').then(() => {
        this.syncedData.initialSync().then(() => {
          vm.loading.dismissLoading()
          vm.loading.dismissLoading()
          switch (notif.type) {
            case 'notifications:DiaryEvent':
              if (notification.wasTapped || notification.tap) {
                // vm.loading.showLoading();
                vm.syncedData.initialSync().then(() => {
                  vm.children.getFromId(notification.child_id).then((rawChild) => {
                    const processedChild = rawChild;
                    const child = this.syncedData.getChild(notification.child_id);
                    this.navService.routeHistory = { origin: 'child/dagboek', destination: 'child', child: child};
                    setTimeout(() => {
                      vm.loading.dismissLoading();
                      this.navService.navRoot('child/dagboek', false, '', {
                        child: rawChild,
                      });
                    }, 1000)
                  });
                })
              }
              break;
            case 'notifications:MessageSentEvent':
              if (notification.wasTapped || notification.tap) {
                // vm.loading.showLoading()

                this.api.get('/v1/children/' + notification.child_id).then((data) => {
                  const cc = data.data.relationships['chat-channel']['data']['id'];
                  const p  = data.data.relationships['person']['data']['id'];
                  const child = this.syncedData.getChild(notification.child_id);
                  // this.navService.routeHistory = { origin: 'child/berichten/chat-channel', destination: 'child', child: child };
                  // chat-channel
                  this.wdcapi.getChatChannel(notification.chat_channel_id).then(c => {
                    // person
                    this.wdcapi.getPerson(p).then(per => {
                      console.info(c);
                      vm.loading.dismissLoading();
                      this.navService.navigateForward(
                        'child/berichten/chat-channel',
                        false,
                        '',
                        {
                          'chat-channel': c,
                          'child-name'  : vm.people.getFullName(
                            per
                          ),
                        }
                      );
                    });
                  });
                });
              }


              // Reload chatchannels
              // vm.sd
              //   .getChatChannels(children)
              //   .then((channels) => {
              //     return vm.sd.parseChatChannels(channels);
              //   })
              //   .then(() => {
              //     const chatChannel    = vm.sd.getChannel(
              //       notification.chat_channel_id
              //     );
              //     const processedChild = vm.sd.getChild(notification.child_id);
              //     this.notifications.next(
              //       new Notification('message:received', notification)
              //     );
              //
              //     // if (notification.wasTapped || notification.tap) {
              //     vm.loading.dismissLoading();
              //
              //     this.navService.navigateForward(
              //       'child/berichten/chat-channel',
              //       false,
              //       '',
              //       {
              //         'chat-channel': processedChild.associatedChannel,
              //         'child-name'  : vm.people.getFullName(
              //           processedChild.associatedPerson
              //         ),
              //       }
              //     );
              //     // }
              //   });
              // });
              break;
            case 'notifications:request-approved':
              if (notification.wasTapped || notification.tap) {
                // vm.loading.showLoading();
                const child = this.syncedData.getChild(notification.child_id);
                this.navService.routeHistory = { origin: `requests/${notification.child_id}`, destination: 'child', child: child };
                if (notification.status === 'accepted') {
                  this.navService.navigateRoot('/requests/' + notification.child_id);
                } else {
                  this.navService.navigateRoot('/requests/' + notification.child_id);
                }
              }
              break;
            default:
              console.warn('Unknown notification type recieved');
              console.warn(JSON.stringify(notification));
          }
        })
      })



    });
  }

  unregister() {
    PushNotifications.removeAllDeliveredNotifications();
    PushNotifications.removeAllListeners();
  }
}
