import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController, Platform } from "@ionic/angular";
import { NavService } from "../nav/nav.service";
// import { Media, MediaAlbum } from "@capacitor-community/media";
import { Media, MediaAlbum } from "@whiteguru/capacitor-plugin-media"
import { Filesystem, Directory } from "@capacitor/filesystem";
import * as moment from 'moment';
import { TranslationService } from "../translation/translation";

// const media = new Media();
const IMAGE_DIR = 'DayCare';

@Injectable()
export class PhotoDownloadProvider {
  downloadProgress: number = 0;

  constructor(
    public httpClient: HttpClient,
    private platform: Platform,
    private alertController: AlertController,
    public navService: NavService,
    private t: TranslationService
  ) { }

  public isMassDownloading = false // If true, will not show download finished message

  downloadPhotoFromUrl(url, album = "DayCare") {
    if (this.navService.isCordova) {
      return this.savePicture(url);
    } else {
      let link = document.createElement("a");
      link.target = "_blank";
      link.href = url;
      link.download = "daycare.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return new Promise((resolve => { resolve(true) }))
    }
  }

  downloadVideoFromUrl(url: string, album = "DayCare") {
    if (this.navService.isCordova) {
      this.saveVideo(url);
    } else {
      let link = document.createElement("a");
      link.target = "_blank";
      link.href = url;
      link.download = "daycare.mp4";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private async readAsBase64(url, type: any = null) {
    return new Promise<any>((resolve, reject) => {
      fetch(url, { cache: "no-cache" })
        .then(async (res) => {
          let blob = await res.blob();
          blob = new Blob([blob],type);
          this.convertBlobToBase64(blob).then((stream) => {
            resolve(stream)
          });
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }

  convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = this.getFileReader();
      reader.onerror = (err) => {
        reject(err);
      };
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)[
      "__zone_symbol__originalInstance"
    ];
    return zoneOriginalInstance || fileReader;
  }

  private async savePicture(url) {
    // Convert photo to base64 format, required by Filesystem API to save
    const base64Data = await this.readAsBase64(url);

    // Write the file to the data directory
    const fileName = new Date().getTime() + ".jpeg";
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });

    let albumName = "DayCare";

    // TODO: Unspagetti this later
    if (this.platform.is("ios")) {
      let albumBundle = await Media.getAlbums();

      let filtered = albumBundle.albums.filter((el: MediaAlbum) => {
        return el.name === albumName;
      });

      if (filtered.length === 0) {
        await Media.createAlbum({ name: albumName });

        albumBundle = await Media.getAlbums();

        filtered = albumBundle.albums.filter((el: MediaAlbum) => {
          return el.name === albumName;
        });
      }
      albumName = filtered.pop().identifier;
    }

    Media
      .savePhoto({ path: savedFile.uri, album: { name: albumName } })
      .then(async () => {
        if (!this.isMassDownloading) {
          const alert = await this.alertController.create({
            message: "Afbeelding opgeslagen",
            buttons: ["OK"],
          });
          alert.present();
        }
      })
      .catch(async (err) => {
        const alert = await this.alertController.create({
          message: err.toString(),
          buttons: ["OK"],
        });
        alert.present();
      });
  }

  private async saveVideo(url: string) {
    // const loading = await this.alertController.create({
    //   message: this.t._('Bezig met opslaan...')
    // });
    // loading.present();

    const modifiedUrl = url //.indexOf('?') >= 0 ? `${url}&ext=.mp4` : url;
    const base64Data = await this.readAsBase64(modifiedUrl, { type: 'video/mp4' });
    const fileName = `daycare-${moment().format('MMDDYYYYss')}.mp4`;

    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data
    });

    let albumName = 'DayCare';
    if (this.platform.is("ios")) {
      let albumBundle = await Media.getAlbums();
      let filtered = albumBundle.albums.filter((el: MediaAlbum) => {
        return el.name === albumName;
      });

      if (filtered.length === 0) {
        await Media.createAlbum({ name: albumName });

        albumBundle = await Media.getAlbums();

        filtered = albumBundle.albums.filter((el: MediaAlbum) => {
          return el.name === albumName;
        });
      }

      albumName = filtered.pop().identifier;
    }

    console.log("SAVEDFILE URI: ", savedFile.uri);

    Media.saveVideo({ path: savedFile.uri, album: { name: albumName } })
      .then(async () => {
        // loading.dismiss();
        const alert = await this.alertController.create({
          message: "Video opgeslagen",
          buttons: ["OK"],
        });
        alert.present();
      })
      .catch(async (err) => {
        const alert = await this.alertController.create({
          message: err.toString(),
          buttons: ["OK"],
        });
        alert.present();
      })
  }
}
