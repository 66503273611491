
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ApiProvider } from '../api/api';

/*
 Generated class for the ChatMessagesProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable()
export class ChildPermissionsProvider {

    constructor(public api: ApiProvider) {
    }

    //Fill based on input 
    protected generate(data) {
        return data;
    }

    getPermissions(child_id: number): Observable<any> {
        return from(this.api.get(`/v1/children/${child_id}/permissions`));
    }

    getChildPermissions(child_id: number): Observable<any> {
        return from(this.api.get(`/v1/children/${child_id}/child-permissions`));
    }

    updateChildPermission(response: string, permission_id: any): Observable<any> {
        const body = {
            data: {
                id: permission_id,
                type: "child-permissions.update",
                attributes: {
                    value: response
                }
            }
        }
        return from(this.api.put(`/v1/child-permissions/${permission_id}`, body))
    }


}
