import { HasOne } from "./jsonapi/hasone";
import { HasMany } from "./jsonapi/hasmany";

//import { HasOne } from './jsonapi/hasone';
// THIS IS DIFFERENT THAN IMAGE (for what ever reason)

export class Photo{

    public 'id' : number;
    public 'child-id' : number;
    public 'eventable-id' : number;
    public 'eventable-type' : string;
    public 'title' : string;
    public 'updated-at' : Date;
    public 'created-at' : Date;

    public 'image' : HasOne; // Deprecated in later stadium
    public 'images' : HasMany;

	
	public fill(data){
        this.id 			    = data.id;
        this.title              = data.title;
        this['eventable-id']    = data['eventable-id'];
        this['eventable-type']  = data['eventable-type'];
        this['child-id']        = data['child-id'];
        this['created-at']      = data['created-at'];
        this['updated-at']      = data['updated-at'];
        
		return this;
	}

	

	

}
