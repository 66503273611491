import { Transformer } from "./../../interface/transformer";
import { HasOne } from "./../../models/jsonapi/hasone";
import { JsonAPI } from "./../../models/jsonapi/jsonapi";
import { JsonapiProvider } from "./../jsonapi/jsonapi";
import { Injectable } from "@angular/core";
import { Child } from "../../models/child";
import { JsonApiHelpProvider } from "../json-api-help/json-api-help";
import { ScheduledSleepMoment } from "../../models/scheduled-sleep-moment";
import { HasMany } from "../../models/jsonapi/hasmany";
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap, switchMap } from 'rxjs/operators';

/*
  Generated class for the ChildrenProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ChildrenProvider
  extends JsonApiHelpProvider
  implements Transformer {
  constructor(public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/children";
  }

  transform(data: JsonAPI) {
    let rc;
    if (data.multiple()) {
      // Transform multiple objects
      rc = [];
      data.data.forEach((relation) => {
        let tmp = this.generate(relation);
        rc.push(tmp);
      });
    } else {
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }

  // Fill based on input
  protected generate(data) {
    let child = new Child();
    let attributes = data.attributes;
    attributes.id = data.id;
    child.fill(attributes);

    // Set relationships for each
    child.person = new HasOne(this.api, data.relationships.person);
    child.relations = new HasMany(this.api, data.relationships.relations);
    child["chat-channel"] = new HasOne(
      this.api,
      data.relationships["chat-channel"]
    );
    child.allergies = new HasMany(this.api, data.relationships.allergies);
    child["scheduled-nutritions"] = new HasMany(
      this.api,
      data.relationships["scheduled-nutritions"]
    );

    return child;
  }

  processScheduledSleepMomentsOfChild(child: Child) {
    let vm = this;

    let url = vm.baseEndpoint + "/" + child.id + "/scheduled-sleep-moments";
    return vm.api.get(url).then((data) => {
      let scheduled_sleep_moments_request: Array<any> = data.data;
      let scheduled_sleep_moments = [];
      scheduled_sleep_moments_request.forEach(function (
        scheduled_sleep_moment_request
      ) {
        let scheduled_sleep_moment = new ScheduledSleepMoment();
        scheduled_sleep_moment_request.attributes.id =
          scheduled_sleep_moment_request.id;
        scheduled_sleep_moment.fill(scheduled_sleep_moment_request.attributes);
        scheduled_sleep_moments.push(scheduled_sleep_moment);
      });

      // Sort the scheduled sleep moments by time.
      scheduled_sleep_moments.sort(function (a, b) {
        return a.timeFrom.diff(b.timeFrom);
      });

      return scheduled_sleep_moments;
    });
  }

  getTermsAndConditions(child: Child) {
    return this.api.get(`${this.baseEndpoint}/${child.id}/organisation`);
  }

  fromRoute(route: ActivatedRoute) {
    return route.paramMap.pipe(map( (params) => {
      const id = params.get('id')
      const child = new Child()
      child.id = parseInt(id)
      return child;
    }))
  }
}
