import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import { Transformer } from "../../interface/transformer";
import { JsonapiProvider } from "../jsonapi/jsonapi";
import { PersonAddress } from "../../models/person-address";

@Injectable()
export class AddressProvider extends JsonApiHelpProvider implements Transformer{

  constructor( public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/children";
  }

  protected generate(data){
    let address = new PersonAddress();
    let attributes = data.attributes;
    attributes.id = data.id;
    address.fill(attributes);

    return address;
  }

}
