import * as moment from "moment";
import {Moment} from "moment";

export class Credit {

  public 'id': number;

  public credit_type: string;
  public credit_hours: number;
  public credit_used: number;
  public credit_available: number;
  public credit_text: string;
  public credit_due_date: string;
  public external_id: number;
  public date: string;

  public fill(data) {
    this.id = data.id;
    this.credit_type = data.credit_type;
    this.credit_hours = data.credit_hours;
    this.credit_used = data.credit_used;
    this.credit_available = data.credit_available;
    this.credit_text = data.credit_text;
    this.credit_due_date = data.credit_due_date;
    this.external_id = data.external_id;
    const date = this.credit_text.match(/(\d\d-\d\d-\d\d)/)
    this.date = date ? date[0] : null

    return this;
  }
}
