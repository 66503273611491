import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { Allergy } from '../../models/allergy';
import { ChatChannel } from '../../models/chat-channel';
import { Child } from '../../models/child';
import { ExternalFile } from '../../models/external-file';
import { Group } from '../../models/group';
import { HasOne } from '../../models/jsonapi/hasone';
import { NutritionCategory } from '../../models/nutrition-category';
import { OrganizedFiles } from '../../models/organized-files';
import { Person } from '../../models/person';
import { PersonAddress } from '../../models/person-address';
import { Relation } from '../../models/relation';
import { User } from '../../models/user';
import { AddressProvider } from '../address/address';
import { AllergiesProvider } from '../allergies/allergies';
import { AuthenticationProvider } from '../authentication/authentication';
import { CacheProvider } from '../cache/cache';
import { ChatChannelsProvider } from '../chat-channels/chat-channels';
import { ChildrenProvider } from '../children/children';
import { ConfigProvider } from '../config/config';
import { ExternalFilesProvider } from '../external-files/external-files';
import { ImagesProvider } from '../images/images';
import { JsonapiProvider } from '../jsonapi/jsonapi';
import { LoadingProvider } from '../loading/loading';
import { NutritionCategoryProvider } from '../nutrition-category/nutrition-category';
import { NutritionProvider } from '../nutrition/nutrition';
import { PeopleProvider } from '../people/people';
import { RelationsProvider } from '../relations/relations';
import { ScheduledNutritionsProvider } from '../scheduled-nutritions/scheduled-nutritions';
import { SwapSettingsService } from '../swap-settings/swap-settings.service';
import { UsersProvider } from '../users/users';
import { WdcApiProvider } from '../wdc-api/wdc-api';

/*
This class is used to background sync commonly used parts of the app for faster loading.
It uses the cache provider for caching.

Please keep the background synced parts to a minimum.

This will make load time significantly faster, and would also cause them to update periodically.

Without this, the api elements will be loaded on demand, meaning a lot of time loading on the user's end, especially
considering how many api calls are needed and the speed of the API.

An alternative to this would be caching manually (repeated calls and manual update/processing though)
Another alternative would be to pass the data to the nav controllers (but then pages won't be independent of their
parents anymore).

Another great alternative would be using state management stuff. If ever this gets too big, something like ngrx-store/redux.

See https://angular.io/guide/dependency-injection for more info on providers
and Angular DI.
*/
@Injectable()
export class SyncedDataProvider {
  public syncedChildren: Array<Child>                        = [];
  public syncedPeople: Array<Person>                         = [];
  public syncedChannels: Array<ChatChannel>                  = [];
  public syncedNutritionCategories: Array<NutritionCategory> = [];
  public syncedFiles: Array<ExternalFile>                    = [];
  public syncedOrganizedFiles: OrganizedFiles                = new OrganizedFiles();
  public user: User;
  public syncedCurrentChild: Child;
  public syncedAllergies: Array<Allergy>                     = [];
  public syncedRelations: Array<Relation>                    = [];

  public syncedReadyChildren: Array<Child> = [];

  public initialSynced: boolean = false;

  private initialPromise: Promise<any>;
  public showTabs = true;

  constructor(
    public api: JsonapiProvider,
    public relations: RelationsProvider,
    public users: UsersProvider,
    public people: PeopleProvider,
    public children: ChildrenProvider,
    public chatChannels: ChatChannelsProvider,
    public nutritionCategories: NutritionCategoryProvider,
    public nutrition: NutritionProvider,
    public scheduledNutritions: ScheduledNutritionsProvider,
    public images: ImagesProvider,
    public invoices: ExternalFilesProvider,
    public loading: LoadingProvider,
    public externalFiles: ExternalFilesProvider,
    public address: AddressProvider,
    public allergiesProvider: AllergiesProvider,
    public store: Storage,
    public authentication: AuthenticationProvider,
    public cache: CacheProvider,
    public wdcapi: WdcApiProvider,
    public config: ConfigProvider,
    public swapSettingsService: SwapSettingsService
  ) {
  }

  private isCyclic(obj) {
    var seenObjects = [];

    function detect(obj) {
      if (obj && typeof obj === 'object') {
        if (seenObjects.indexOf(obj) !== -1) {
          return true;
        }
        seenObjects.push(obj);
        for (var key in obj) {
          if (obj.hasOwnProperty(key) && detect(obj[key])) {
            console.log(obj, 'cycle at ' + key);
            return true;
          }
        }
      }
      return false;
    }

    return detect(obj);
  }

  serializedUser() {
    let u      = {...this.user};
    u.person   = null;
    u.relation = null;
    return u;
  }

  generateSaveableData() {
    let x     = {};
    x['type'] = [];

    for (let member in this) {
      if (member.includes('synced')) {
        x[member.toString()] = this[member];

        if (this[member] && this[member][0]) {
          x['type'].push(typeof this[member][0]);
        } else {
          x['type'].push('object');
        }
      }
    }

    x['type'].push('object');
    x['user'] = this.user;

    const s = JSON.stringify(this.serializeObject(x));
    return s;
  }

  save() {
    const s = this.generateSaveableData();
    return this.store.set('syncedData', s).then(() => {
      this.store.get('syncedData').then((data) => {

      });
    });
  }

  removeCached() {
    this.initialSynced             = false;
    this.syncedChildren            = [];
    this.syncedPeople              = [];
    this.syncedChannels            = [];
    this.syncedNutritionCategories = [];
    this.syncedFiles               = [];
    this.syncedOrganizedFiles      = new OrganizedFiles();
    this.syncedCurrentChild        = undefined;
    this.syncedAllergies           = [];
    this.syncedRelations           = [];
    this.syncedReadyChildren       = [];
    this.initialPromise            = undefined;
    this.user                      = undefined;
    return this.store.remove('syncedData');
  }

  getCached() {
    return this.store.get('syncedData');
  }

  /**
   * Triggers initial sync
   * Returns a Promise.
   * Promise is resolved if the sync completes or has already been completed.
   * @returns {Promise<any>}
   */
  initialSync() {
    let vm = this;

    return vm.getCached().then(cached => {
      if (cached) {
        // this.initialSynced = true;
      }

      // Ensure initial sync is only ran once.
      if (!this.initialPromise) {
        console.log('Not yet synced!');
        this.initialPromise = new Promise((resolve) => {
          if (vm.initialSynced === true) {
            console.log('Initial Synced is False');
            // If sync is already done before, resolve
            resolve();
          } else {
            console.log('Restore');
            // If there is a cached value, restore it, and make the sync run in the background instead.
            // Run sync
            // vm.sync().then(function(){
            //   // Once sync is done, resolve.
            //   resolve();
            // })
            // Disable cached state management for now. Reenable it later.
            // RESTORE
            vm.getCached().then(function(syncedData) {
              let x = JSON.parse(syncedData);
              console.log(x);
              if (x) {
                for (let member in x) {
                  if (member.includes('synced') || member == 'user') {
                    vm[member.toString()] = x[member];
                  }
                }
                console.log(x);
                console.log(vm);
                if (vm.user && vm.user.id) {
                  resolve();
                }
                // Make the sync run in the background
                vm.sync().then(() => {
                  resolve();
                });
              } else {
                // Run sync
                vm.sync().then(function() {
                  // Once sync is done, resolve.
                  resolve();
                });
              }
            });
          }
        });
        return this.initialPromise;
      } else {
        return this.initialPromise;
      }
    });
  }

  /**
   * Syncs everything.
   * If you only need to sync specific sections, call their specific processes.
   */
  sync() {
    let vm = this;

    return new Promise((resolve, reject) => {
      let resolvedPromises = 0;
      let totalPromises    = 0;

      function resolvePromise() {
        resolvedPromises++;
        console.log(resolvedPromises + '/' + totalPromises);
        if (resolvedPromises == totalPromises) {
          vm.initialSynced       = true;
          vm.syncedReadyChildren = [...vm.syncedChildren];
          vm.save().then(() => {
            resolve();
          });
        }
      }

      function addPromise(promise: Promise<any>) {
        totalPromises++;
        promise.then(() => {
          resolvePromise();
        });
      }

      vm.processChildren().then(function() {
        addPromise(vm.processScheduledNutritionsOfChildren());
        addPromise(vm.processScheduledSleepMomentsOfChildren());
        addPromise(vm.processRelationsOfChildren());
        addPromise(vm.processAllergiesOfChildren());
        addPromise(vm.processGroupsOfChildren());
      });
    });
  }

  processChildren() {
    let vm = this;

    return new Promise((resolve, reject) => {
      vm.users.selfUncached().then((user) => {
        let synced_user = user;
        if (!vm.user) {
          vm.user = synced_user;
        }

        // If there is no user, then we need to redirect to the login page.
        if (!user || !user.relation) {
          vm.authentication.logout().then(() => {
            // window.location.reload(true);
            window.location.assign('/');
          });
        }

        // Get the relation connected to the user
        // We require this to retrieve the children
        vm.relations.relation(user.relation).then((relation) => {
          // Off all children we need a bit more information too..
          vm.children.relation(relation.children).then((children) => {
            vm.syncedChildren = children;

            // Retrieve some data about the relation ships (We can run this parallel)
            let peopleIds          = children.map((child) => child.person.key());
            let peoplePromise      = vm.people.filter({id: peopleIds});
            let chatChannelPromise = this.loadChatChannels();
            let personPromise      = vm.people.relation(synced_user.person);

            // Run all promises
            Promise.all([
              peoplePromise,
              chatChannelPromise,
              personPromise,
            ]).then((data) => {
              vm.syncedPeople              = data[0];
              synced_user.associatedPerson = data[2];
              vm.syncedChildren            = children;

              vm.parseChatChannels(data[1]);

              if (synced_user.associatedPerson.address) {
                vm.address
                  .relation(synced_user.associatedPerson.address)
                  .then(function(address) {
                    synced_user.associatedPerson.associatedAddress = address;
                    vm.user                                        = synced_user;
                    resolve();
                  });
              } else {
                synced_user.associatedPerson.associatedAddress = new PersonAddress();
                vm.user                                        = synced_user;
                resolve();
              }
            });
          });
        });
      });
    });
  }

  private getChatChannelIds() {
    return this.syncedChildren.map(
      (child) => child['chat-channel']['data']['id']
    );
  }

  public reloadChatChannels() {
    let vm = this;
    return this.loadChatChannels().then((channels) => {
      vm.parseChatChannels(channels);
    });
  }

  public loadChatChannels() {
    return this.getChatChannels(this.getChatChannelIds());
  }

  public getChatChannels(children: Array<any>): Promise<ChatChannel[]> {
    return this.chatChannels.filter({id: children});
  }

  public parseChatChannels(chatchannels: Array<ChatChannel>) {
    let vm            = this;
    vm.syncedChannels = chatchannels;

    this.syncedChildren.forEach(function(child, index, array) {
      array[index].associatedPerson = vm.syncedPeople.find(function(person) {
        return person.id == child.person.data.id;
      });
      let channel                   = vm.getChannel(child['chat-channel'].data.id);
      child.associatedChannel       = channel;
    });
  }

  processScheduledNutritionsOfChildren() {
    let vm = this;

    return new Promise((resolve, reject) => {
      let scheduledNutritionPromises = [];

      // Fetch the Scheduled Nutrition from the children
      vm.syncedChildren.forEach(function(child) {
        scheduledNutritionPromises.push(
          vm.scheduledNutritions.getForChild(child)
        );
      });

      Promise.all(scheduledNutritionPromises).then((values) => {
        // Put all the Nutrition Categories into one Array so we can fetch them.
        let nutrition_categories_to_fetch = new Set();
        for (let scheduled_nutrition of values) {
          nutrition_categories_to_fetch.add(
            scheduled_nutrition['nutrition-category-id']
          );
        }

        // Fetch the Nutrition Categories
        vm.nutritionCategories
          .getNutritionCategories(Array.from(nutrition_categories_to_fetch))
          .then((data) => {
            vm.syncedNutritionCategories = data;

            vm.syncedChildren.forEach(function(child, index) {
              child.scheduledNutritions = values[index];

              // Process the Nutrition Categories per child
              for (let scheduledNutrition of child.scheduledNutritions) {
                // Process Category Name
                let category = vm.getNutritionCategory(
                  scheduledNutrition['nutrition-category-id']
                );
                if (typeof category != 'undefined') {
                  scheduledNutrition['category-name'] = category.name;
                }

                // Add in Image Url to the Scheduled Nutrition
                scheduledNutrition.image_url = vm.nutritionCategories.getNutritionCategoryImageFromString(
                  scheduledNutrition['category-name']
                );
              }
            });

            resolve();
          });
      });
    });
  }

  processScheduledSleepMomentsOfChildren() {
    let vm = this;

    let scheduledSleepMomentPromises = [];

    // For each children, get scheduled sleep moments
    vm.syncedChildren.forEach(function(child, index) {
      scheduledSleepMomentPromises.push(
        vm.children.processScheduledSleepMomentsOfChild(child)
      );
    });

    return Promise.all(scheduledSleepMomentPromises).then(
      (resolvedPromises) => {
        //  Process and append them to the synced children
        vm.syncedChildren.forEach((child, index, childrenArray) => {
          childrenArray[index].scheduledSleepMoments = resolvedPromises[index];
        });

        // Sort them by date
      }
    );
  }

  processGroupsOfChildren() {
    let vm       = this;
    let promises = new Set();

    // List down the groups we need to sync
    vm.syncedChildren.forEach(function(child) {
      promises.add(vm.wdcapi.getCombinedChildGroups(child));
    });

    return Promise.all(promises).then((groups: Array<Array<Group>>) => {
      vm.syncedChildren.forEach(function(child, i) {
        child.associatedGroups  = groups[i];
        let associatedCareTypes = [];
        for (const group of groups[i]) {
          associatedCareTypes.push(group['care-type']);
        }
        child.associatedCareTypes = associatedCareTypes;
      });
    });
  }

  processAllergiesOfChildren() {
    let vm              = this;
    let allergyPromises = new Set();

    // List down the allergies we need to sync
    vm.syncedChildren.forEach(function(child) {
      child.allergies.data.forEach(function(data) {
        allergyPromises.add(vm.allergiesProvider.getFromId(data.id));
      });
    });

    return Promise.all(allergyPromises).then(function(allergies) {
      // Add allergies to synced allergies
      for (let allergy of allergies) {
        if (!vm.getAllergy(allergy['id'])) {
          vm.syncedAllergies.push(allergy as Allergy);
        }
      }

      function sortAllergies(allergies) {
        allergies.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
      }

      // Assign allergies to children
      vm.syncedChildren.forEach(function(child) {
        if (child.allergies.data) {
          for (let allergy_data of child.allergies.data) {
            let allergy = vm.getAllergy(allergy_data.id);
            child.associatedAllergies.push(allergy);
          }
          sortAllergies(child.associatedAllergies);
        }
      });
    });
  }

  processRelationsOfChildren() {
    let vm               = this;
    let relationPromises = [];

    return new Promise(function(resolve) {
      let resolvedPromises = 0;
      let totalPromises    = 0;

      function resolvePromise() {
        resolvedPromises++;
        if (resolvedPromises == totalPromises) {
          vm.initialSynced = true;
          resolve();
        }
      }

      function addPromise(promise: Promise<any>) {
        totalPromises++;
        promise.then(() => {
          resolvePromise();
        });
      }

      vm.syncedChildren.forEach(function(child) {
        relationPromises.push(vm.relations.relation(child.relations));
      });

      if (!relationPromises.length) {
        resolve();
      }

      Promise.all(relationPromises).then(function(data: Array<any>) {
        vm.syncedChildren.forEach(function(child, index) {
          child['syncedRelations'] = [];
          for (let relation of data[index]) {
            child['syncedRelations'].push(relation);
            addPromise(
              vm.people.filter({id: relation['person-id']}).then((person) => {
                if (person.length > 0 && person[0].image) {
                  addPromise(
                    vm.images.getImage(person[0]).then((image) => {
                      if (image) {
                        person[0].image_url = image['url'];
                      } else {
                        person[0].image_url = '/';
                      }

                      child.associatedRelatedPeople.push(person[0]);
                    })
                  );
                } else {
                  child.associatedRelatedPeople.push(person[0]);
                }
              })
            );
          }
        });
      });
    });
  }

  getNextCachePeriod() {
    return moment().add(1, 'hour').toDate();
  }

  getChild(id: number) {
    return this.syncedChildren.find(function(child) {
      return child.id == id;
    });
  }

  getNutritionCategory(id: number) {
    return this.syncedNutritionCategories.find((nutrition_category) => {
      return nutrition_category.id == id;
    });
  }

  async initialSyncWithMessage(
    message: string                   = 'Gegevens ophalen...',
    dismissMessageWhenLoaded: boolean = true
  ) {
    let vm = this;

    await vm.loading.showLoading(message);
    return vm.initialSync().then(function() {
      if (dismissMessageWhenLoaded) {
        vm.loading.dismissLoading();
      }
    });
  }

  syncFiles() {
    let vm = this;

    return new Promise(function(resolve) {
      // Ensure we have already initialSynced
      vm.initialSync().then(function() {
        // Get the relation
        let relation_id = vm.user['relation-id'];

        // Get the files
        vm.externalFiles.getFilesFromRelationId(relation_id).then((files) => {
          vm.syncedFiles = files;

          // vm.externalFiles.processFilesAttachments(relation_id, vm.syncedFiles).then(function () {
          const organizedFiles = new OrganizedFiles();
          organizedFiles.organizeFiles(vm.syncedFiles);
          vm.syncedOrganizedFiles = organizedFiles;
          resolve();
          // });
        });
      });
    });
  }

  getPersonForChild(child: Child) {
    // Search the person in the people list
    let person = this.syncedPeople.find(function(item) {
      return item.id == child['person-id'];
    });

    if (person == undefined) {
      return new Person();
    } else {
      return person;
    }
  }

  getAllergy(id: number) {
    return this.syncedAllergies.find((allergy) => {
      return allergy.id == id;
    });
  }

  getChannel(id: number) {
    if (this.syncedChannels) {
      return this.syncedChannels.find((channel) => {
        return channel.id == id;
      });
    } else {
      return undefined;
    }
  }

  private serializeObject(object) {
    var simpleObject = {};

    if (typeof object != 'object') {
      return object;
    }

    for (var prop in object) {
      if (!object.hasOwnProperty(prop)) {
        continue;
      }
      if (typeof object[prop] != 'object') {
        simpleObject[prop] = object[prop];

      } else if (
        object[prop] instanceof Array ||
        object[prop] instanceof HasOne ||
        (object[prop] &&
         typeof object[prop]['fill'] == 'function' &&
         object[prop]['id'])
      ) {
        simpleObject[prop] = {};
        if (object[prop] instanceof Array) {
          simpleObject[prop] = [];
        }
        for (var element in object[prop]) {
          simpleObject[prop][element] = this.serializeObject(
            object[prop][element]
          );
        }
      } else if (typeof object[prop] == 'function') {

      } else if (prop == 'user') {
        simpleObject[prop] = object[prop];
      }
    }
    return simpleObject;
  }
}
