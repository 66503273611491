import { Transformer } from './../../interface/transformer';
import { Relationship } from './../../models/jsonapi/relationship';
import { Relation } from './../../models/relation';
import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { Injectable } from '@angular/core';
import { JsonapiProvider } from '../jsonapi/jsonapi';
import { HasMany } from '../../models/jsonapi/hasmany';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';

/*
Generated class for the RelationsProvider provider.

See https://angular.io/guide/dependency-injection for more info on providers
and Angular DI.
*/
@Injectable()
export class RelationsProvider extends JsonApiHelpProvider implements Transformer {
  
  constructor( public api: JsonapiProvider) {
    super(api);
  }
  
  transform(data: JsonAPI){
    
    let rc;
    if(data.multiple()){
      // Transform multiple objects
      rc = [];
      data.data.forEach(relation => {
        
        let tmp = this.generate(relation);
        rc.push(tmp);
      });
      
    }else{
      // Transform one object
      rc = this.generate(data.data);
    }
    
    return rc;
  }

  public relation( relation: Relationship ){
    return this.api.relation(relation).then( relation => {
      return this.transform(relation);
    })
  }
  
  
  // Fill based on input
  protected generate(data){
    let relation = new Relation();
    let attributes = data.attributes;
    attributes.id = data.id;
    relation.fill(attributes);
    
    // Set relationships for each
    relation.children = new HasMany(this.api, data.relationships.children);

    // user.person = new HasOne(this.api, data.relationships.person);
    // user.relation = new HasOne(this.api, data.relationships.relation);
    
    return relation;
  }
  
  
  
}
