import { HasOne } from './jsonapi/hasone';

export class ChatMessage{

  public 'id' : number;
  public 'chat-channel-id': number;
  public 'user-id' : number;
  public 'body' : string;
  public 'created-at': Date;
  public 'updated-at': Date;
  public 'person-id': number;

  public 'chat-channel' : HasOne;
  public 'user' : HasOne;

  public fill(data){
    this['id'] = data['id'];
    this['chat-channel-id'] = data['chat-channel-id'];
    this['user-id'] = data['user-id'];
    this['body'] = data['body'];
    this['created-at'] = data['created-at'];
    this['updated-at'] = data['updated-at'];
    this['person-id'] = data['person-id'];

    return this;
  }
}
