import { HasOne } from "./jsonapi/hasone";
import { HasMany } from "./jsonapi/hasmany";
import { ScheduledNutrition } from "./scheduled-nutrition";
import { Person } from "./person";
import { Allergy } from "./allergy";
import { ScheduledSleepMoment } from "./scheduled-sleep-moment";
import { ChatChannel } from "./chat-channel";
import { Relation } from "./relation";
import { Group } from "./group";

export class Child {
  public "id": number;
  public "external-id": string;
  public "person-id": number;
  public "location-id": number;
  public "created-at": string;
  public "updated-at": string;

  public note: string;
  public school: string;
  public class: string;
  public "organisation-id": number;

  public mentor: string;

  public "person": HasOne;
  public "chat-channel": HasOne;
  public "relations": HasMany;
  public "allergies": HasMany;

  public scheduledNutritions: Array<ScheduledNutrition> = [];
  public scheduledSleepMoments: Array<ScheduledSleepMoment> = [];
  public associatedRelatedPeople: Array<Person> = [];
  public associatedAllergies: Array<Allergy> = [];
  public associatedGroups: Array<Group> = [];
  public associatedCareTypes: Array<string> = [];
  public associatedPerson: Person;
  public associatedChannel: ChatChannel;
  public syncedRelations: Array<Relation> = [];

  public fill(data) {
    this.id = data.id;
    this["person-id"] = data["person-id"];
    this["location-id"] = data["location-id"];
    this["external-id"] = data["external-id"];
    this["created-at"] = data["created-at"];
    this["updated-at"] = data["updated-at"];

    this.note = data["note"];
    this.school = data["school"];
    this.class = data["class"];
    this.mentor = data["mentor"];

    this["organisation-id"] = data["organisation-id"];

    return this;
  }
}

export class ChildAPIInterface {
    "bsn": string
    "gender": number
    "initials": number
    "first_name": string
    "infix": string
    "last_name": string
    "date_of_birth": string //"2015-01-01",
    "school": string //"DE KLAROEN MAARSSEN",
    "school_year": string //"2019-2020",
    "school_class": string //"",
    "school_class_nr": string //"",
    "school_group": string //"",
    "warning_1": string //"waarschuwnaam1",
    "emergency_1": string //"0615151515",
    "warning_2": string //"waarschuwnaam2",
    "emergency_2": string //"0618181818",
    "warning_3": string //"",
    "emergency_3": string //"",
    "particulars": string //"Komt deze info ook WEER in Kidsvision? Vraag van deze ouder. ",
    "disease": string //"Welke hier noemen?",
    "medicine": string //"",
    "diet": string //"",
    "allergies": string //"Hooikoorts",
    "doctor_name": string //"Van Puffelen",
    "doctor_phone": string //"0541252525",
    "dentist_name": string //"Van Tuffelen",
    "dentist_phone": string //"0541353535"
}
