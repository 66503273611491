import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  galleryView = new BehaviorSubject<string>('fotoalbum');

  constructor() { }

  changeView() {
    if (this.galleryView.value === 'fotoalbum') {
      this.galleryView.next('video');
    } else this.galleryView.next('fotoalbum');
  }
}
