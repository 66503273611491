import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as download from 'downloadjs';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { SyncedDataProvider } from '../../providers/synced-data/synced-data';
import { ExternalFile } from '../../models/external-file';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DocumentViewer, DocumentViewerOptions, } from '@ionic-native/document-viewer/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NavService } from 'src/app/providers/nav/nav.service';
import { ExternalFilesProvider } from '@core/providers/external-files/external-files';
import * as moment from 'moment';

declare var window: any;
/**
 * Generated class for the ExternalFilesComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'external-files',
  templateUrl: 'external-files.html',
  styleUrls: ['./external-files.scss'],
})
export class ExternalFilesComponent implements OnInit, OnChanges {
  @Input('externalFiles')
  public externalFiles: Array<ExternalFile> = [];

  @Input('notFoundMessage')
  public notFoundMessage: string;
  public isApp;

  constructor(
    public navCtrl: NavController,
    public syncedData: SyncedDataProvider,
    public alertCtrl: AlertController,
    public platform: Platform,
    public documentViewer: DocumentViewer,
    private file: File,
    private fileOpener: FileOpener,
    public browser: InAppBrowser,
    public navService: NavService,
    public externalFilesService: ExternalFilesProvider
  ) {
  }

  ngOnInit() {
    let vm = this;
    if (vm.syncedData.syncedFiles.length) {
      vm.syncedData.syncFiles();
    } else {
      // Show loading screen message
      vm.syncedData
        .initialSyncWithMessage('Gegevens ophalen...', false)
        .then(function () {
          // Load invoices
          vm.syncedData.syncFiles().then(() => {
            // Dismiss Loading screen message once invoices are loaded
            // Sort Invoices By Date
            vm.syncedData.loading.dismissLoading();
            console.log(vm.syncedData.syncedFiles)
          });
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentYear = parseInt(moment().format('Y'));
    const lastLastYear = parseInt(moment().subtract(2, 'year').format('Y'));
    const lastYear = parseInt(moment().subtract(1, 'year').format('Y'));
    const invoiceYears: any = [lastLastYear, lastYear, currentYear];
    console.log(invoiceYears)
    this.externalFiles = changes.externalFiles.currentValue.filter((f:any) => invoiceYears.indexOf(f.year) !== -1);
  }

  openFile(file: ExternalFile) {
    this.externalFilesService
      .getAttachmentFromFile(file['relation-id'], file)
      .then((res: any) => {
        file.attachment = res;
        if (this.navService.isCordova) {
          this.openFileInApp(file);
        } else {
          this.openFileInWeb(file);
        }
      })
      .catch((error) => console.error(error));
  }

  public isAndroid() {
    return this.platform.is('android');
  }

  public saveFileInAndroid(file: ExternalFile) {
    // Remember to execute this after the onDeviceReady event

    // If your base64 string contains "data:application/pdf;base64,"" at the beginning, keep reading.
    var myBase64 = file.attachment.url;
    // To define the type of the Blob
    var contentType = 'application/pdf';
    // if cordova.file is not available use instead :
    // var folderpath = "file:///storage/emulated/0/";
    var folderpath = this.file.externalRootDirectory + '/Download/';
    var filename = file.name + '.pdf';

    this.savebase64AsPDF(folderpath, filename, myBase64, contentType);
  }

  openFileInWeb(file, i = 0) {
    // console.log(file);
    // console.log(this.isApp);
    // As per https://productforums.google.com/forum/#!topic/chrome/wvYYcBwJ9FA:
    // Chrome, IE, Edge and Firefox blocks data:// URLs from loading. So only way to view them properly is to save them.
    try {
      download(file.attachment.url, file.name + '.pdf', 'application/pdf');
    } catch {
      if (i < 100) {
        this.openFileInWeb(file, i + 1);
      }
    }
  }

  openFileInApp(file: ExternalFile) {
    console.log('In app');
    let vm = this;
    let options: DocumentViewerOptions = {
      title: file.name,
    };

    if (vm.platform.is('android')) {
      vm.navService.navigateForward('pdf-viewer', false, '', {
        title: file.name,
        pdfsrc: file.attachment.url,
      });
    } else {
      let blob = this.b64toBlob(
        file.attachment.url.replace('data:application/pdf;base64,', ''),
        'application/pdf'
      );
      this.file
        .writeFile(this.file.cacheDirectory, 'temp.pdf', blob, {
          replace: true,
        })
        .then((res) => {
          vm.fileOpener
            .open(res.toURL(), 'application/pdf')
            .then((res) => {
            })
            .catch((err) => {
              if (vm.platform.is('ios')) {
                vm.documentViewer.viewDocument(
                  res.toURL(),
                  'application/pdf',
                  options
                );
              } else {
                // Android: Use PDFJs
                vm.navService.navigateForward('pdf-viewer', false, '', {
                  title: file.name,
                  pdfsrc: file.attachment.url,
                });
              }
              // vm.alert('Kan bestand niet openen. Misschien hebt u geen PDF-reader geïnstalleerd.');
            });
        })
        .catch((err) => {
          vm.alert('Kan bestand niet opslaan.');
        });
    }
  }

  async alert(text) {
    let alert = await this.alertCtrl.create({
      header: 'Mislukt',
      subHeader: text,
      buttons: ['OK'],
    });
    await alert.present();
  }

  private b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   *
   * @param b64Data {String} Pure base64 string without contentType
   * @param contentType {String} the content type of the file i.e (application/pdf - text/plain)
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   * @return Blob
   */
  private bb64toBlob(b64Data, contentType, sliceSize = 512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  /**
   * Create a PDF file according to its database64 content only.
   *
   * @param folderpath {String} The folder where the file will be created
   * @param filename {String} The name of the file that will be created
   * @param content {Base64 String} Important : The content can't contain the following string (data:application/pdf;base64). Only the base64 string is expected.
   */
  private savebase64AsPDF(folderpath, filename, content, contentType) {
    console.log(content);
    // Convert the base64 string in a Blob
    var DataBlob = this.bb64toBlob(
      content.replace('data:application/pdf;base64,', ''),
      contentType
    );

    console.log('Starting to write the file :3');

    window.resolveLocalFileSystemURL(folderpath, function (dir) {
      console.log('Access to the directory granted succesfully');
      dir.getFile(filename, { create: true }, function (file) {
        console.log('File created succesfully.');
        alert('Saved as ' + folderpath + '/' + filename);
        file.createWriter(
          function (fileWriter) {
            console.log('Writing content to file');
            fileWriter.write(DataBlob);
          },
          function () {
            alert('Unable to save file in path ' + folderpath);
          }
        );
      });
    });
  }
}
