import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConfigProvider} from '../config/config'
import {Group} from '../../models/group'
import {ChildPresenceRequest} from '../../models/child-presences-request'
import * as moment from 'moment'
import {Moment} from 'moment'
import { KalenderPage } from 'src/app/pages/child/kalender/kalender.page';

/*
  Generated class for the CalendarCustomConfigProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class CalendarCustomConfigProvider {
  date: any;


  static readonly BRANDS_WITH_CUSTOM_CONFIG = [
    'dons'
  ];

  constructor(public http: HttpClient,
              public config: ConfigProvider,
  ) {

  }

  areWeUsingABrandWithCustomConfig() {
    const currentBrand = this.config.get('API_SHARD');
    console.log('We are using the brand ' + currentBrand);
    if (CalendarCustomConfigProvider.BRANDS_WITH_CUSTOM_CONFIG.includes(currentBrand)) {
      console.log(currentBrand + ' is using a custom config.');
      return true;
    } else {
      console.log('We are not using a brand with custom config.');
      return false;
    }
    ;
  }

  private toDate(h, m = 0, s = 0) {
    return moment(this.date).hour(h).minute(m).second(s).toDate();
  }

  private isWednesday(date: Moment) {
    return date.day() == 3;
  }

  private isFriday(date: Moment) {
    return date.day() == 5;
  }

  donsGetExtraDayButtons(date: Moment, group: Group, calendar: KalenderPage) {
    let buttons       = [];
    const location_id = group['location-id'];

    let enableOchtend = true;

    // These are the default extra day hours:
    const hours = {
      ochtend: {
        text: 'Ochtend',
        from: moment(this.date).hour(9).minute(0).second(0).toDate(),
        to:   moment(this.date).hour(13).minute(0).second(0).toDate(),
      },
      middag:  {
        text: 'Middag',
        from: moment(this.date).hour(13).minute(0).second(0).toDate(),
        to:   moment(this.date).hour(18).minute(0).second(0).toDate(),
      },
      heledag: {
        text: 'Hele dag',
        from: moment(this.date).hour(9).minute(0).second(0).toDate(),
        to:   moment(this.date).hour(18).minute(0).second(0).toDate(),
      }
    }

    switch (location_id) {
      case 1:
        // Dongeschool
        enableOchtend = false;

        hours.middag.text = 'MIDDAG 15.00 tot 18.30';
        hours.middag.from = this.toDate(15);
        hours.middag.to   = this.toDate(18, 30);

        hours.heledag.text = 'HELE DAG 8.00 tot 18.30';
        hours.heledag.from = this.toDate(8);
        hours.heledag.to   = this.toDate(18, 30);

        if (this.isWednesday(date)) {
          hours.middag.text = 'MIDDAG 13.00 tot 18.30';
          hours.middag.from = this.toDate(13);
          hours.middag.to   = this.toDate(18, 30);
        }
        break;
      case 2:
        // Scholekster
        enableOchtend = false;
        hours.middag.text = 'MIDDAG 15.00 tot 18.30';
        hours.middag.from = this.toDate(15);
        hours.middag.to   = this.toDate(18, 30);

        hours.heledag.text = 'HELE DAG 8.00 tot 18.30';
        hours.heledag.from = this.toDate(8);
        hours.heledag.to   = this.toDate(18, 30);

        if (this.isWednesday(date)) {
          hours.middag.text = 'MIDDAG 12.45 tot 18.30';
          hours.middag.from = this.toDate(12, 45);
          hours.middag.to   = this.toDate(18, 30);
        }
        break;
      case 3:
        // Notenkraker
        enableOchtend = false;
        hours.middag.text = 'MIDDAG 14.45 tot 18.30';
        hours.middag.from = this.toDate(14, 45);
        hours.middag.to   = this.toDate(18, 30);

        hours.heledag.text = 'HELE DAG 8.00 tot 18.30';
        hours.heledag.from = this.toDate(8);
        hours.heledag.to   = this.toDate(18, 30);
        break;
      case 4:
        // Olympiaschool
        enableOchtend = false;
        hours.middag.text = 'MIDDAG 15.00 tot 18.30';
        hours.middag.from = this.toDate(15, 0);
        hours.middag.to   = this.toDate(18, 30);

        hours.heledag.text = 'HELE DAG 8.00 tot 18.30';
        hours.heledag.from = this.toDate(8);
        hours.heledag.to   = this.toDate(18, 30);

        if (this.isWednesday(date)) {
          hours.middag.text = 'MIDDAG 12.00 tot 18.30';
          hours.middag.from = this.toDate(12, 0);
          hours.middag.to   = this.toDate(18, 30);
        }
        break;
      case 5:
        // BMS
        enableOchtend = false;
        hours.middag.text = 'MIDDAG 14.45 tot 18.30';
        hours.middag.from = this.toDate(14, 45);
        hours.middag.to   = this.toDate(18, 30);

        hours.heledag.text = 'HELE DAG 8.00 tot 18.30';
        hours.heledag.from = this.toDate(8);
        hours.heledag.to   = this.toDate(18, 30);

        if (this.isWednesday(date)) {
          hours.middag.text = 'MIDDAG 12.30 tot 18.30';
          hours.middag.from = this.toDate(12, 30);
          hours.middag.to   = this.toDate(18, 30);
        }

        if (this.isFriday(date)) {
          hours.middag.text = 'MIDDAG JONG 12.00 tot 18.30';
          hours.middag.from = this.toDate(12, 0);
          hours.middag.to   = this.toDate(18, 30);
        }
        break;
      case 6:
        // Notenkraker
        enableOchtend = false;
        hours.middag.text = 'MIDDAG 14.20 tot 18.30';
        hours.middag.from = this.toDate(14, 20);
        hours.middag.to   = this.toDate(18, 30);

        hours.heledag.text = 'HELE DAG 8.00 tot 18.30';
        hours.heledag.from = this.toDate(8);
        hours.heledag.to   = this.toDate(18, 30);
        break;
    }

    switch (group["care-type"]) {
      case 'tso':
        /**
         * Hi! TSO is handled in a special way.
         * Basically, it only shows 1 option for TSO, which is usually 12 to 13.
         */
        buttons.push(
          {
            'text':  '12:00 - 13:00',
            handler: () => {
              // Request
              let cp = new ChildPresenceRequest();
              cp.fill(
                {
                  'date':          moment(this.date).format('YYYY-MM-DD'),
                  'from-date':     moment(this.date).hour(12).minute(0).second(0).toDate(),
                  'to-date':       moment(this.date).hour(13).minute(0).second(0).toDate(),
                  'child':         calendar.child,
                  'group':         group,
                  'description':   "",
                  'presence-type': "strip"
                }
              );

              calendar.showLoading("Aanvraag doorgeven...");

              calendar.childpresence.request(cp).then(() => {
                // Alert finished request
                calendar.requestFinished();
              })
            }
          });
        break;
      default:
        /**
         * The default way to handle the day selection buttons.
         * Usually there are these options:
         *  - Ochtend (morning)
         *  - Middag (afternoon)
         *  - Hele dag (entire day)
         */
        // Can I schedule in the morning?
        if (calendar.isItPossibleToScheduleInTheMorning() && enableOchtend) {
          buttons.push(
            {
              'text':  hours.ochtend.text,
              handler: () => {
                // Request
                let cp = new ChildPresenceRequest();
                cp.fill(
                  {
                    'date':          moment(this.date).format('YYYY-MM-DD'),
                    'from-date':     hours.ochtend.from,
                    'to-date':       hours.ochtend.to,
                    'child':         calendar.child,
                    'group':         group,
                    'description':   "",
                    'presence-type': "extra"
                  }
                );

                calendar.showLoading("Aanvraag doorgeven...");

                calendar.childpresence.request(cp).then(() => {
                  // Alert finished request
                  calendar.requestFinished();
                })
              }
            },
          )
        };

        // Can I schedule in the afternoon
        if (calendar.isItPossibleToScheduleInTheAfternoon()) {
          buttons.push(
            {
              'text':  hours.middag.text,
              handler: () => {
                // Request
                let cp = new ChildPresenceRequest();
                cp.fill(
                  {
                    'date':          moment(this.date).format('YYYY-MM-DD'),
                    'from-date':     hours.middag.from,
                    'to-date':       hours.middag.to,
                    'child':         calendar.child,
                    'group':         group,
                    'description':   "",
                    'presence-type': "extra"
                  }
                );

                calendar.showLoading("Aanvraag doorgeven...");
                calendar.childpresence.request(cp).then(() => {
                  // Alert finished request
                  calendar.requestFinished();
                })
              }
            },
          );
        }

        // Can I schedule both?
        if (calendar.isItPossibleToScheduleInTheMorning() && calendar.isItPossibleToScheduleInTheAfternoon()) {
          buttons.push(
            {
              'text':  hours.heledag.text,
              handler: () => {
                // Request
                let cp = new ChildPresenceRequest();
                cp.fill(
                  {
                    'from-date':     hours.heledag.from,
                    'to-date':       hours.heledag.to,
                    'child':         calendar.child,
                    'group':         group,
                    'description':   "",
                    'presence-type': "extra"
                  }
                );
                calendar.showLoading("Aanvraag doorgeven...");
                calendar.childpresence.request(cp).then(() => {
                  // Alert finished request
                  calendar.requestFinished();
                })
              }
            },
          );
        }

        return buttons;
    }
  }

  getButtonsForDayRequestForGroup(date: Moment, group: Group, calendar: KalenderPage) {
    this.date = date;

    let buttons        = [];
    const currentBrand = this.config.get('API_SHARD');

    switch (currentBrand) {
      case 'dons':
        buttons = this.donsGetExtraDayButtons(date, group, calendar);
        break;
    }

    return buttons;
  }
}
