import { Component } from "@angular/core";
import { PhotoLibrary } from "@ionic-native/photo-library/ngx";
import { AlertController, ModalController, NavController, NavParams } from '@ionic/angular';
import { Image } from "./../../models/image";
import { PhotoDownloadProvider } from '@core/providers/photo-download/photo-download';

/**
 * Generated class for the PhotoDetailPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: "page-photo-detail",
  templateUrl: "photo-detail.html"
})
export class PhotoDetailPage {
  public image: Image;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private view: ModalController,
    private alertCtrl: AlertController,
    private photoLibrary: PhotoLibrary,
    public photoDownloadService: PhotoDownloadProvider,
  ) {}

  ionViewDidLoad() {
    this.image = this.navParams.get("image");
  }

  closeModal() {
    this.view.dismiss();
  }

  save() {
    let vm = this;

    // this.photoLibrary.requestAuthorization({ read: true, write: true }).then(
    //   () => {
    //     this.photoLibrary.saveImage(vm.image.url, "Ouderapp").then(
    //       success => {
    //         this.showError(
    //           "De foto is opgeslagen in het album 'Ouderapp'!",
    //           "Gelukt!"
    //         );
    //       },
    //       error => {
    //         this.showError("Kan foto niet opslaan door onbekende reden.");
    //         console.error(error);
    //       }
    //     );
    //   },
    //   error => {
    //     this.showError(
    //       "Kan foto niet opslaan omdat de app hier geen toegang tot heeft."
    //     );
    //   }
    // );

    // Save image
    this.photoDownloadService.downloadPhotoFromUrl(vm.image.url);
  }

  async showError(text: string, title?: string) {
    title = title ? title : "Mislukt";

    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: text,
      buttons: ["OK"]
    });
    await alert.present();
  }
}
