import { Component, Input, OnInit } from "@angular/core";
import { ChildPresenceRequest } from "@core/models/child-presences-request";
import { ChildSwapRequest } from "@core/models/child-swap-request";
import { ChildrenProvider } from "@core/providers/children/children";
import { ModalController } from "@ionic/angular";
import {TranslationService} from "@core/providers/translation/translation";

@Component({
  selector: "app-recapitulation",
  templateUrl: "./recapitulation.component.html",
  styleUrls: ["./recapitulation.component.scss"],
})
export class RecapitulationComponent implements OnInit {
  @Input() request: ChildSwapRequest | ChildPresenceRequest;

  termsUrl = "";
  isSwap;

  constructor(
    public childrenService: ChildrenProvider,
    public modalCtrl: ModalController,
    public t: TranslationService,
  ) {}

  ngOnInit() {
    console.log(this.request);
    this.childrenService
      .getTermsAndConditions(this.request.child)
      .then(
        (res: any) =>
          (this.termsUrl = res.data["attributes"]["terms-and-conditions"])
      )
      .catch((err) => console.error(err));
    this.isSwap = this.request instanceof ChildSwapRequest;
    console.log(this.request);
    if (this.request instanceof ChildPresenceRequest) {
      this.request["from-date"] = new Date(this.request["from-date"]);
    }
  }

  dismiss(status) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      status
    });
  }
}
