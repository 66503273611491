export class JsonAPI{

  public meta : any;
  public links : any;
  public data : any;
  public included : any;




  public fill(data){

    if(data.data != null){
      this.data = data.data;
    }else{
      // Incase data is null
      this.data = [];
    }

    if(data.links != null){
      this.links = data.links;
    }

    if(data.meta != null){
      this.meta = data.meta;
    }

    if(data.included != null){
      this.included = data.included;
    }

    return this;
  }


  public multiple(){
    if(this.data instanceof Array){
      return true;
    }

    return false;
  }


}
