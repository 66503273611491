import { HasOne } from './jsonapi/hasone';

export class Nutrition{

  public 'id' : number;

  public 'quantifiable'   : boolean;
  public 'name'           : string;
  public 'note'           : string;
  public 'unit'           : number;
  public 'nutrition-type' : string;
  public 'name-plural'    : string;
  public 'quantity-min'   : number;
  public 'quantity-max'   : number;
  public 'quantity-step'  : number;

  public 'created-at': Date;
  public 'updated-at': Date;

  public 'chat-channel' : HasOne;
  public 'user' : HasOne;

  public fill(data){
    this['quantifiable'] = data['quantifiable'];
    this['name'] = data['name'];
    this['note'] = data['note'];
    this['unit'] = data['unit'];
    this['nutrition-type'] = data['nutrition-type'];
    this['name-plural'] = data['name-plural'];
    this['quantity-min'] = data['quantity-min'];
    this['quantity-max'] = data['quantity-max'];
    this['quantity-step'] = data['quantity-step'];

    this['created-at'] = data['created-at'];
    this['updated-at'] = data['updated-at'];

    return this;
  }
}
