import { Component, Input } from '@angular/core';
import {TranslationService} from "@core/providers/translation/translation";

/**
 * Generated class for the ParentsCrudComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'parents-crud',
  templateUrl: 'parents-crud.html'
})
export class ParentsCrudComponent {

  @Input('parent') parent          = {};
  @Input('hasAddress') hasAddress = "1";

  constructor(public t: TranslationService) {
  }

}
