export class Allergy{

  public 'id' : number;

  public 'allergy-type' : string;
  public 'description'  : string;
  public 'name'         : string;

  public 'created-at': string;
  public 'updated-at': string;

  public fill(data){
    this.id 						= data.id;

    this['allergy-type'] = data['allergy-type'];
    this['description'] = data['description'];
    this['name'] = data['name'];

    this['created-at'] 				= data['created-at'];
    this['updated-at'] 				= data['updated-at'];

    return this;
  }
}
