export class Config {

  public API_HOST          = "jsonapi.wedaycare.com";
  public API_CLIENT_ID     = "TwVcphLpamnmkTB4PYwuhznMS0Rl147b";
  public API_CLIENT_SECRET = "ow5TynSH65aPzUXQceECKV9obCBhQ61c";
  public API_PROTO         = "https";
  public API_VERSION       = "v1";
  public API_SHARD         = "default";


  // DON'T CHANGE ANYTHING BELOW THIS LINE

  constructor() {
  }

  public get(key) {
    return this[key];
  }
}
