import { Transformer } from './../../interface/transformer';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Injectable } from '@angular/core';
import { JsonAPI } from '../../models/jsonapi/jsonapi';
import { Relationship } from '../../models/jsonapi/relationship';

/*
  Generated class for the JsonApiHelpProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
// Find a better name for this
export abstract class JsonApiHelpProvider implements Transformer {

  protected baseEndpoint = "/v1/unknown";

  constructor( public api: JsonapiProvider) {
  }

  public transform(data: JsonAPI){

    let rc;
    if(data.multiple()){
      // Transform multiple objects
      rc = [];
      data.data.forEach(person => {

        let tmp = this.generate(person);
        rc.push(tmp);
      });

    }else{
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }

  public relation( relation: Relationship ){
    return this.api.relation(relation).then( relationData => {
      return this.transform(relationData);
    })
  }

  public relationCached(relation: Relationship, cachePeriod: Date){
    // Remove first part of the url
    return this.api.relationCached(relation, cachePeriod).then( relationData => {
      return this.transform(relationData);
    })
  }

  public getFromId(id: number) {
    let vm = this;
    return this.api.get(this.baseEndpoint + '/' + id).then(data => {
      return vm.transform(data);
    });
  }

  public getFromIds(ids: Array<number>) {
    return this.filter( { "id" : ids } );
  }

  public filter( input ){
    // Convert input to url encoded query string
    var vm = this;


    let filter = this.buildFilter(input);
    console.log(filter);

    // Get live data
    return this.api.get(vm.baseEndpoint + "?" + filter).then(filterData => {

      return vm.transform(filterData);
    }, err => {
      return;
    })
  }

  public buildFilter( input ){
    let items = [];

    for(let key in input){
      let keyname = "[" + key + "]";
      if(input[key] instanceof Array){
        input[key].join(",");
      }
      let value = input[key];

      let str = "filter" + encodeURIComponent(keyname) + "=" + encodeURIComponent(value);
      items.push(str);
    }

    return items.join("&");
  }

  // Should return an object of X type
  protected abstract generate(any);

}
