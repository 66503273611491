import {Component} from '@angular/core';

/**
 * Generated class for the ChatBubbleComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
    selector: 'sfix',
    templateUrl: 'scroll-fix.html'
})
export class ScrollFixComponent {

    constructor() {

    }

    ngAfterViewInit() {
        setTimeout(function () {
            const el = document.getElementsByClassName('button_wa');
            for (var i = 0; i < el.length; i++) {
                const a: HTMLElement = el[i] as HTMLElement;
                a.click();
            }
        }, 750);
    }

}
