import { Injectable }          from '@angular/core';
import { Credit }              from "../../models/credit";
import { JsonapiProvider }     from "../jsonapi/jsonapi";

/*
  Generated class for the CreditsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class CreditsProvider {

  constructor(public api: JsonapiProvider) {
  }

  get(child_id: number) {
    return this.api.get('/v1/children/' + child_id + '/credits').then(data => {
      let rc = [];
      data.data.forEach(person => {

        let tmp = this.generate(person);
        rc.push(tmp);
      });

      return rc;
    });
  }

  protected generate(data){
    let credit = new Credit();
    let attributes = data.attributes.credit;
    attributes.id = data.id;
    attributes.external_id = data.attributes.external_id;
    console.log(data.attributes)
    credit.fill(attributes);

    return credit;
  }

}
