import { HttpClient }     from '@angular/common/http';
import { Injectable }     from '@angular/core';
import { ConfigProvider } from "../config/config";
import { Group }          from "../../models/group";
import * as moment        from "moment";
import { Moment }         from "moment";

/*
  Generated class for the AppointmentManagerProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AppointmentManagerProvider {

  constructor(
    private config: ConfigProvider
  ) {

  }

  /**
   * Can we send a request day?
   */
  canRequestDay(group: Group, day: Moment) {
    const vm = this;
    const shard = vm.config.get('API_SHARD');
    const fourteenDaysFromNow = moment().add(14, 'day');

    // For client BroodEnSpelen
    // And group type is TSO.
    if (shard == 'broodenspelen') {
      if (group["care-type"] === 'tso') {
        // Parent can only request extra day maximum of 14 days in the future.
        if (day.isSameOrBefore(fourteenDaysFromNow)) {
          return 'success';
        }else {
          return "U kunt tot maximaal 14 dagen in de toekomst een extra opvang dag aanvragen.";
        }
      }
    }

    return 'success';
  }



}
