import { HasOne } from './jsonapi/hasone';
import * as moment from "moment";
import { Moment } from "moment";

export class ScheduledSleepMoment{

  public 'id' : number;

  public 'note'       : string;
  public 'child-id'   : number;
  public 'sleep-type' : string;
  public 'time-from'  : Date;
  public 'time-to'    : Date;

  public 'created-at' : string;
  public 'updated-at' : string;

  public 'person'     : HasOne;
  public 'relation'   : HasOne;

  public timeFrom : Moment;
  public timeTo   : Moment;

  public image_url : string;

  public fill(data){
    this.id 						= data.id;

    this['note'] 			  = data['note'];
    this['child-id']    = data['child-id'];
    this['sleep-type']  = data['sleep-type'];
    this['time-from']   = data['time-from'];
    this['time-to']     = data['time-to'];

    this['created-at'] 				= data['created-at'];
    this['updated-at'] 				= data['updated-at'];

    this.timeFrom = moment(this['time-from'], 'HH:mm:ss');
    this.timeTo = moment(this['time-to'], 'HH:mm:ss');
    this.image_url = this.getImageUrlFromSleepType(this['sleep-type']);

    return this;
  }

  getImageUrlFromSleepType(sleep_type) {
    switch(sleep_type) {
      case 'sleep':
        return 'assets/imgs/icons/sleep-moment-sleep-small.png';
      case 'rest':
        return 'assets/imgs/icons/sleep-moment-rest-small.png';
    }
  }

}
