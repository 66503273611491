import { Injectable } from "@angular/core";
import { ExternalFile } from "../../models/external-file";
import { JsonApiHelpProvider } from "../json-api-help/json-api-help";
import { Transformer } from "../../interface/transformer";
import { JsonapiProvider } from "../jsonapi/jsonapi";
import { ExternalFileAttachment } from "../../models/external-file-attachment";

/*
  Generated class for the ExternalFiles provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ExternalFilesProvider
  extends JsonApiHelpProvider
  implements Transformer {
  constructor(public api: JsonapiProvider) {
    super(api);
  }

  protected generate(data) {
    let external_file = new ExternalFile();
    let attributes = data.attributes;
    attributes.id = data.id;
    external_file.fill(attributes);

    return external_file;
  }

  getFilesFromRelationId(relation_id: number): Promise<Array<ExternalFile>> {
    return this.api
      .get("/v1/relations/" + relation_id + "/files")
      .then((arrayOfData) => {
        let files: Array<ExternalFile> = [];

        for (let data of arrayOfData.data) {
          let file = new ExternalFile();
          file = this.generate(data);
          files.push(file);
        }

        return files;
      });
  }

  processFilesAttachments(relation_id, files: Array<ExternalFile>) {
    let vm = this;
    let attachmentPromises = [];

    for (let file of files) {
      attachmentPromises.push(vm.getAttachmentFromFile(relation_id, file));
    }

    return Promise.all(attachmentPromises.map((p) => p.catch((e) => e))).then(
      (values) => {
        files.forEach(function (file, i) {
          if (!(file instanceof Error)) {
            file.attachment = values[i];
          }
        });
      }
    );
  }

  getAttachmentFromFile(relation_id: number, file: ExternalFile) {
    return this.api
      .get("/v1/relations/" + relation_id + "/attachments/" + file.id)
      .then(function (data) {
        let attachment = new ExternalFileAttachment();
        data.data.attributes.id = data.data.id;
        attachment.fill(data.data.attributes);
        return attachment;
      });
  }
}
