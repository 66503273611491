import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { NavService } from 'src/app/providers/nav/nav.service';

/**
 * Generated class for the PdfJsViewerPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'app-pdf-js-viewer',
  templateUrl: 'pdf-js-viewer.html',
})
export class PdfJsViewerPage {
  title: string;
  pdfSrc: string = '/assets/sample.pdf';
  zoom = 1;
  src;

  constructor(public navCtrl: NavController, public navParams: NavParams, public navService: NavService) {

    // TODO: ADD SPINNER
    let pdfSrc;
    if (this.navService.state) {
      pdfSrc = this.navService.state['pdfsrc'];
      this.title = this.navService.state['title'] || 'PDF';
    }

    if (!pdfSrc) {
      this.navService.navigateRoot("overview");
      return;
    }

    if(pdfSrc && pdfSrc.indexOf('base64') >= 0) {
      const base =  pdfSrc.split(',').pop();
      this.src = this.base64ToArrayBuffer(base);
      // this.loadFromFile(pdfSrc).then(() => {});
    }else {
      this.pdfSrc = pdfSrc || '/assets/sample.pdf';
    }
  }


  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  ionViewDidLoad() {

  }

  async loadFromFile(pdfSrc) {
    this.pdfSrc = await this.loadPdf(pdfSrc);
  }

  async loadPdf(path: string) {
    return new Promise<string>((resolve, reject) => {

      const request = new XMLHttpRequest();
      request.open('GET', path, true);
      request.responseType = 'blob';

      request.onload = () => {
        const reader = new FileReader();

        reader.onload = (e: any) => resolve(e.target.result);
        reader.onerror = err => reject(err);
        reader.readAsDataURL(request.response);
      };

      request.send();
    });
  }

  base64ToArrayBuffer(base64) {
    let binary_string =  window.atob(base64);
    let len = binary_string.length;
    console.log(len);
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

}
