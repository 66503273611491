import { Transformer }         from './../../interface/transformer';
import { HasOne }              from './../../models/jsonapi/hasone';
import { Injectable }          from '@angular/core';
import { User }                from './../../models/user';
import { JsonapiProvider }     from '../jsonapi/jsonapi';
import { JsonAPI }             from '../../models/jsonapi/jsonapi';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import * as moment             from 'moment';

/*
 Generated class for the UsersProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable()
export class UsersProvider extends JsonApiHelpProvider implements Transformer {


  constructor(public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/users";
  }

  selfUncached() {
    var vm = this;

    // Get live data
    return this.api.get(this.baseEndpoint + '?me=').then(user => {
      // Model version of the user data
      let mUser = vm.transform(user);
      return mUser;
    }, err => {
      // It means we are not logged in
      // window.setTimeout(() => {
      //   window.location.reload();
      // }, 500);
    })
  }

  self() {
    var vm = this;

    // Get live data
    return this.api.getCached(this.baseEndpoint + '?me=', moment().add(1, 'hour').toDate()).then(user => {
      // Model version of the user data
      let mUser = vm.transform(user);
      return mUser;
    }, err => {
      // It means we are not logged in
      // window.setTimeout(() => {
      //   window.location.reload();
      // }, 500);
    })


  }

  /**
   * Resolves if still logged in, rejects if not.
   * @returns {Promise<JsonAPI>}
   */
  amIStillLoggedIn() {
    return this.api.get(this.baseEndpoint + '?me=').then(() => {
      return;
    })
  }

  transform(data: JsonAPI) {
    let rc;
    if (data.multiple()) {
      // Transform multiple objects
      rc = [];
      data.data.forEach(user => {

        let tmp = this.generate(user);
        rc.push(tmp);
      });

    } else {
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }


  // Fill based on input
  protected generate(data) {
    let user       = new User();
    let attributes = data.attributes;
    attributes.id  = data.id;
    user.fill(attributes);

    // Set relationships for each
    user.person   = new HasOne(this.api, data.relationships.person);
    user.relation = new HasOne(this.api, data.relationships.relation);

    return user;
  }


}
