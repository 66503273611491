import { Child } from "./child";
import { Group } from "./group";


export class ChildPresenceRequest{

    public 'from-date' : Date;
    public 'to-date' : Date;
    public 'child' : Child;
    public 'group' : Group;
    public 'presence-type' : string;
    public 'description': string;
    public 'credit-id': string | number;
    public 'credit-external-id': string | number;
  
  public fill(data){

    this['from-date'] = data['from-date'].replace(/\s/, 'T');
    this['to-date'] = data['to-date'].replace(/\s/, 'T');
    this['child'] = data['child'];
    this['group'] = data['group'];
    this['description'] = data['description'];
    this['presence-type'] = data['presence-type'];
    this['credit-id'] = data['credit-id']?.toString();
    console.log(data)
    this['credit-external-id'] = data['credit-external-id']?.toString();

    return this;
  }
}
