import { HasMany } from './jsonapi/hasmany';
//import { HasOne } from './jsonapi/hasone';

export class Relation{

	public 'id' : number;
	public 'external-id': string;
    public 'location-id': number;
    public 'organisation-id': number;
    public 'parent-number': number;
    public 'person-id': number;
    public 'relation-type': string;
	public 'created-at': string;
	public 'updated-at': string;

	// public 'person' : HasOne;
	// public 'relation' : HasOne;

    public 'children' : HasMany;
	
	public fill(data){

        this.id 						= data.id;
        this['external-id']             = data['external-id'];
        this['location-id']             = data['location-id'];
        this['organisation-id']         = data['organisation-id'];
        this['parent-number']           = data['parent-number'];
        this['person-id']               = data['person-id'];
        this['relation-type']           = data['relation-type'];
        this['created-at']              = data['created-at'];
        this['updated-at']              = data['updated-at'];

		return this;
	}

	

	

}
