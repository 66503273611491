import { Transformer } from './../../interface/transformer';
import { HasOne } from './../../models/jsonapi/hasone';
import { Injectable } from '@angular/core';
import { Person } from './../../models/person';
import { JsonapiProvider } from '../jsonapi/jsonapi';
import { JsonAPI } from '../../models/jsonapi/jsonapi';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import { Child } from '../../models/child';

/*
  Generated class for the PeopleProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class PeopleProvider extends JsonApiHelpProvider implements Transformer {

  constructor( public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/people";
  }

  transform(data: JsonAPI){

    let rc;
    if(data.multiple()){
      // Transform multiple objects
      rc = [];
      data.data.forEach(person => {

        let tmp = this.generate(person);
        rc.push(tmp);
      });

    }else{
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }

  // Fill based on input
  protected generate(data){
    let person = new Person();
    let attributes = data.attributes;
    attributes.id = data.id;
    person.fill(attributes);

    if(data.relationships && data.relationships.image) {
      person.image = new HasOne(this.api, data.relationships.image);
    }

    if(data.relationships && typeof(data.relationships.address) != 'undefined') {
      person.address = new HasOne(this.api, data.relationships.address);
    }

    return person;
  }

  public getRelatedPersonFromChild(child: Child) {
    return this.relation(child.person).then(person => {
      return person;
    });
  }

  public getFullName(person: Person) : string{
    return person['first-name'] + ' ' + person['last-name'];
  }

}
