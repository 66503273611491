import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionSheetController, NavController, Platform } from '@ionic/angular';
import { Child } from '@core/models/child';
import {TranslationService} from "@core/providers/translation/translation";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  public eventSubject = new Subject<string>();

  public listViewSubject              = new Subject<boolean>();
  public showNewRequestsButtonSubject = new Subject<boolean>();

  public child = new Child();

  constructor(
    private navCtrl: NavController,
    private actionSheetCtrl: ActionSheetController,
    public platform: Platform,
    public t: TranslationService,
  ) {
  }

  switch() {
    this.eventSubject.next('switch');
  }

  btnNewRequest() {
    this.eventSubject.next('new-request');
  }

  async btnEditRequest() {
    const actionSheet = await this.actionSheetCtrl.create({
      // For which group do you want to schedule an extra day?
      header: '',
      buttons: [
        {
          text   : this.t._('Afwezigheid doorgeven'),
          handler: () => {
            this.navCtrl.navigateForward('report-absence/' + this.child.id);
          },
        },
        {
          text   : this.t._('Ruildag aanvragen'),
          handler: () => {
            this.navCtrl.navigateForward('/request-swap-day/' + this.child.id);
          },
        },
        {
          text   : this.t._('Annuleren'),
          role   : 'cancel', // will always sort to be on the bottom
          icon   : !this.platform.is('ios') ? 'close' : null,
          handler: () => {
            console.log('Cancel clicked');
          },
        }
      ]
    });

    await actionSheet.present();
  }

  setListView(listView) {
    this.listViewSubject.next(listView);
  }

  setShowNewRequestsButton(show) {
    this.showNewRequestsButtonSubject.next(show);
  }

}
