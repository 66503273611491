import { HasOne } from './jsonapi/hasone';
import { Person } from './person';

export class User {

	public 'id': number;
	public 'email': string;
	public 'employee-id': number;
	public 'person-id': number;
	public 'real-email': boolean;
	public 'relation-id': number;
	public 'suggest-password-change': boolean;
	public 'welcome-mail-sent': boolean;
	public 'created-at': string;
	public 'updated-at': string;
	public 'last-login': string;
	public 'has-agreed-to-terms': boolean;

	public 'person': HasOne;
	public 'relation': HasOne;

	public associatedPerson: Person;

	public fill(data) {
		this.id                         = data.id;
		this.email                      = data.email || '';
		this['employee-id']             = data['employee-id'];
		this['person-id'] 				= data['person-id'];
		this['real-email'] 				= data['real-email'];
		this['relation-id'] 			= data['relation-id'];
		this['suggest-password-change'] = data['suggest-password-change'];
		this['welcome-mail-sent'] 		= data['welcome-mail-sent'];
		this['created-at'] 				= data['created-at'];
		this['updated-at'] 				= data['updated-at'];
		this['last-login'] 				= data['last-login'];

		return this;
	}





}
