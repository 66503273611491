import { Injectable } from '@angular/core';
import { JsonapiProvider } from "../jsonapi/jsonapi";
import { JsonApiHelpProvider } from "../json-api-help/json-api-help";
import { Transformer } from "../../interface/transformer";
import { NutritionCategory } from "../../models/nutrition-category";

/*
  Generated class for the NutritionCategoryProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class NutritionCategoryProvider extends JsonApiHelpProvider implements Transformer {

  constructor(public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/nutrition-categories";
  }

  protected generate(data){
    let category = new NutritionCategory();
    let attributes = data.attributes;

    attributes.id = data.id;
    category.fill(attributes);

    return category;
  }

  getNutritionCategories(nutrition_categories_to_fetch: any) : Promise<any> {
    return this.filter({ id: nutrition_categories_to_fetch });
  }

  getNutritionCategoryImageFromString(s : string) : string {
    let image_url = 'assets/imgs/icons/';
    let normalized_s = s.toLowerCase();

    switch(normalized_s) {
      case 'flesvoeding':
      case 'melk':
        image_url += 'nutrition-type-milk.png';
        break;
      case 'brood':
        image_url += 'nutrition-type-bread.png';
        break;
      case 'drankje':
        image_url += 'nutrition-type-drink.png';
        break;
      case 'snack':
        image_url += 'nutrition-type-snack.png';
        break;
      case 'babyvoeding':
        image_url += 'nutrition-type-baby.png';
        break;
      case 'overige':
        image_url += 'nutrition-type-other.png';
        break;
      case 'fruit':
        image_url += 'nutrition-type-fruit.png';
        break;
      case 'sleep':
        image_url += 'sleep-moment-sleep.png';
        break;
      case 'rest':
      default:
        image_url += 'sleep-moment-rest-small.png';
    }

    return image_url;
  }

}
