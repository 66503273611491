import {Injectable} from '@angular/core';
import {Relatable} from '../../models/relatable';

@Injectable()
export class RelatorService {
  constructor() {
  }

  /**
   * Get the id from an array
   * Returns undefined or the Relatable.
   * @param id
   * @param relations
   */
  public findById(id: number, relations: Array<Relatable>, key: string = 'id'): any {
    return relations.find((value) => {
      if (value[key] == id) {
        return true;
      }
      return false;
    })
  }

  public addOnce(relatable: Relatable, relations: Array<Relatable>) {
    if (!this.findById(relatable.id, relations)) {
      relations.push(relatable);
      return true;
    }
    return false;
  }
}
