import {Image} from "./image";
import * as moment from 'moment';


export class MessagesListData{
  public image: Image = new Image();
  public sender: string;
  public message: string;
  public time: string;
  public notificationCount: number = 0;
  public messages_list_id: number = 0;


  public fromNow(){
    return moment(this.time).fromNow();
  }


}
