import { Injectable }         from '@angular/core';
import { SyncedDataProvider } from "../synced-data/synced-data";
import { Child }              from "../../models/child";
import { ConfigProvider }     from '../config/config'
import {Time} from "@angular/common";
import {RequestTypes} from "@core/models/enums/request-types";
import {GroupTypes} from "@core/models/enums/group-types";
import moment, {Moment} from "moment";

/*
 Generated class for the PermissionsProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable()
export class PermissionsProvider {
  currentShard = '';

  constructor(
    private sd: SyncedDataProvider,
    public config: ConfigProvider,
  ) {
    this.currentShard = this.config.get('API_SHARD');
  }

  childOnlyHasTSO(child: Child) {
    const care_types = child.associatedCareTypes;
    if (care_types.length == 1 && care_types[0] == 'tso') {
      return true;
    } else {
      return false;
    }
  }

  childHasTSO(child: Child) {
    const care_types = child.associatedCareTypes;
    for (const care_type of care_types) {
      if (care_type == 'tso') {
        return true;
      }
    }
    return false;
  }

  childHasCareType(child: Child) {
    return child.associatedCareTypes.length;
  }

  canUseDiary(child: Child) {
    if (this.childHasCareType(child) &&
        !this.childOnlyHasTSO(child)) {
      return true;
    }
    return false;
  }

  canUseMessages(child: Child) {
    if (this.currentShard === 'broodenspelen') {
      return true;
    }

    if (this.childHasCareType(child)
        && !this.childOnlyHasTSO(child)
    ) {
      return true;
    }
    return false;
  }

  canUseCalendar(child: Child) {
    if (this.childHasCareType(child)) {
      return true;
    }
    return false;
  }

  canUsePhotoAlbum(child: Child) {
    if (this.childHasCareType(child) &&
        !this.childOnlyHasTSO(child)) {
      return true;
    }
    return false;
  }

  canUseMyCredits(child: Child) {
    // Please remove "Mijn tegoed" at client Kinderkabinet, Poko Loko, Eiland Marlyne and Dons opvang.
    switch (this.currentShard) {
      case 'kinderkabinet':
        return false;
      case 'pokoloko':
        return false;
      case 'eilandmarlyne':
        return true;
      case 'dons':
        return false;
    }

    if (this.childHasCareType(child) &&
        !this.childOnlyHasTSO(child)) {
      return true;
    }
    return false;
  }

  canUseRequests(child: Child) {
    if (this.childHasCareType(child)) {
      return true;
    }
    return false;
  }

  canUseProfile(child: Child) {
    if (
      this.childHasCareType(child) &&
      !this.childOnlyHasTSO(child)
    ) {
      return true;
    }
    return false;
  }

  canUseSaldoStrippenkart(child: Child) {
    // return true;
    return this.childHasTSO(child);
  }

  canUseNotificationToggles() {
    if (this.currentShard === 'broodenspelen') {
      return true;
    }

    let onlyHasTSO = true;
    for (const child of this.sd.syncedChildren) {
      if (!this.childOnlyHasTSO(child)) {
        onlyHasTSO = false;
      }
    }

    return !onlyHasTSO;
  }

  canUseCalendarNewRequestButton() {
    switch (this.currentShard) {
      case 'dekluts':
        return true;
      default:
        return true;
    }
  }

  // Swop
  canUseRuildagAanvragen() {
    switch (this.currentShard) {
      case 'dekluts':
        return false;
      default:
        return true;
    }
  }

  canRequestAbsence(groupType: GroupTypes, requestType: RequestTypes, startTime: Moment, currentTime: Moment) {
    // const allowed = ['ill', 'vacation', 'misc']
    // const duration = moment.duration(startTime.diff(currentTime));
    // const hours = duration.asHours();

    // The API will give the message now.
    // if (allowed.includes(requestType)) {
    //   switch(groupType) {
    //     case "bso":
    //         if (hours >= 2) {
    //           return true
    //         }
    //         return false
    //       break
    //     case "kdv":
    //     case "tso":
    //     case "vso":
    //     default:
    //       if (hours >= 0) {
    //         return true
    //       }
    //       return false
    //   }
    // }

    return true
  }

}
