import { HasOne } from './jsonapi/hasone';

export class ChatChannel{

	public 'id' : number;
	public 'child-id' : number;
	public 'created-at': Date;
	public 'updated-at': Date;
	public 'last-viewed': Date;
	public 'unread-count': number;
	public 'latest-chat-message-id': number;

    public 'person' : HasOne;
    
	// public 'relation' : HasOne;

	
	public fill(data){
		this['id'] = data['id'];
		this['child-id' ] = data['child-id'];
		this['created-at'] = data['created-at'];
		this['updated-at'] = data['updated-at'];
		this['last-viewed'] = data['last-viewed'];
		this['unread-count'] = data['unread-count'];
		this['latest-chat-message-id'] = data['latest-chat-message-id'];

		return this;
	}

	

	

}
