import {Relatable} from './interfaces/relatable';
import RelatedByDate from './interfaces/related-by-date'

/**
 * Helper for managing Arrays of something with an id and their relations.
 */
export default class Relator {
  /**
   * Get the id from an array
   * Returns undefined or the Relatable.
   */
  static findById(id: number, relations: Array<any>, key: string = 'id'): any {
    if (Array.isArray(relations)) {
      return relations.find((value) => {
        if (value[key] == id) {
          return true;
        }
        return false;
      });
    } else {
      return false;
    }
  }

  /**
   * Returns -1 when id can't be found.
   */
  static findKeyById(id, relations: Array<Relatable>, key = 'id'): any {
    for (let i = 0; i < relations.length; i++) {
      if (relations[i][key] == id) {
        return i;
      }
    }
    return -1;
  }

  static findKeysById(id, relations: Array<Relatable>, key = 'id') {
    const keys = [];
    for (let i = 0; i < relations.length; i++) {
      if (relations[i][key] === id) {
        keys.push(i);
      }
    }
    return keys;
  }

  static addOnce(from: { id: number } | any, to: Array<any>) {
    if (from.hasOwnProperty('id')) {
      if (!this.findById(from.id, to)) {
        to.push(from);
        return true;
      }
      return false;
    } else {
      if (!to.includes(from)) {
        to.push(from);
      }
    }
  }

  /**
   * If relatable is not in relation, add it.
   * @param relatable
   * @param relations
   * @param byId
   */
  static addOnceBy(relatable, relations: Array<any>, byId = 'name') {
    if (!this.findById(relatable[byId], relations, byId)) {
      relations.push(relatable);
      return true;
    }
  }

  static mergeOnceBy(from: Array<any>, to: Array<any>, byId = 'name') {
    for (const f of from) {
      Relator.addOnceBy(f, to, byId);
    }
  }

  /**
   * Add all the *UNIQUE* values from array 'from' to array 'to'.
   * @param from
   * @param to
   */
  static mergeOnce(from: Array<any>, to: Array<any>) {
    for (const f of from) {
      Relator.addOnce(f, to);
    }
  }

  /**
   */
  static sortByDate(relations: Array<any | Relatable>, date_field = 'created_at') {
    relations.sort((a, b) => {
      const ad = new Date(a[date_field]);
      const bd = new Date(b[date_field]);
      return bd.getTime() - ad.getTime();
    });
    return relations;
  }

  static filterOnlyBeforeDate(date: Date, relations: Array<any | Relatable>, date_field = 'created_at') {
    const filtered = relations.filter(relatable => {
      const r_date = new Date(relatable[date_field]);
      if (r_date.getTime() < date.getTime()) {
        return true;
      }
      return false;
    });
    return filtered;
  }

  static getFirstN(n: number, relations: Array<any>) {
    return relations.slice(0, n);
  }

  static getLastElement(relations: Array<any>) {
    return relations[relations.length - 1];
  }

  static where(relations: Array<any>, whereK, whereV) {
    const filtered = [];
    for (const relation of relations) {
      if (relation[whereK] === whereV) {
        filtered.push(relation);
      }
    }
    return filtered;
  }

  static removeWhere(whereV: any, relations: Array<any>) {
    for (let i = 0; i < relations.length; i++) {
      if (relations[i] === whereV) {
        // This is the one to remove.
        relations.splice(i, 1);
        return relations;
      }
    }
    return relations;
  }

  static removeWhereId(id: number, relations: Array<any>) {
    const k = Relator.findKeyById(id, relations);
    return relations.splice(k, 1);
  }

  /**
   * Returns the deleted value.
   * @param index
   * @param relations
   */
  static removeAt(index: number, relations: Array<any>) {
    return relations.splice(index, 1);
  }

  static groupByDate(relations: Array<any>, key = 'created-at') {
    const groups = [];

    // Sample Date: 2019-11-03
    for (const relation of relations) {
      // Create the group if it doesn't exist yet
      const date_string = relation[key].substring(0,10);
      const group = new RelatedByDate();
      group.date = date_string;
      this.addOnceBy(group, groups, 'date');

      const added_group = this.findById(date_string, groups, 'date') as RelatedByDate;
      // Add in the item.
      added_group.data.push(relation);
    }

    return groups as Array<RelatedByDate>;
  }
}
