import { Injectable } from '@angular/core';
import { Transformer } from "../../interface/transformer";
import { JsonApiHelpProvider } from "../json-api-help/json-api-help";
import { JsonapiProvider } from "../jsonapi/jsonapi";
import { Group } from '../../models/group';
import { Location } from '../../models/location';

/*
  Generated class for the LocationsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class LocationsProvider extends JsonApiHelpProvider implements Transformer {

  protected baseEndpoint = "/v1/locations";

  constructor( public api: JsonapiProvider) {
    super(api);
  }

  protected generate(data){
    let group = new Location();
    let attributes = data.attributes;
    attributes.id = data.id;
    group.fill(attributes);

    return group;
  }
}
