import { DatedFiles } from './dated-files';
import { ExternalFile } from './external-file';
import * as moment from 'moment';

export class OrganizedFiles {

  public facturen: Array<DatedFiles>        = [];
  public jaaropvangen: Array<DatedFiles>    = [];
  public correspondentie: Array<DatedFiles> = [];
  public contracten: Array<DatedFiles>      = [];
  public documenten: Array<DatedFiles>      = [];

  organizeFiles(files: Array<ExternalFile>) {
    let vm = this;

    // Sort the files by date
    files.sort(function (file1, file2) {
      return moment(file2.date).diff(moment(file1.date));
    });

    let facturenAll = [];
    let corresepondentieAll  = [];
    let contractenAll    = [];
    let documentenAll    = [];
    let jaaropvangenAll    = [];
    files.forEach(function (file) {

      // Put each file on their respective category
      switch (file['document-type'].toLowerCase()) {
        case 'verkoopfactuur':
          facturenAll.push(file);
          break;
        case 'opgave':
          jaaropvangenAll.push(file);
          break;
        case 'correspondentie':
          corresepondentieAll.push(file);
          break;
        case 'ondertekening':
          contractenAll.push(file);
          break;
        case 'mailing':
          documentenAll.push(file);
          break;
      }
    })

    // Put each file on their respective year
    vm.facturen        = vm.categorizeFilesByYear(facturenAll); // Facturen
    vm.correspondentie = vm.categorizeFilesByYear(corresepondentieAll); //
    vm.contracten      = vm.categorizeFilesByYear(contractenAll);
    vm.documenten      = vm.categorizeFilesByYear(documentenAll);
    vm.jaaropvangen    = vm.categorizeFilesByYear(jaaropvangenAll);
  }

  private categorizeFilesByYear(files: Array<ExternalFile>) {
    let datedFilesAll: Array<DatedFiles> = [];
    let currentYear                      = 0;
    let currentDatedFiles                = undefined;

    function addDatedFiles(datedFiles) {
      if (typeof (datedFiles) != 'undefined') {
        datedFilesAll.push(datedFiles);
      }
    }

    files.forEach(function (file) {
      let fileYear = file.date.getFullYear();
      if (fileYear !== currentYear || typeof (currentDatedFiles) === 'undefined') {
        currentYear = fileYear;
        addDatedFiles(currentDatedFiles);
        currentDatedFiles      = new DatedFiles();
        currentDatedFiles.year = fileYear;
      }
      currentDatedFiles.files.push(file);
    })
    addDatedFiles(currentDatedFiles);

    return datedFilesAll;
  }
}
