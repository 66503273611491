import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy, NavParams } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage";
import { TokenProvider } from "./providers/token/token";
import { UsersProvider } from "./providers/users/users";
import { AuthGuard } from "./guards/auth.guard";
import { JsonapiProvider } from "./providers/jsonapi/jsonapi";
import { ApiProvider } from "./providers/api/api";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { ConfigProvider } from "./providers/config/config";
import { CacheProvider } from "./providers/cache/cache";
import { ComponentsModule } from "./components/components.module";
import { LinkyModule } from "ngx-linky";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { PdfJsViewerPage } from "./pages/pdf-js-viewer/pdf-js-viewer";
import { PhotoDetailPage } from "./pages/photo-detail/photo-detail";
import { AuthenticationProvider } from "./providers/authentication/authentication";
import { PeopleProvider } from "./providers/people/people";
import { NotificationProvider } from "./providers/notification/notification";
// import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { SyncedDataProvider } from "./providers/synced-data/synced-data";
import { RelationsProvider } from "./providers/relations/relations";
import { ChildrenProvider } from "./providers/children/children";
import { ChatChannelsProvider } from "./providers/chat-channels/chat-channels";
import { NutritionCategoryProvider } from "./providers/nutrition-category/nutrition-category";
import { NutritionProvider } from "./providers/nutrition/nutrition";
import { ScheduledNutritionsProvider } from "./providers/scheduled-nutritions/scheduled-nutritions";
import { ImagesProvider } from "./providers/images/images";
import { ExternalFilesProvider } from "./providers/external-files/external-files";
import { LoadingProvider } from "./providers/loading/loading";
import { AddressProvider } from "./providers/address/address";
import { AllergiesProvider } from "./providers/allergies/allergies";
import { WdcApiProvider } from "./providers/wdc-api/wdc-api";
import { GroupsProvider } from "./providers/groups/groups";
import { ChildDayLinesProvider } from "./providers/child-day-lines/child-day-lines";
import { ApiInterceptor } from './providers/api/api.interceptor';
import { TimeoutInterceptor } from './providers/api/timeout.interceptor';
import { NavService } from './providers/nav/nav.service';
import { PermissionsProvider } from './providers/permissions/permissions';
import { RelatorService } from './providers/relator/relator.service';
import { PhotoDownloadProvider } from './providers/photo-download/photo-download';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { DiaryEventsProvider } from './providers/diary-events/diary-events';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import localeNLExtra from '@angular/common/locales/extra/nl';
import { ChatMessagesProvider } from './providers/chat-messages/chat-messages';
import { CalendarService } from './providers/calendar/calendar.service';
import { ChildPresenceProvider } from './providers/child-presence/child-presence';
import { ChildAbsenceProvider } from './providers/child-absence/child-absence';
import { CalendarCustomConfigProvider } from './providers/calendar-custom-config/calendar-custom-config';
import { AppointmentManagerProvider } from './providers/appointment-manager/appointment-manager';
import { PlacementsProvider } from './providers/placements/placements';
import { PhotosProvider } from './providers/photos/photos';
import { CreditsProvider } from './providers/credits/credits';
import { ChildRequestsProvider } from './providers/child-requests/child-requests';
import { LocationsProvider } from './providers/locations/locations';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { File } from "@ionic-native/file/ngx";
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { GalleryService } from './providers/gallery/gallery.service';
import { ChildSwapRequestProvider } from './providers/child-swap-request/child-swap-request';
import { SwapSettingsService } from './providers/swap-settings/swap-settings.service';
import { ToastProvider } from '@core/providers/toast/toast';
import { AlertProvider } from '@core/providers/alert/alert';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Http } from '@angular/http';
import { FormsModule } from "@angular/forms";
import { TranslationService } from "@core/providers/translation/translation";
import { ChildPermissionsProvider } from "./providers/child-permissions/child-permissions";

registerLocaleData(localeNL, 'nl-NL', localeNLExtra);

@NgModule({
    //TODO: Temporary, modularise
    declarations: [AppComponent, PdfJsViewerPage, PhotoDetailPage],
    entryComponents: [PdfJsViewerPage, PhotoDetailPage],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        AppRoutingModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        ComponentsModule,
        LinkyModule,
        PdfViewerModule,
        FormsModule,
    ],
    // TODO: Modularise providers and guards to avoild bloating the app module
    providers: [
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        TokenProvider,
        UsersProvider,
        AuthGuard,
        JsonapiProvider,
        ApiProvider,
        ConfigProvider,
        CacheProvider,
        AuthenticationProvider,
        PeopleProvider,
        NotificationProvider,
        // FirebaseX,
        SyncedDataProvider,
        RelationsProvider,
        ChildrenProvider,
        ChatChannelsProvider,
        NutritionCategoryProvider,
        NutritionProvider,
        ScheduledNutritionsProvider,
        ImagesProvider,
        ExternalFilesProvider,
        LoadingProvider,
        AddressProvider,
        AllergiesProvider,
        WdcApiProvider,
        GroupsProvider,
        ChildDayLinesProvider,
        NavParams,
        NavService,
        PermissionsProvider,
        RelatorService,
        PhotoLibrary,
        PhotoDownloadProvider,
        DiaryEventsProvider,
        ChatMessagesProvider,
        CalendarService,
        ChildPresenceProvider,
        ChildAbsenceProvider,
        ChildPermissionsProvider,
        CalendarCustomConfigProvider,
        AppointmentManagerProvider,
        PlacementsProvider,
        PhotosProvider,
        CreditsProvider,
        ChildRequestsProvider,
        LocationsProvider,
        DocumentViewer,
        File,
        FileOpener,
        InAppBrowser,
        GalleryService,
        ChildSwapRequestProvider,
        SwapSettingsService,
        ToastProvider,
        AlertProvider,
        TranslationService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
