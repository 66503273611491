export class SwapSettings {
  public schooldays;
  public holidays;
  public locationId;
  public groupId;
  public swapEnabled;
  public minScope;
  public maxScope;
  public minPast;
  public maxPast;
  public minFuture;
  public maxFuture;
  public closingDays;

  constructor(
    schoolDays,
    holidays,
    locationId,
    groupId,
    swapEnabled,
    minScope,
    maxScope,
    minPast,
    maxPast,
    minFuture,
    maxFuture,
    closingDays
  ) {
      this.schooldays = schoolDays;
      this.holidays = holidays;
      this.locationId = locationId;
      this.groupId = groupId;
      this.swapEnabled = swapEnabled instanceof Boolean ? swapEnabled : swapEnabled == 1;
      this.minScope = minScope;
      this.maxScope = maxScope;
      this.minPast = minPast;
      this.maxPast = maxPast,
      this.minFuture = minFuture;
      this.maxFuture = maxFuture;
      this.closingDays = closingDays;
  }

  isGlobal() {
      return this.locationId == null && this.groupId == null;
  }

  isGlobalLocationSetting(groupId) {
      return this.locationId === null && this.groupId === groupId;
  }

  isGlobalGroup(locationId) {
      return this.groupId === null && this.locationId === locationId;
  }
}
