import { Injectable } from '@angular/core';
import { Transformer } from "../../interface/transformer";
import { JsonApiHelpProvider } from "../json-api-help/json-api-help";
import { Allergy } from "../../models/allergy";
import { JsonapiProvider } from "../jsonapi/jsonapi";

/*
  Generated class for the AllergiesProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AllergiesProvider extends JsonApiHelpProvider implements Transformer  {

  constructor(public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/allergies";
  }

  // Fill based on input
  protected generate(data){
    let allergy = new Allergy();
    let attributes = data.attributes;
    attributes.id = data.id;
    allergy.fill(attributes);

    return allergy;
  }
}
