import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Transformer } from './../../interface/transformer';
import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import { ChildPresenceRequest } from '../../models/child-presences-request';
import * as moment from 'moment';

/*
  Generated class for the ChildPresenceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ChildPresenceProvider extends JsonApiHelpProvider implements Transformer {

  constructor(public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = '/v1/child-presence-requests';
  }

  transform(data: JsonAPI) {

    let rc;
    if (data.multiple()) {
      // Transform multiple objects
      rc = [];
      data.data.forEach(channel => {

        let tmp = this.generate(channel);
        rc.push(tmp);
      });

    } else {
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }

  public request(presence: ChildPresenceRequest) {
    // Convert into object
    let vm   = this;
    let data = {
      'data': {
        'type'         : 'child-presences',
        'attributes'   : {
          'date'         : moment(presence['from-date']).format('YYYY-MM-DD'),
          'from-time'    : moment(presence['from-date']).format('HH:mm:ss'),
          'to-time'      : moment(presence['to-date']).format('HH:mm:ss'),
          'presence-type': presence['presence-type'],
          'description'  : '',
        },
        'relationships': {
          'child': {
            'data': {
              'type': 'children',
              'id'  : presence.child.id.toString()
            }
          },
          'group': {
            'data': {
              'type': 'groups',
              'id'  : presence.group.id.toString()
            }
          }
        }
      }
    };

    console.log(presence)
    if (presence['credit-id']) {
      data['data']['attributes']['credit-id'] = presence['credit-id'];
    }else {
      data['data']['attributes']['credit-external-id'] = presence['credit-external-id']
    }

    return this.api.post(vm.baseEndpoint, data).then(data => {
      return vm.transform(data);
    });

  }

  // Fill based on input
  protected generate(data) {
    // let chatchannel = new ChatChannel();
    // let attributes = data.attributes;
    // attributes.id = data.id;
    // chatchannel.fill(attributes);

    // Set relationships for each
    //child.person = new HasOne(this.api, data.relationships.person);
    // user.relation = new HasOne(this.api, data.relationships.relation);
    return;
  }


}
