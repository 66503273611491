import { HasOne } from "./jsonapi/hasone";
import { PersonAddress } from "./person-address";

export class Person{

	public 'id' : number;

	public 'email': string;
	public 'first-name': string;
	public 'last-name': string;
	public 'gender': string;
	public 'phone-home': string;
	public 'phone-mobile': string;
	public 'phone-work': string;
	public 'date-of-birth': string;

	public 'image': HasOne;
	public 'address': HasOne;

  public associatedAddress: PersonAddress;


	public fill(data){
		this['id'] = data.id;
		this['email'] = data.email;
		this['first-name'] = data['first-name'];
		this['last-name'] = data['last-name'];
		this['gender'] = data.gender;
		this['phone-home'] = data['phone-home'];
		this['phone-mobile'] = data['phone-mobile'];
		this['phone-work'] = data['phone-work'];
		this['date-of-birth'] = data['date-of-birth'];

		return this;
	}

  public getFullName() : string{
    return this['first-name'] + ' ' + this['last-name'];
  }


}
