import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Placement } from '../../models/placement';
import { Observable } from 'rxjs';
import { ApiProvider } from '../api/api';

/*
  Generated class for the PlacementsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class PlacementsProvider {
  private baseUrl = '/v1/placements';

  constructor(
    private api: ApiProvider
  ) {

  }

  public getPlacements(child_id, group_id): Observable<Placement> {
    return this.api.getO(this.baseUrl, {
      params: {
        'filter[child]': child_id,
        'filter[group]': group_id,
      }
    }).pipe(
      map(data => {
        if (data['meta']['count']) {
          const p: Placement = {...data['data'][0]['attributes']};
          p.id               = parseInt(data['data'][0]['id']);
          console.log(p);

          // Find out if there is a strip on any of the placements
          for (const pl of data['data']) {
            console.log(pl);
            if (pl['attributes']['placement-type'] === 'strippenkaart') {
              p['placement-type'] = 'strippenkaart';
            }

            if (pl['attributes']['related-product'].flex) {
              p['related-product'].flex = true;
            }
          }

          return p as Placement;
        }else {
          return new Placement();
        }
      })
    )
  }

}
