export const Translations = {
    en: {
        'Uitloggen': 'Logout',
        'Instellingen': 'Settings',
        'Bezig met laden': 'Loading...',
        'Uren over %(credit)s uur': '%(credit)s hours credits left!',
        'Meldingen': 'Notifications',
        'Verander wachtwoord': 'Change Password',
        'Facturen': 'Invoices',
        'Jaaropgave': 'Annual statement',
        'Correspondentie': 'Correspondence',
        'Contracten': 'Contracts',
        'Documenten': 'Documents',
        'Naar ouder portaal': 'To parent portal',
        'Mijn NAW gegevens': 'My name and address details',
        'Gebruikersvoorwaarden': 'Terms of Use',
        'Privacy voorwaarden': 'Privacy Policy',
        'Gegevens': 'Details',
        'Dagboek': 'Diary',
        'Berichten': 'Messages',
        'Kalender': 'Calendar',
        'Fotoalbum': 'Photo album',
        'Tegoeden': 'Credits',
        'Verzoeken': 'Requests',
        'Profiel': 'Profile',
        'Saldo strippenkaart': 'Balance strip card',
        'Extra informatie': 'Extra information',
        'Naam huisarts': 'Name of your family doctor',
        'Telefoon huisarts': 'Phone doctor',
        'Naam tandarts': 'Name dentist',
        'Telefoon tandarts': 'Phone dentist',
        'Waarschuwen 1': 'Warn 1',
        'Noodnummer 1': 'Emergency number 1',
        'Waarschuwen 2': 'Warn 2',
        'Noodnummer 2': 'Emergency number 2',
        'Waarschuwen 3': 'Warn 3',
        'Noodnummer 3': 'Emergency number 3',
        'Bijzonderheden': 'Particularities',
        'Ziektes': 'Diseases',
        'Medicijnen': 'Medicines',
        'Dieet': 'Diet',
        'Allergie': 'Allergy',
        'Wijzigen': 'Modify',
        'Groep': 'Group',
        'Kind bewerken': 'Edit Child',
        'Geselecteerd': 'Selected',
        'Gepland': 'Planned',
        'Afwezig': 'Absent',
        'Geen geplande dagregels gevonden': 'No scheduled day rules found',
        'Geplande medewerkers': 'Planned employees',
        'Geen ingeplande regels': 'No scheduled rules',
        'Al aangevraagd': 'Already requested',
        'Extra dag aanvragen': 'Request extra day',
        'Normaal': 'Normal',
        'Schoolvrije dagen': 'No School',
        'Schoolweken': 'School weeks',
        'Vakantieweken': 'Vacation',
        'Gesloten': 'Closed',
        'Download alle foto\'s': 'Download all photos',
        'Jaaropvangen': 'Annual Statement',
        'Transacties': 'Transactions',
        'Er is nog geen tegoed opgebouwd of uw kinderopvang maakt geen gebruik van tegoeden': 'No credit has been built up yet or your childcare does not use credits',
        'Geldig tot': 'Valid until',
        'Voorwaarden bij tegoeden': 'Conditions for credits',
        'Mijn Tegoed': 'My Credits',
        'Geaccepteerd': 'Accepted',
        'Afgewezen': 'Turned down',
        'Wordt verwerkt': 'Processing',
        'Geannuleerd door ouder': 'Canceled by parent',
        'Aanvraag datum:': 'Application date:',
        'Aangevraaagd door:': 'Requested by:',
        'Aanvraag': 'Application',
        'Datum': 'Date',
        'Locatie': 'Location',
        'Originele dag': 'Original day',
        'Nieuwe dag': 'New day',
        'Tijd': 'Time',
        'Betaling': 'Payment',
        'Betaald met: Tegoed': 'Payment by: Credit',
        'Afwezig op 29-042019(11.00 uur)': 'Absent on 04/29/2019(11 a.m.)',
        'Aanvraag annuleren': 'Cancel request',
        'koste: 11.00 uur': 'cost: 11 a.m.',
        'Status': 'Status',
        'Wie mag': 'Who can',
        'ophalen': 'to pick up',
        'Voedingsschema': 'Feeding Schedule',
        'Slaapritme': 'Sleep rhythm',
        'Annuleren': 'Cancel',
        'Selecter een groep waarvoor uw extra opvang wilt aanvragen': 'Select a group for which you want to request extra care',
        'Uw aanvraag controleren': 'Check your request',
        'Gewenste dagdeel': 'Desired part of the day',
        'Dagdeel': 'Daypart',
        'Ruilen naar': 'Exchange to',
        'Door deze aanvraag in te dienen gaat u akkoord met onze': 'By submitting this application you agree to our',
        'Naar welke dag moet er geruild worden': 'To which day should be exchanged',
        'Van': 'By',
        'Ruilen': 'exchange',
        'Tot': 'Until',
        'Verzenden': 'Send',
        'Ziek': 'sick',
        'Vakantie': 'Holidays',
        'Selecteer het type afwezigheid': 'Select the type of absence',
        'Tot en met datum (optioneel)': 'Until date (optional)',
        'Selecteer Begindag': 'Select Start Day',
        'Infix': 'Infix',
        'Voornaam': 'First Name',
        'Voorletter': 'Initial',
        'Achternaam': 'Last name',
        'Geslacht': 'Sex',
        'Man': 'Man',
        'Vrouw': 'Woman',
        'Geboortedatum': 'Date of birth',
        'Schooljaar': 'School year',
        'Schoolklas': 'School class',
        'Schoolgroep': 'School group',
        'Noordnummer 1': 'North number 1',
        'Noordnummer 2': 'North number 2',
        'Noordnummer 3': 'North number 3',
        'Kind Toevoegen': 'Add child',
        'Tussenvoegsels': 'infixes',
        'Straat': 'Street',
        'Huisnummer': 'House number',
        'Plaats': 'Venue',
        'Telefoon thuis': 'Telephone at home',
        'Telefoon werk': 'Phone work',
        'Telefoon mobiel': 'Mobile phone',
        'Naam werkgever': 'Name of employer',
        'voorwaarden': 'conditions',
        'Terug naar inloggen': 'Back to login',
        'Het wachtwoord is gereset': 'The password has been reset',
        'Je zult in je mailbox een email ontvangen met de instructie hoe je je wachtwoord kunt resetten': 'You will receive an email in your mailbox with instructions on how to reset your password',
        'Wachtwoord resetten': 'Reset password',
        'Tegoed': 'Credits',
        'Selecteer welke datum': 'Select which date',
        'Geen beschikbare dagdelen gevonden. Selecteer een andere datum': 'No available days found. Select another date',
        'uren': 'hours',
        'Deze aanvraag wordt extra in rekening gebracht': 'This application will be charged extra',
        'Strippenkaart': 'Strip card',
        'Flexibel abonnement': 'Flexible subscription',
        'Aanvraag indienen': 'File a request',
        'Incidenteel': 'Incidental',
        'Extra facturatie': 'Extra',
        'Legenda': 'Legend',
        'Om deze dag opnieuw aan te melden neemt u contact op met de groep': 'To re-register this day, please contact the group',
        'Indienen': 'Submit',
        'Geen contracten gevonden': 'No contracts found',
        'Geen facturen gevonden' : 'No invoices found',
        'Geen documenten gevonden': 'No documents found',
        'Geen record gevonden': 'No record found',
        'Oud Wachtwoord': 'Old Password',
        'Nieuw Wachtwoord': 'New Password',
        'Herhaal Nieuw Wachtwoord': 'Confirm New Password',
        'Nieuwe berichtmeldingen': 'New Message Notifications',
        'Meldingen nieuw dagboek': 'New Diary Notifications',
        'Nieuwe afbeelding melding': 'New Image Notifications',
        'Nieuwe melding voor ophalen van kind': 'New notification for child pick up',
        'Nieuwe melding over allergie': 'New notification about allergy',
        'Nieuwe melding voor verschoning': 'New notification for change',
        'Nieuwe melding over voeding': 'New notification about nutrition',
        'Nieuwe melding slaapmoment': 'New notification sleep time',
        'Email': 'Email',
        'Selecteer een tegoed regel': 'Select a credit line',
        'uur': 'Hour',
        'Indien u geen tegoed regel kunt selecteren betekent dit dat er geen tegoed beschikbaar is. U kunt dan alleen een aanvragen doen via': 'If you cannot select a credit line, it means that no credit is available. You can then only apply via',
        'Tegoed geselecteerd': 'Credit selected',
        'geldig': 'valid',
        'Gewenste groep': 'Desired group',
        'Geen dagregels gevonden. Selecteer een andere dag': 'No day rules found. Select another day',
        'Naar welke nieuwe dag wilt u ruilen?': 'Which new day would you like to trade to?',
        'Welke geplande dag wilt u ruilen?': 'Which scheduled day do you want to exchange?',
        'Het wisselen van verschillende dagtypen is niet toegestaan': 'Changing different day types is not allowed',
        'Aanvragen': 'Send request',
        'Opslaan': 'Save',
        'Select Date': 'Selecteer een datum',
        'Ruildag aanvragen': 'Request an exchange day',
        'Ruiling aanvragen': 'Request an exchange day',
        'Afwezigheid doorgeven': 'Request an absence',
        'Selecteer een datum': 'Select a date',
        'Accountgegevens': 'Account details',
        'Gebruikersnaam': 'User name',
        'Actief sinds': 'active since',
        'Ouder 1 - Persoonlijke gegevens': 'Parent 1 - Personal Information',
        'Ouder 2 - Persoonlijke gegevens': 'Parent 2 - Personal Information',
        'Verstuur': 'Send',
        'Bericht': 'Message',
        'Berichten inladen...': 'Loading...',
        'Foto\'s inladen': 'Loading photos',
        'Foto\'s inladen...': 'Loading photos...',
        'Aanvragen inladen...': 'Loading requests...',
        'Kalender inladen...': 'Loading calendar...',
        'werkdagen vanaf geplande dag': 'working days from scheduled day',
        'Bedankt voor uw aanvraag': 'Thank you for your request',
        'Bedankt voor uw aanvraag.': 'Thank you for your request.',
        'Afsluiten': 'Close',
        'Sluiten': 'Close',
        'Uw aanvraag is naar de locatie gestuurd. U krijgt hiervan bericht.': 'Your request has been sent to the venue. You will be notified.',
        'Uw aanvraag is naar de locatie gestuurd. U krijgt hiervan bericht via de app.': 'Your request has been sent to the venue. You will be notified via the app.',
        'U ontvangt uiterlijk een dag voorafgaand aan de ruildag/extra dag, de bevestiging of afwijzing van uw verzoek':'You will receive confirmation or rejection on your request no later than one day prior to the exchange day/extra day.',
        'Toestemmingen': 'Permissions',
        'Afmelden niet mogelijk': 'Request Not Possbile',
        'U kunt voor vandaag geen afwezigheid meer doorgeven. Voor meer informatie neem contact op met de groep': 'You can no longer request an absence for today. For more information contact the group.',
        'In verband met het verstrijken van de uiterste afmeldtijd, kunt u uw kind vandaag alleen afmelden via "Berichten".':'Due to the expiration of the request absence time, you can only request today via "Messages".',
        'Nog in te vullen': 'Not yet completed',
        'Ja': 'Yes',
        'Nee': 'No',
        'U kunt dit niet meer wijzigen. Neem contact op met de groep': 'You can no longer change this. Contact the group.',
        'Selecteer beschikbaar tegoed': 'Select available credit',
        'Kies actie': 'Choose action',
        'Bezig met opslaan...': 'Saving...'
    }
}
