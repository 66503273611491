import { Child } from './../../models/child';
import { Transformer } from './../../interface/transformer';
import { HasOne } from './../../models/jsonapi/hasone';
import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import { DayLine } from '../../models/dayline';
import * as moment from 'moment';

/*
  Generated class for the ChildrenProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ChildDayLinesProvider extends JsonApiHelpProvider implements Transformer {

  constructor( public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/day-lines";
  }
  
  transform(data: JsonAPI){
    
    let rc;
    
    if(data.multiple()){
      // Transform multiple objects
      rc = [];
      data.data.forEach(relation => {
        
        let tmp = this.generate(relation);
        rc.push(tmp);
      });
      
    }else{
      // Transform one object
      rc = this.generate(data.data);
    }
      
    return rc;
  }  
  
  // Fill based on input
  protected generate(data){
    let dayline = new DayLine();
    let attributes = data.attributes;
    attributes.id = data.id;
    dayline.fill(attributes);
    
    dayline.child = new HasOne(this.api, data.relationships.child);
    dayline.group = new HasOne(this.api, data.relationships.group);
    
    return dayline;
  }

  public forChild(child: Child, fromDate : Date|string, toDate : Date|string){
      let vm = this;
      let from = moment(fromDate);
      let to = moment(toDate);
      // Get live data
      return this.api.get(vm.baseEndpoint + "?child-id=" + child.id + "&date-from=" + from.format("YYYY-MM-DD") + "&sort=date&limit=1000&date-to=" + to.format("YYYY-MM-DD") ).then(filterData => {
        return vm.transform(filterData);
      }, err => {
        return;
      })
  }
  

}
