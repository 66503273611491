import { JsonapiProvider } from './../../providers/jsonapi/jsonapi';

export class Relationship{

        public data : any;
        public type : any;
        public id : any;
        public links: any;

        // tslint:disable-next-line
        private primary : string;

        constructor(public api: JsonapiProvider){
            this.primary = "id";
        }

        public link(){
          if(this.links && this.links.related) {
            return this.links.related;
          }
        }


}
