import { Relatable } from "./relatable";

export class Location implements Relatable{
  public name: string;
  public is_active: boolean;
  public id: number;

  public fill(data){
    this['id'] = data['id'];
    this['is_active'] = data['is_active'];
    this['name'] = data['name'];

    return this;
  }
}

export interface RawLocation {
  type: string;
  id: number;
  attributes: {
    name: string;
    "external-id": string;
    "organisation-id": string;
    "is-active": boolean;
    "monthly-daylines": number;
    "weekly-daylines": number;
    "created-at": string;
    "updated-at": string;
    "deleted-at": string;
    "open-child-requests-count": number;
  },
  relationships: any,
  links: any,
}
