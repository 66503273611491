
export class ExternalFileAttachment{

  public 'id'           : number;

  public 'url'              : string;
  public 'external-id'      : number;
  public 'relation-id'      : number;

  public fill(data){
    this.id 						      = data.id;
    this['url'] 			    	  = data['url'];
    this['external-id']       = data['external-id'];
    this['relation-id']       = data['relation-id'];

    return this;
  }

}
