import { NgModule } from "@angular/core";
import { ChatBubbleComponent } from "./chat-bubble/chat-bubble";
import { ChildBubbleComponent } from "./child-bubble/child-bubble";
import { ExternalFilesComponent } from "./external-files/external-files";
import { MessageComponent } from "./message/message";
import { PhotoComponent } from "./photo/photo";
import { ScrollFixComponent } from "./scroll-fix/scroll-fix";
import { IonicModule } from "@ionic/angular";
import { LinkyModule } from "ngx-linky";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SwapRequestComponent } from "./swap-request/swap-request.component";
import { ParentsCrudComponent } from "./parents-crud/parents-crud";
import { ChildSwapRequestProvider } from "../providers/child-swap-request/child-swap-request";
import { RecapitulationComponent } from "./recapitulation/recapitulation.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  providers: [ChildSwapRequestProvider],
  declarations: [
    ChatBubbleComponent,
    ChildBubbleComponent,
    ExternalFilesComponent,
    MessageComponent,
    PhotoComponent,
    ScrollFixComponent,
    SwapRequestComponent,
    ParentsCrudComponent,
    RecapitulationComponent,
  ],
  imports: [CommonModule, FormsModule, IonicModule, IonicModule, LinkyModule, TranslateModule.forChild()],
  exports: [
    ChatBubbleComponent,
    ChildBubbleComponent,
    ExternalFilesComponent,
    MessageComponent,
    PhotoComponent,
    ScrollFixComponent,
    SwapRequestComponent,
    ParentsCrudComponent,
    RecapitulationComponent,
  ],
})
export class ComponentsModule {}
