import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';

import { JsonapiProvider } from '../jsonapi/jsonapi';
import { JsonAPI } from '../../models/jsonapi/jsonapi';
import { Transformer } from '../../interface/transformer';
import { ChildRequest } from '../../models/child-request';
import { Child } from '../../models/child';

/*
  Generated class for the ChildRequestsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ChildRequestsProvider extends JsonApiHelpProvider implements Transformer {

  constructor( public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/child-requests";
  }

  transform(data: JsonAPI){

    let rc;
    if(data.multiple()){
      // Transform multiple objects
      rc = [];
      data.data.forEach(req => {

        let tmp = this.generate(req);
        rc.push(tmp);
      });

    }else{
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }

  public getForChild(child : Child){
    return this.api.get(this.baseEndpoint + "?child-id=" + child.id + "&order=" + "desc").then(data => {
      return this.transform(data);
    });
  }

  // Fill based on input
  protected generate(data){
    let request = new ChildRequest();
    let attributes = data.attributes;
    attributes.id = data.id;
    request.fill(attributes);

   
    return request;
  }


}