export class PersonAddress {
  public id: number;

  public city: string;
  public 'house-number': string;
  public 'house-number-suffix': string;
  public 'postal-code': string;
  public 'street-name': string;

  public 'created-at': Date;
  public 'updated-at': Date;

  public fill(data) {
    this.id = data.id;
    this['city'] = data['city'];
    this['house-number'] = data['house-number'];
    this['house-number-suffix'] = data['house-number-suffix'];
    this['postal-code'] = data['postal-code'];
    this['street-name'] = data['street-name'];

    return this;
  }
}
