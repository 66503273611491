import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

/*
  Generated class for the TokenProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class TokenProvider {

  constructor(public storage: Storage) {
  }

  public hasExpired() {
    // console.log(this.storage.get('tokens'));
  }

  public set(data) {
    return this.storage.set('tokens', data);
  }

  public clear() {
    return this.storage.remove('tokens');
  }

  // Helper functions to get authentication data
  public get() {
    return this.storage.get('tokens');
  }

  public accessToken() {
    return this.storage.get('tokens').then(tokens => {
      if (tokens != null && tokens['access_token'] != null) {
        return tokens['access_token'];
      }

      return '';
    });
  }

  public refreshToken() {
    return this.storage.get('tokens').then(tokens => {
      if (tokens != null && tokens['refresh_token'] != null) {
        return tokens['refresh_token'];
      }

      return '';
    });
  }
}
