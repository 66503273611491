import {Injectable} from '@angular/core';
import { AlertController } from '@ionic/angular';
import {TranslationService} from "@core/providers/translation/translation";

/*
  Generated class for the AlertProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AlertProvider {

  constructor(
      public alert: AlertController,
      public t: TranslationService,
      ) {

  }

  async success(text = "Succes!", handler = () => {}) {
    let alert = await this.alert.create({
      header: "Success!",
      message: text,
      buttons: [{
        text: this.t._('Afsluiten'),
        handler
      }],
      backdropDismiss: false
    });
    await alert.present();
  }

  async error(text = "Fout!", title = "Error!", handler = () => {}) {
    let alert = await this.alert.create({
      header: title,
      message: text,
      buttons: [{
        text: this.t._('Afsluiten'),
        handler
      }],
      backdropDismiss: false
    });
    await alert.present();
  }

}
