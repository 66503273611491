import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { TokenProvider } from "../providers/token/token";
import { UsersProvider } from "../providers/users/users";
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private token: TokenProvider,
    private users: UsersProvider,
    private router: Router,
    private navCtrl: NavController
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve, reject) => {
      this.token.get().then(
        data => {
          if (data) {
            // Check if the access token has expired
            this.users
              .amIStillLoggedIn()
              .then(() => {
                resolve(true);
              })
              .catch(() => {
                console.log("catch amIStillLoggedIn error");
                this.navCtrl.navigateRoot(['login']);
                resolve(false);
              });
          } else {
            console.log("There is no token data");
            this.navCtrl.navigateRoot(['login']);
            resolve(false);
          }
        },
        err => {
          console.log("There is an error in getting the token.");
          this.navCtrl.navigateRoot(['login']);
          resolve(false);
        }
      );
    });
  }
}
