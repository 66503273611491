import { Input, Output, EventEmitter, Component } from '@angular/core';
import {MessagesListData} from "../../models/messages-list-data";
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Generated class for the Message component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'message',
  templateUrl: 'message.html',
  styleUrls: ['message.scss']
})
export class MessageComponent {

  @Input('messageListData')
  public messageListData : MessagesListData = new MessagesListData();

  @Output('messageClicked')
  public messageClicked = new EventEmitter();

  constructor(public sanitizer: DomSanitizer) {

  }

  callMessageClicked() {
    this.messageClicked.emit();
  }

}
