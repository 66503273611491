import { HasOne } from './jsonapi/hasone';
import { Moment } from "moment";

export class ScheduledNutrition{

  public 'id'           : number;
  public 'quantity'     : number;
  public 'time'         : string;
  public 'note'         : string;
  public 'child-id'     : number;
  public 'nutrition-id' : number;

  public 'created-at' : Date;
  public 'updated-at' : Date;

  public 'child'      : HasOne;
  public 'nutrition'  : HasOne;

  public 'name'                 : string;
  public 'unit'                 : string;
  public 'moment'               : Moment;
  public 'category-name'        : string;
  public 'nutrition-category-id': number;
  public 'image_url'            : string;

  public fill(data){
    this.id 						      = data.id;
    this['quantity'] 				  = data['quantity'];
    this['time'] 			        = data['time'];
    this['note'] 			        = data['note'];
    this['child-id'] 			    = data['child-id'];
    this['nutrition-id'] 	    = data['nutrition-id'];
    this['created-at'] 				= data['created-at'];
    this['updated-at'] 				= data['updated-at'];

    return this;
  }

}

