import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from "../json-api-help/json-api-help";
import { Transformer } from "../../interface/transformer";
import { JsonapiProvider } from "../jsonapi/jsonapi";
import { JsonAPI } from "../../models/jsonapi/jsonapi";
import { HasOne } from "../../models/jsonapi/hasone";
import { ScheduledNutrition } from "../../models/scheduled-nutrition";
import * as moment from "moment";

/*
  Generated class for the ScheduledNutritionsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ScheduledNutritionsProvider extends JsonApiHelpProvider implements Transformer {

  constructor(public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/children";
  }

  transform(data: JsonAPI){
    let rc;
    if(data.multiple()){
      // Transform multiple objects
      rc = [];
      data.data.forEach(relation => {

        let tmp = this.generate(relation);
        rc.push(tmp);
      });

    }else{
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }

  // Fill based on input
  protected generate(data){
    let scheduled_nutrition = new ScheduledNutrition();
    let attributes = data.attributes;
    attributes.id = data.id;
    scheduled_nutrition.fill(attributes);

    // Set relationships for each
    scheduled_nutrition.child = new HasOne(this.api, data.relationships.child);
    scheduled_nutrition.nutrition = new HasOne(this.api, data.relationships.nutrition);

    return scheduled_nutrition;
  }

  public getForChild(child){
    return this.api.get(
      '/v1/children/' + child.id +
      '/scheduled-nutritions?include=nutrition,nutrition-category'
    ).then(data => {
      let scheduled_nutritions : Array<ScheduledNutrition> = [];
      let nutritions = [];
      let scheduled_nutritions_request : Array<any> = data.data;
      nutritions = data.included;

      scheduled_nutritions_request.forEach(function(scheduled_nutrition_request, index){
        // Set scheduled nutrition
        let scheduled_nutrition = new ScheduledNutrition();
        scheduled_nutrition_request.attributes.id = scheduled_nutrition_request.id;
        scheduled_nutrition.fill(scheduled_nutrition_request.attributes);

        // Get the nutrition data and precache them into the scheduled_nutrition.
        let nutrition = nutritions.find(function(value){
          return (value.id == scheduled_nutrition['nutrition-id']);
        });
        scheduled_nutrition.name = nutrition.attributes.name;
        scheduled_nutrition.unit = nutrition.attributes.unit;

        // Put in the nutrition-category-id
        scheduled_nutrition['nutrition-category-id'] = nutrition.attributes['nutrition-category-id'];

        // Precache the moment of time for scheduled_nutrition, to avoid having to redo this all over again each time it's needed.
        scheduled_nutrition.moment = moment(scheduled_nutrition.time, 'HH:mm:ss');

        // Make scheduled.nutrition.time follow the same format on the client site (09:00 instead of 09:00:00)
        scheduled_nutrition.time = scheduled_nutrition.time.substring(0, scheduled_nutrition.time.length - 3);

        scheduled_nutritions.push(scheduled_nutrition);
      });

      // Sort the scheduled nutritions by time.
      scheduled_nutritions.sort(function(a, b){
        return a.moment.diff(b.moment);
      });

      return scheduled_nutritions;
    });
  }

}
