import { CacheProvider } from './../cache/cache';
import { Relationship } from './../../models/jsonapi/relationship';
import { ApiProvider } from './../api/api';
import { Injectable } from '@angular/core';
import { JsonAPI } from '../../models/jsonapi/jsonapi';


/*
  Generated class for the JsonapiProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class JsonapiProvider {


  constructor( private api: ApiProvider, private cache: CacheProvider ){
    this.cache.setNamespace('jsonapi-provider');
  }


  public get( endpoint ){
    let vm = this;
    return this.api.get(endpoint).then( data => {
      return vm.transform(data);
    })
  }

  public post( endpoint: string, data: any ){
    let vm = this;
    return this.api.post(endpoint, data).then(data => {
      return vm.transform(data);
    });
  }

  public getCached(endpoint, cachePeriod: Date){
    // Check if we have a cached element
    return this.cache.get(endpoint).then(data => {
      if(data == null){
        // Do normal call
        return this.get(endpoint).then( raw => {
          // Set cache in storage
          return this.cache.set(endpoint, raw, cachePeriod ).then(() =>{
            // Return data we retrieved earlier
            return this.transform(raw);
          })
        });
      }
      // Cached item found
      return this.transform(data);

    });
  }

  // Gets relationship data
  public relation(relation: Relationship){
      // Remove first part of the url
      let endpoint = relation.links.related.replace(/^.*\/\/[^\/]+/, '/');
      return this.get(endpoint);
  }

  public relationCached(relation: Relationship, cachePeriod: Date){
      // Remove first part of the url
    if(relation && relation.links) {
      let endpoint = relation.links.related.replace(/^.*\/\/[^\/]+/, '/');
      return this.getCached(endpoint, cachePeriod);
    }

      return Promise.reject(new Error('No relation passed.'));
  }

  // Convert to JSONAPI model
  public transform(data){
    let model = new JsonAPI();
    model.fill(data);

    return model;

  }



}
