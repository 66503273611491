import {Injectable} from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

/*
  Generated class for the LoadingProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ToastProvider {

  constructor(public toast: ToastController) {

  }

  async error(text = "Fout!") {
    const toast = await this.toast.create({
      message: text,
      color: 'danger',
      duration: 7000
    });
    await toast.present();
  }

}
