import { Component, Input, OnInit } from '@angular/core';
import { DayLine } from 'src/app/models/dayline';
import { Child } from 'src/app/models/child';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Group } from 'src/app/models/group';
import { AlertController, LoadingController, ModalController, NavController, NavParams } from '@ionic/angular';
import { SyncedDataProvider } from 'src/app/providers/synced-data/synced-data';
import { ChildSwapRequestProvider } from 'src/app/providers/child-swap-request/child-swap-request';
import { ConfigProvider } from 'src/app/providers/config/config';
import Relator from 'src/app/helpers/relator/relator';
import { ChildSwapRequest } from 'src/app/models/child-swap-request';
import { Person } from 'src/app/models/person';
import { RecapitulationComponent } from '../recapitulation/recapitulation.component';
import { PermissionsProvider } from '@core/providers/permissions/permissions';
import {TranslationService} from "@core/providers/translation/translation";

@Component({
  selector   : 'app-swap-request',
  templateUrl: './swap-request.component.html',
  styleUrls  : ['./swap-request.component.scss'],
})
export class SwapRequestComponent {

  @Input() dayline: DayLine     = new DayLine();
  @Input() groups: Array<Group> = [];
  private child: Child          = new Child();
  public loading;

  private fromtime: string;
  private totime: string;
  private fromdate: Moment;
  private todate: Moment;
  private togroup: string;
  private group: Group;

  private dates: Array<Moment> = new Array();


  constructor(
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public navParams: NavParams,
    public loadingCtrl: LoadingController,
    public syncedData: SyncedDataProvider,
    // TODO: FIXTHIS
    // public viewCtrl: ViewController,
    public swapprovider: ChildSwapRequestProvider,
    public alertCtrl: AlertController,
    private config: ConfigProvider,
    private permissions: PermissionsProvider,
    public t: TranslationService,
  ) {
  }

  ionViewDidEnter() {
    const vm        = this;
    console.log('Ion View Did Load');
    this.dayline  = this.navParams.get('dayline');
    this.groups   = this.navParams.get('groups');
    this.fromdate = moment(this.dayline.date);
    // this.todate   = moment(new Date(this.dayline.date));
    this.fromtime =
      this.dayline['time-from'].split(':')[0] +
      ':' +
      this.dayline['time-from'].split(':')[1];
    this.totime   =
      this.dayline['time-to'].split(':')[0] +
      ':' +
      this.dayline['time-to'].split(':')[1];

    this.togroup = this.dayline['group-id'].toString();
    this.group   = this.groups[Relator.findKeyById(this.togroup, this.groups)];

    this.syncedData.initialSync().then(() => {
      this.child = this.syncedData.syncedChildren.find(function(item) {
        return item.id == vm.dayline['child-id'];
      });
    });

    this.targetDates();
  }

  getPersonForChild(child: Child) {
    this.dismissLoading();
    // Search the person in the people list
    let person = this.syncedData.syncedPeople.find(function(item) {
      return item.id == child['person-id'];
    });

    if (person == undefined) {
      return new Person();
    } else {
      return person;
    }
  }

  async showLoading(text) {
    this.loading = await this.loadingCtrl.create({
      message: text
    });

    await this.loading.present();
  }

  dismissLoading() {
    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
  }

  onGroupChanged() {
    this.targetDates();
  }

  targetDates() {
    this.dates = [];
    this.group = this.groups[Relator.findKeyById(this.togroup, this.groups)];
    // let shard  = this.config.get('API_SHARD');
    // console.log(shard);

    // if (this.dates.length > 0) {
    //   return this.dates;
    // } else {
    //   // Atleast 14 records
    //   let counter = 0;

    //   // Minimal date is tomorrow
    //   //let startdate = this.fromdate;
    //   let startdate = moment();
    //   let max_days  = 90;

    //   if (this.group['care-type'] == 'tso' && shard == 'broodenspelen') {
    //     // For BroodEnSpelen, only 14 days from date.
    //     let days = 0;
    //     while (days < 14) {
    //       counter = counter + 1;
    //       days++;
    //       let date      = moment(startdate).add(counter, 'days');
    //       let dayOfWeek = parseInt(date.format('E'));
    //       if (date.isSameOrBefore(startdate)) {
    //         continue;
    //       }
    //       if (dayOfWeek == 6 || dayOfWeek == 7) {
    //         continue;
    //       }
    //       this.dates.push(date);
    //     }
    //   } else if (shard == 'eigenenwijzer') {
    //     while (counter <= 30) {
    //       counter++;
    //       let date      = moment(startdate).add(counter, 'days');
    //       let dayOfWeek = parseInt(date.format('E'));
    //       if (date.isSameOrBefore(startdate)) {
    //         continue;
    //       }
    //       if (dayOfWeek == 6 || dayOfWeek == 7) {
    //         continue;
    //       }
    //       this.dates.push(date);
    //     }
    //   } else {
    //     // Normal
    //     while (this.dates.length < max_days) {
    //       counter       = counter + 1;
    //       let date      = moment(startdate).add(counter, 'days');
    //       let dayOfWeek = parseInt(date.format('E'));
    //       if (date.isSameOrBefore(startdate)) {
    //         continue;
    //       }
    //       if (dayOfWeek == 6 || dayOfWeek == 7) {
    //         continue;
    //       }
    //       this.dates.push(date);
    //     }
    //   }
    // }

    const currentDate = moment(new Date());
    currentDate.add(1, 'day');
    console.log(this.group.swapSetting);
    let day = moment(new Date(this.dayline.date));
    for (let index = 0; index < this.group.swapSetting.maxPast; index++) {
        day = this.removeBusinessDays(day, 1);

        if (day.isBefore(currentDate)) {
          break;
        }

        this.dates.unshift(day);
        day = moment(new Date(day.toDate()));      
    }
    day = moment(new Date(this.dayline.date));
    this.dates.push(day);
    // this.dates = [...this.dates];
    for (let index = 0; index < this.group.swapSetting.maxFuture; index++) {
        day = this.addBusinessDays(day, 1);
        this.dates.push(day);      
        day = moment(new Date(day.toDate()));
    }
  }

  private getMaxDates() {
    // If group is TSO, then max is 14 days.
    if (this.group['care-type'] == 'tso') {
      return 14;
    } else {
      return 90;
    }
  }

  cancel() {
    // TODO:FIXTHIS
    this.modalCtrl.dismiss();
  }

  async swap() {
    if (!this.todate) {
      const alert = await this.alertCtrl.create({header: 'Fout', subHeader: 'U moet een datum selecteren!', buttons: [
        {
        text: 'Ok',
        handler: () => {
        }
      }]});
      alert.present();
      return;
    }
    let swap = new ChildSwapRequest();

    // Generate 2 fake groups (just for id's)
    let attendGroup = new Group();
    let absentGroup = new Group();

    attendGroup.fill({id: this.togroup});
    absentGroup.fill({id: this.dayline['group-id']});

    swap.fill({
      'from-date'       : this.fromdate.toDate(),
      'to-date'         : this.todate.toDate(),
      'absent-from-time': this.dayline['time-from'],
      'absent-to-time'  : this.dayline['time-to'],
      'attend-from-time': this.fromtime + ':00',
      'attend-to-time'  : this.totime + ':00',
      'attend-group'    : attendGroup,
      'absent-group'    : absentGroup,
      description       : '',
      child             : this.child
    });

    const modal = await this.modalCtrl.create({
      component: RecapitulationComponent,
      componentProps: {
        request: swap
      }
    })

    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data.status) {

    await this.showLoading('Ruildag doorgeven...');

    // Save it, alert the user and close it
    this.swapprovider.request(swap).then(async data => {
      this.dismissLoading();

      let alert = await this.alertCtrl.create({
        header   : 'Aanvraag doorgegeven',
        subHeader: 'U krijgt bericht over uw aanvraag via de app.',
        buttons  : ['OK']
      });
      if (this.permissions.childOnlyHasTSO(this.child) && this.config.get("API_SHARD") == 'broodenspelen') {
        alert = await this.alertCtrl.create({
          header: "Aanvraag goedgekeurd",
          subHeader: "Uw aanvraag voor extra opvang is goedgekeurd.",
          buttons: ["Sluiten"],
        });
      };

      await alert.present();
      // TODO: FIXTHIS
      this.modalCtrl.dismiss();
    });
    } else {
      this.modalCtrl.dismiss();
    }
  }

  addBusinessDays(originalDate: Moment, numDaysToAdd) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;
  
    const newDate = originalDate.clone();
  
    while (daysRemaining > 0) {
      newDate.add(1, 'days');
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining--;
      }
    }
  
    return newDate;
  }

  removeBusinessDays(originalDate: Moment, numDaysToRemove) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToRemove;
  
    const newDate = originalDate.clone();

  
    while (daysRemaining > 0) {
      newDate.subtract(1, 'days');
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining--;
      }
    }
  
    return newDate;
  }

}
