import { Component, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import {TranslationService} from "@core/providers/translation/translation";

/**
 * Generated class for the ChatBubbleComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'chat-bubble',
  templateUrl: './chat-bubble.html'
})
export class ChatBubbleComponent {

  @Input('self')
  public self: boolean;

  @Input('date')
  public date: Date;

  @Input('message')
  message: string;

  @Input('sending')
  sending: boolean;

  @ViewChild('bubble') inner: any;

  constructor(private t: TranslationService) {
    this.self = false;
  }

  messageClass(){
    return this.self ? "bubble-self" : "bubble-other";
  }

  public formatDate(){
    return moment(this.date || new Date()).locale(this.t.language).fromNow();
  }
}
