import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Transformer } from './../../interface/transformer';
import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import { ChatMessage } from '../../models/chat-message';

/*
 Generated class for the ChatMessagesProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable()
export class ChatMessagesProvider extends JsonApiHelpProvider implements Transformer {

  constructor(public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/chat-messages";
  }

  transform(data: JsonAPI) {

    let rc;
    if (data.multiple()) {
      // Transform multiple objects
      rc = [];
      data.data.forEach(channel => {

        let tmp = this.generate(channel);
        rc.push(tmp);
      });

    } else {
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }


  // Fill based on input
  protected generate(data) {
    let chatmessage = new ChatMessage();
    let attributes = data.attributes;
    attributes.id = data.id;
    chatmessage.fill(attributes);

    // Set relationships for each
    //child.person = new HasOne(this.api, data.relationships.person);
    // user.relation = new HasOne(this.api, data.relationships.relation);

    return chatmessage;
  }

  public getMessages(chat_channel_id: number, cursor: Date, page: number = 1, limit: number = 10) {
    let vm = this;
    let items = [
      'cursor' + '=' + encodeURIComponent( cursor.toISOString() ),
      'cursor_direction' + '=' + 'desc',
      'cursor_field' + '=' + 'created_at',
      'filter[channels][]' + '=' + chat_channel_id,
      'limit' + '=' + limit,
      'page' + '=' + page,
      'sort' + '=' + '-created_at'
    ];
    let querystring = items.join("&");

    return vm.api.get(vm.baseEndpoint + "?" + querystring).then(data => {
      return vm.transform(data);
    }, err => {
      return;
    })
  }


  public sendMessage(body: string, chat_channel_id: number, user_id: number) {
    let vm = this;
    let date = new Date();
    var data = {
      "data": {
        "attributes": {
          "body": body,
          "created-at": date.toISOString()
        },
        "relationships": {
          "chat-channel": {
            "data": {
              "type": "chat-channels",
              "id": chat_channel_id
            }
          },
          "user": {
            "data": {
              "type": "users",
              "id": user_id
            }
          }
        },
        "type": "chat-messages"
      }
    }

    return this.api.post(vm.baseEndpoint, data).then(data => {
        return vm.transform(data);
    }, err => {
      return;
    })
  }
}
