import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {TranslationService} from "@core/providers/translation/translation";

/*
  Generated class for the LoadingProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class LoadingProvider {

  private loading;

  constructor(public loadingCtrl: LoadingController, public t: TranslationService) {

  }

  async showLoading(text = this.t._("Bezig met laden")) {
    console.log("Showing loading");
    if (this.loading) {
      await this.dismissLoading();
    }
    this.loading = await this.loadingCtrl.create({
      message: text
    });

    await this.loading.present();
  }

  async dismissLoading() {
    console.log(this.loading);
    if (this.loading) {
      await this.loading.dismiss();
    }
    try {
      await this.loading.dismiss();
    } catch(_e) {

    }
  }

}
