import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {path: '', redirectTo: 'overview', pathMatch: 'full'},
  {
    path        : 'mijn-account',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('./pages/mijn-account/mijn-account.module').then(
        m => m.MijnAccountPageModule
      )
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then(m => m.LoginPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./login/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: "overview",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/overview/overview.module").then(m => m.OverviewPageModule)
  },
  {
    path        : 'child',
    canActivate : [AuthGuard],
    loadChildren: () => import('./pages/child/child.module').then(m => m.ChildPageModule)
  },
  {
    path        : 'pdf-viewer',
    canActivate : [AuthGuard],
    loadChildren: () => import('./pages/pdf-js-viewer/pdf-js-viewer.module').then(m => m.PdfJsViewerPageModule)
  },
  {
    path        : 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path        : 'reset-test-user',
    loadChildren: () => import('./pages/reset-test-user/reset-test-user.module').then(m => m.ResetTestUserPageModule)
  },
  {
    path        : 'dev/synced-data',
    loadChildren: () => import('./dev/synced-data/synced-data.module').then(m => m.SyncedDataPageModule)
  },
  {
    path        : 'dev/calendar-tester',
    loadChildren: () => import('./dev/calendar-tester/calendar-tester.module').then(m => m.CalendarTesterPageModule)
  },
  {
    path: 'dev/api-tester',
    loadChildren: () => import('./dev/api-tester/api-tester.module').then( m => m.ApiTesterPageModule)
  },
  {
    path        : 'download-photos/:id',
    loadChildren: () => import('./pages/download-photos/download-photos.module').then(m => m.DownloadPhotosPageModule)
  },
  {
    path: 'report-absence/:id',
    loadChildren: () => import('./pages/report-absence/report-absence.module').then( m => m.ReportAbsencePageModule)
  },
  {
    path: 'request-extra-day/:id',
    loadChildren: () => import('./pages/request-extra-day/request-extra-day.module').then( m => m.RequestExtraDayPageModule)
  },
  {
    path: 'request-swap-day/:id',
    loadChildren: () => import('./pages/request-swap-day/request-swap-day.module').then( m => m.RequestSwapDayPageModule)
  },
  {
    path: 'requests/:id',
    loadChildren: () => import('./pages/requests/requests.module').then( m => m.RequestsPageModule)
  },
  {
    path: 'request/:id',
    loadChildren: () => import('./pages/request/request.module').then( m => m.RequestPageModule)
  },
  {
    path: 'test-requests-page',
    loadChildren: () => import('./pages/test-requests-page/test-requests-page.module').then( m => m.TestRequestsPagePageModule)
  },
  {
    path: 'test-anything',
    loadChildren: () => import('./pages/test-anything/test-anything.module').then( m => m.TestAnythingPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
