import { DOCUMENT } from '@angular/common';
import { Component, Inject, isDevMode, Renderer2, ViewChild } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { ConfigProvider } from '@core/providers/config/config';
import { IonHeader, LoadingController, MenuController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { ApiProvider } from './providers/api/api';
import { AuthenticationProvider } from './providers/authentication/authentication';
import { CalendarService } from './providers/calendar/calendar.service';
import { GalleryService } from './providers/gallery/gallery.service';
import { NavService } from './providers/nav/nav.service';
import { NotificationProvider } from './providers/notification/notification';
import { TranslationService } from "@core/providers/translation/translation";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  preloaded = false;
  currentHeader = '';
  currentHeaderSub;
  showBackButton = false;
  showBackButtonSub;
  listview = false;
  listviewSub;
  showNewRequestsButton = false;
  showNewRequestsButtonSub;

  showHeader = true;
  showHeaderSub;

  gallerySub;
  isMenuOpen = false;
  public galleryView = 'fotoalbum';
  public marginFix = '44px';

  private loading;

  public isLogged;
  public isLoggedSub;
  public language;

  @ViewChild(IonHeader) header: IonHeader;

  constructor(
    private activatedRoute: ActivatedRoute,
    private platform: Platform,
    public navService: NavService,
    public calService: CalendarService,
    public menu: MenuController,
    public galService: GalleryService,
    public loadingCtrl: LoadingController,
    public authentication: AuthenticationProvider,
    private api: ApiProvider,
    private notification: NotificationProvider,
    private renderer: Renderer2,
    private storage: Storage,
    private config: ConfigProvider,
    private t: TranslationService,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.log(isDevMode())

    document.title = this.config.get('API_SHARD');
    this.currentHeaderSub = this.navService.currentHeader.subscribe(header => {

      if (this.isMenuOpen) {
        this.menu.close();
      }

      if (header) {
        this.preloaded = true;

        switch (header) {
          case 'Overview':
            this.currentHeader = '';
            break;
          case 'Download photos':
            this.currentHeader = 'Download foto\'s';
            break;
          default:
            this.currentHeader = header;
        }
      }

      document.title = this.currentHeader + ' - ' + this.config.get('API_SHARD');
    });

    this.isLoggedSub = this.navService.isLogged.subscribe(isLogged => {

      this.isLogged = isLogged;
    });

    this.showBackButtonSub = this.navService.showBackButton.subscribe(showBackButton => this.showBackButton = showBackButton);

    this.showHeaderSub = this.navService.isHeaderEnabled.subscribe((_ => this.toggleHeader()));

    this.listviewSub = this.calService.listViewSubject.subscribe(listView => this.listview = listView);
    this.showNewRequestsButtonSub = this.calService.showNewRequestsButtonSubject.subscribe(showNewRequestsButton => this.showNewRequestsButton = showNewRequestsButton);
    this.gallerySub = this.galService.galleryView.subscribe(view => this.galleryView = view);
    moment.locale('nl');

    this.initializeApp();
  }

  async initializeApp() {
    const vm = this;
    await this.t.initialize()
    this.language = await this.t.getLanguage()
    console.log('LANGUAGE', this.language)
    this.platform.ready().then(platform => {
      if (!this.notification.token) {
        this.storage.get('notificationToken').then(token => {
          vm.notification.token = token;
        });
      }
      console.log(platform);
      this.navService.isCordova = (platform.toLowerCase() === 'cordova');
      // this.statusBar.styleDefault();
      // StatusBar.styleDefault();
      // StatusBar.setStyle(StatusBarStyle.Light);

      // SplashScreen.hide(); // Uncommenting this will make the header bar merge with the top area
      setTimeout(() => this.processHeader(), 3000);
      console.log('INITIALIZE APP NOTIFICATION')
      this.notification.setupNotificationsHook();
      this.notification.handlePushNotifications();
    });
  }

  processHeader() {
    console.log('here');
    if (this.navService.isCordova) {
      console.log('here');
      const header = this.document.querySelector('ion-header');
      if (header) {
        this.marginFix = `${header.offsetHeight}px`;
        const routerOutler = this.document.querySelector('ion-router-outlet');
        this.renderer.setStyle(routerOutler, 'margin-top', this.marginFix);
        console.log('Trying to hide splashscreen');
        SplashScreen.hide()
        window.setTimeout(() => {
          console.info('hiding splash');
          SplashScreen.hide()
          this.renderer.setStyle(routerOutler, 'margin-top', this.marginFix)
        }, 2000);
        window.setTimeout(() => this.renderer.setStyle(routerOutler, 'margin-top', this.marginFix), 3000)
        window.setTimeout(() => this.renderer.setStyle(routerOutler, 'margin-top', this.marginFix), 4000)
      }
    }
  }

  toggleHeader() {
    if (this.showHeader) {
      const routerOutler = this.document.querySelector('ion-router-outlet');
      this.renderer.setStyle(routerOutler, 'margin-top', '0');
      this.showHeader = false;
    } else {
      const routerOutler = this.document.querySelector('ion-router-outlet');
      this.renderer.setStyle(routerOutler, 'margin-top', this.marginFix);
      this.showHeader = true;
    }
  }

  openMenu() {
    this.menu.open('menu');
    this.isMenuOpen = true;
    this.menu.getMenus().then(menus => console.log(menus));
  }

  OnDestroy() {
    if (this.currentHeaderSub) {
      this.currentHeaderSub.unsubscribe();
    }
    if (this.showBackButtonSub) {
      this.showBackButtonSub.unsubscribe();
    }
    if (this.gallerySub) {
      this.gallerySub.unsubscribe();
    }

    if (this.isLoggedSub) {
      this.isLoggedSub.unsubscribe();
    }

    if (this.showHeaderSub) {
      this.showHeaderSub.unsubscribe();
    }
  }

  back() {
    this.navService.back();
  }

  btnEditRequest() {
    this.calService.btnEditRequest();
  }

  btnNewRequest() {
    this.calService.btnNewRequest();
  }

  switch() {
    this.calService.switch();
  }

  changeGalleryView() {
    this.galService.changeView();
  }

  myaccount() {
    this.navService.navigateForward('mijn-account');
  }

  changePassword() {
    this.navService.navigateForward('mijn-account/wachtwoord-verander');
  }

  invoices() {
    this.navService.navigateForward('mijn-account/facturen');
  }

  contracts() {
    this.navService.navigateForward('mijn-account/contracten');
  }

  documents() {
    this.navService.navigateForward('mijn-account/documenten');
  }

  notifications(event = null) {
    console.log(event);
    this.navService.navigateForward('mijn-account/meldingen');
  }

  myNameAndAddress() {
    this.navService.navigateForward('mijn-account/mijn-naw-gegevens');
  }

  annualCare() {
    this.navService.navigateForward('mijn-account/jaaropvangen');
  }

  correspondence() {
    this.navService.navigateForward('mijn-account/correspondentie');
  }

  userPolicy() {
    window.open('https://wedaycare.com/gebruikersvoorwaarden-ouder-app-en-platform/', 'location=yes');
    if (this.isMenuOpen) {
      this.menu.close();
    }
  }

  privacyPolicy() {
    window.open('https://wedaycare.com/privacyvoorwaarden/', 'location=yes');
    if (this.isMenuOpen) {
      this.menu.close();
    }
  }


  async logout() {
    await this.showLoading('Uitloggen...');
    const vm = this;

    // This is very important, unregister FCM notifications.
    // Otherwise user will get notified over and over again.
    this.api.post('/v1/fcm/unregister-token', {
      token: vm.notification.token,
    }).then(() => {
      this.authentication.logout().then(() => {
        this.notification.unregister();
        this.dismissLoading();
        // User has been signed off. Go back to the login page
        this.navService.navigateRoot('login');
      });
    });
  }

  async showLoading(text) {
    this.loading = await this.loadingCtrl.create({
      message: text
    });

    await this.loading.present();
  }

  dismissLoading() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }

  goToWebportal() {
    const currentBrand = this.config.get('API_SHARD');
    window.open('https://' + currentBrand + '.web.wedaycare.com/login', '_system', 'location=yes');
    return
  }
}
