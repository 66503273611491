import { AuthenticationProvider } from './../authentication/authentication';
import { ConfigProvider } from './../config/config';
import { Injectable, Injector } from '@angular/core';

import { catchError, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { TokenProvider } from '../token/token';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  error: HttpErrorResponse;

  constructor(public tokens: TokenProvider, public config: ConfigProvider, public inj: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.tokens.get())
      .pipe(switchMap(tokens => {
        let newReq = request.clone({headers: this.headers(request, tokens)});

        return next.handle(newReq).pipe(
          map((event: HttpEvent<any>) => {
            return event;
          }), catchError(error => {
            if (error instanceof HttpErrorResponse) {
              if (error.status == 403 || error.status == 401) {
                this.error = error;
                return this.refreshToken(request, next);
              }
            }
            return throwError(error);
          }));

      }));
  }

  shard(tokens?, data?) {
    // if ((tokens != null && tokens['username'] != null && (tokens['username'] == "developers@wedaycare.com" || tokens['username'] == "info@wedaycare.com" ))
    // || (data != null && data['username'] != null && (data['username'] == "developers@wedaycare.com" || data['username'] == "info@wedaycare.com" ))) {
    //     return "default";
    // }
    //
    if (
      (data && data['username'] === 'info@wedaycare.com') || (tokens && tokens['username'] === 'info@wedaycare.com')) {
      return 'default';
    }
    return this.config.get('API_SHARD');
  }

  headers(request: HttpRequest<any>, tokens?) {
    let headers = request.headers;
    headers     = headers.append('Content-Type', 'application/json');
    headers     = headers.append('Accept', 'application/json');
    headers     = headers.append('jdc-shard', this.shard(tokens, request.body));
    if (tokens != null) {
      let bearer = 'Bearer ' + tokens['access_token'];
      headers    = headers.append('Authorization', bearer.trim());
    }
    return headers;
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authprov = this.inj.get(AuthenticationProvider);

    // If Authentication provider is refreshing queue up all calls
    if (!authprov.isRefreshing()) {
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      console.log('refreshing')
      return from(this.tokens.get().then(tokens => {
          console.log('truly refreshing')
          return this.refresh(tokens['refresh_token']).then(() => {
            console.log('refresh success')
            return this.tokens.get();
          }).catch(() => {
            console.log('refresh error 1')
          });
        }).catch(() => console.log('refresh error'))
      ).pipe(switchMap((tokens) => {
          console.log('pipe')
          this.tokenSubject.next(tokens);
          let newReq = req.clone({headers: this.headers(req, tokens)});
          return next.handle(newReq);
        })
        , catchError(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          console.log('refresh token bad, logout')
          return this.logoutUser();
        })
        , finalize(() => {
          //this.isRefreshingToken = false;
        }));
    } else {
      return this.tokenSubject.pipe(
        filter(tokens => tokens != null)
        , take(1)
        , switchMap(tokens => {
          let newReq = req.clone({headers: this.headers(req, tokens)});
          return next.handle(newReq);
        }));
    }
  }

  logoutUser() {
    if (this.error != null) {
      return throwError(this.error);
    }

    return throwError('');
  }

  public refresh(refresh_token: string) {
    // Repeat API post to retrieve access token
    return this.inj.get(AuthenticationProvider).refresh(refresh_token).then(rc => {
      return rc;
    }, error => {
      return false;
    });

  }


}
