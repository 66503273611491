import { Observable, throwError } from 'rxjs';
import { timeout, tap } from 'rxjs/operators'
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Timeout in seconds
    let _timeout = 15;


    return next.handle(req).pipe(timeout(_timeout * 1000), tap(event => {}, err => { // timeout of 5000 ms
        if(err instanceof HttpErrorResponse){
            console.warn("Request timeout occured");
            throwError(err);
        }
    }));
  }
}