import { Injectable } from '@angular/core';
import { Transformer } from "../../interface/transformer";
import { JsonApiHelpProvider } from "../json-api-help/json-api-help";
import { JsonapiProvider } from "../jsonapi/jsonapi";
import { Nutrition } from "../../models/nutrition";

/*
  Generated class for the NutritionProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class NutritionProvider extends JsonApiHelpProvider implements Transformer {

  constructor( public api: JsonapiProvider) {
    super(api);
  }

  protected generate(data){
    let nutrition = new Nutrition();
    let attributes = data.attributes;
    attributes.id = data.id;
    nutrition.fill(attributes);

    return nutrition;
  }
}
