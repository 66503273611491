import { ConfigProvider } from './../config/config';
import { ApiProvider } from './../api/api';
import { Injectable } from '@angular/core';
import { UsersProvider } from './../users/users';
import * as moment from 'moment';

import { PeopleProvider } from '../people/people';
import { TokenProvider } from '../token/token';
import { CacheProvider } from '../cache/cache';
import { User } from '@core/models/user';
import { NavController } from '@ionic/angular';

/*
 Generated class for the AuthenticationProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable()
export class AuthenticationProvider {
  private _refreshing: Boolean = false;
  public lastLogin: any = {};

  constructor(
    public api: ApiProvider,
    public config: ConfigProvider,
    public token: TokenProvider,
    public users: UsersProvider,
    public people: PeopleProvider,
    public cache: CacheProvider,
    private navCtrl: NavController,
  ) {}

  public isRefreshing() {
    return this._refreshing;
  }

  public isAuthenticated() {
    return this.token.get().then(
      data => {
        if (data) {
          // There are tokens. Are they still valid?
          let expired = moment().isAfter(moment(data.expires, "X"));
          if (expired) {
            // Lets try a refresh
            return this.refresh(data["refresh_token"]).then(
              refresh => {
                return refresh;
              },
              error => {
                return false;
              }
            );
          } else {
            // Validate the access token if it still works with calling current user info.
            return this.users.self().then(
              user => {
                return true;
              },
              err => {
                return false;
              }
            );
          }
        }
        return false;
      },
      err => {
        return false;
      }
    );
  }

  refresh(refresh_token: string) {
    let vm = this;
    if (this._refreshing) {
      return new Promise(function(resolve, reject) {
        (function waitForRefresh() {
          if (!vm._refreshing) {
            return resolve(true);
          }
          setTimeout(waitForRefresh, 50);
        })();
      });
    } else {
      this._refreshing = true;
      // Get current authentication data and refresh the token with new data
      return this.api.refresh(refresh_token).then(data => {
        vm._refreshing = false;
        return data;
      });
    }
  }

  login(email: string, password: string) {
    const vm = this;

    // Do validation
    let body = {
      grant_type: "password",
      client_id: this.config.get("API_CLIENT_ID"),
      client_secret: this.config.get("API_CLIENT_SECRET"),
      username: email,
      password: password
    };

    return this.api.post("/v1/oauth/access_token", body).then(data => {
      // Custom data item that adds the amount of seconds to this date and outputs the unix time.
      data["expires"] = moment()
        .add(data["expires_in"], "s")
        .format("X");
      data["username"] = email;
      this.lastLogin = data["last-login"];
      this.token.set(data);

      vm.cache.uniquePrefix = data["refresh_token"];
      console.log('Handling access token')
    }).catch(() => {
      console.log('Error in access token') 
    });
  }

  logout() {
    const vm = this;
    return new Promise((resolve, reject) => {
      this.token.clear().then(() => {
        // TODO: Clear CacheProvider and SyncedData
        vm.cache.clearAll(true).then(() => {
          resolve();
        });
      });
    });
  }

  forgot(email: string) {
    let body = {
      email: email
    };

    return this.api.postRaw("/v1/passwords/email", body).then(
      data => {
        return true;
      },
      error => {
        // Current guess is that the email adres is unknown.
        return false;
      }
    );
  }

  checkIfPasswordNeedsChange() {
    let vm = this;
    return vm.api.get("/v1/users").then(res => {
      let lastLogin             = vm.lastLogin;
      let suggestPasswordChange =
            res['data']['attributes']['suggest-password-change'];
      const user                = res['data']['attributes'] as User;

      if (!user['has-agreed-to-terms']) {
        this.navCtrl.navigateRoot(['/terms']);
      }

      return !lastLogin || suggestPasswordChange;
    });
  }
}
