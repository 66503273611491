import { Relationship } from "./relationship";
import { JsonapiProvider } from './../../providers/jsonapi/jsonapi';

export class HasMany extends Relationship{
    
        constructor(api: JsonapiProvider, data: any){
            super(api);

            this.fill(data);
        }


        public fill(data){
            this.data = data.data;
            this.links = data.links;

            return this;
        }
    

    
    
}
