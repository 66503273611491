import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  public navSub;
  public showBackButton = new Subject<boolean>();
  public currentHeader = new Subject<string>();
  public state;
  private customHeader = false;
  private customHeadersStack = [];
  public routeHistory: any = null

  private navStack = [];

  public isCordova = false;

  public isLogged = new Subject<boolean>();

  public isHeaderEnabled = new Subject<boolean>();

  constructor(
    public router: Router,
    public navCtrl: NavController) {
    this.navSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isLogged.next(event['urlAfterRedirects'].indexOf('login') === -1);
        this.parseHeader(event['urlAfterRedirects']);
      }
    });
   }

   parseHeader(currentPage: string): void {
     if (currentPage === '/') return;

      // Diabolical function steps
      // currentPage = login/forgottent-password
      const page = currentPage.split('/').pop()
      if (page != '') {
        this.navStack.push(page);
      }
      // split 1 -> [login, forgotten-password]
      // pop -> forgotten-password
      // split 2 -> [forgotten, password]
      // join -> forgotten password
      // split 3 -> [array of chars]
      const split = page.split('-').join(' ').split('');
      split[0] = split[0].toUpperCase();
      const header = split.join('');
      this.showBackButtonParse(header);
      if (!this.customHeader) {
        const _customHeader = this.customHeadersStack.length > 0 ? this.customHeadersStack[this.customHeadersStack.length - 1] : '';
        if (!!_customHeader) {
          this.currentHeader.next(_customHeader);
        } else {
          this.currentHeader.next(header);
        }
      }
   }

   showBackButtonParse(currentPage: string) {
      if (currentPage === 'Login' || currentPage === 'Overview') {
        this.showBackButton.next(false);
      } else {
        this.showBackButton.next(true);
      }
   }

   navigateForward(path: string, customHeader = false, header?, state?, navOptions?): void {

    if (!navOptions) {
      navOptions = {
        animated: false,
        animate: false,
      }
    } else {
      navOptions['animated'] = false;
      navOptions['animate'] = false;
    }
    this.customHeadersStack.push(header);
    this.customHeader = customHeader;
    if (this.customHeader) {
      this.currentHeader.next(header);
    }
    this.state = state;
    this.navCtrl.navigateForward(path, navOptions);
   }

  navRoot(path: string, customHeader = false, header?, state?, navOptions?): void {

    if (!navOptions) {
      navOptions = {
        animated: false,
        animate: false,
      }
    } else {
      navOptions['animated'] = false;
      navOptions['animate'] = false;
    }
    this.customHeadersStack.push(header);
    this.customHeader = customHeader;
    if (this.customHeader) {
      this.currentHeader.next(header);
    }
    this.state = state;
    this.navCtrl.navigateRoot(path, navOptions);
  }

  navigateReplace(path: string, ) {
    this.router.navigate([path], { replaceUrl: true})
  }

   navigateRoot(path): void {
     this.customHeader = false;
     this.customHeadersStack = [];
     this.state = undefined;
     this.navCtrl.navigateRoot(path, {
      animated: false,
    });
   }

   back(): void {
    this.customHeadersStack.pop()
    this.customHeader = false;
    const route = this.checkRouteHistory();
    if(route) {
      // this.navCtrl.navigateRoot(path);
      this.navRoot(route.destination, false, '', {child: route.child})
    } else {
      this.navCtrl.pop();
    }
   }

   checkRouteHistory() {
     if(this.routeHistory && this.routeHistory.hasOwnProperty('origin') && this.routeHistory.hasOwnProperty('destination')) {
       const origin = this.routeHistory.origin;
       const currentRoute = this.router.url;
       if(currentRoute && currentRoute.indexOf(origin) >= 0) {
         return this.routeHistory;
       } else {
         return false;
       }
     }
   }

   toggleHeader() {
     this.isHeaderEnabled.next(true);
   }

   disableHeader() {
    this.isHeaderEnabled.next(false);
   }

   enableHeader() {
    this.isHeaderEnabled.next(true);
   }

   setTitle(title) {
    this.currentHeader.next(title)
   }

}
