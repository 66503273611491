import { Availability } from "./availability";
import { SwapSettings } from './swap-settings';

export class Group{

  public 'id' : number;
  public 'care-type' : string;
  public 'name' : string;
  public 'location-id': number;
  public swapSetting: SwapSettings;

  public availabilities: Array<Availability>;

  public fill(data){
    this['id'] = data['id'];
    this['care-type'] = data['care-type'];
    this['name'] = data['name'];
    this['location-id'] = data['location-id'];

    return this;
  }
}
