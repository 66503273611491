import { ChildSwapRequest } from './../../models/child-swap-request';
import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Transformer } from './../../interface/transformer';
import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import * as moment from 'moment';

/*
  Generated class for the ChildPresenceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ChildSwapRequestProvider extends JsonApiHelpProvider implements Transformer {

  constructor(public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = '/v1/child-swap-requests';
  }

  transform(data: JsonAPI) {

    let rc;
    if (data.multiple()) {
      // Transform multiple objects
      rc = [];
      data.data.forEach(channel => {

        let tmp = this.generate(channel);
        rc.push(tmp);
      });

    } else {
      // Transform one object
      rc = this.generate(data.data);
    }

    return rc;
  }

  public request(swap: ChildSwapRequest) {
    // Convert into object
    let vm   = this;
    let data = {
      'data': {
        'type'         : 'child-swaps',
        'attributes'   : {
          'from-date'       : moment(swap['from-date']).format('YYYY-MM-DD'),
          'to-date'         : moment(swap['to-date']).format('YYYY-MM-DD'),
          'absent-from-time': swap['absent-from-time'],
          'absent-to-time'  : swap['absent-to-time'],
          'attend-from-time': swap['attend-from-time'],
          'attend-to-time'  : swap['attend-to-time'],
          'description'     : ''
        },
        'relationships': {
          'child'       : {
            'data': {
              'type': 'children',
              'id'  : swap.child.id
            }
          },
          'absent-group': {
            'data': {
              'type': 'groups',
              'id'  : swap['absent-group'].id.toString()
            }
          },
          'attend-group': {
            'data': {
              'type': 'groups',
              'id'  : swap['attend-group'].id.toString()
            }
          }
        }
      }
    };

    return this.api.post(vm.baseEndpoint, data).then(data => {
      return vm.transform(data);
    }, err => {
      return;
    });

  }

  // Fill based on input
  protected generate(data) {
    // let chatchannel = new ChatChannel();
    // let attributes = data.attributes;
    // attributes.id = data.id;
    // chatchannel.fill(attributes);

    // Set relationships for each
    //child.person = new HasOne(this.api, data.relationships.person);
    // user.relation = new HasOne(this.api, data.relationships.relation);
    return;
  }


}
