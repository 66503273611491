//import { HasOne } from './jsonapi/hasone';

export class Image {

  public 'id': number;
  public 'url': string;
  public 'src': string;
  public 's_url': string;
  public 'w':   number;
  public 'h':   number;
  // public 'external-id': string;
  // public 'person-id': number;
  // public 'location-id': number;
  public 'created-at': string;
  public 'updated-at': string;

  // public 'person' : HasOne;
  // public 'relation' : HasOne;


  public fill(data) {
    this.id = data.id;
    this.url = data.url;
    this['created-at'] = data['created-at'];
    this['updated-at'] = data['updated-at'];
    this.s_url = data.resizes.small;
    return this;
  }


}
