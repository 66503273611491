import { Child } from "./child";
import { Group } from "./group";


export class ChildSwapRequest{

    public 'from-date' : Date;
    public 'to-date' : Date;
    public 'absent-from-time': string;
    public 'absent-to-time': string;
    public 'attend-from-time': string;
    public 'attend-to-time': string;
    public 'absent-group' : Group;
    public 'attend-group' : Group;
    public 'description': string;
    public 'child' : Child;
  
  public fill(data){

    this['from-date'] = data['from-date'];
    this['to-date'] = data['to-date'];
    this['absent-from-time'] = data['absent-from-time'];
    this['absent-to-time'] = data['absent-to-time'];
    this['attend-from-time'] = data['attend-from-time'];
    this['attend-to-time'] = data['attend-to-time'];
    this['absent-group'] = data['absent-group'];
    this['attend-group'] = data['attend-group'];
    this['child'] = data['child'];
    this['description'] = data['description'];

    return this;
  }
}
