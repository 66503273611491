import { HasOne } from './jsonapi/hasone';

export class DayLine{

    public "id": number;
    public "date": any;
    public "source": string;
    public "reason": string;
    public "comment": string;
    public "duration": number;
    public "child-id": number;
    public "group-id": number;
    public "time-from": string;
    public "time-to": string;
    public "is-present": boolean;
    public "has-checked-in": boolean;
    public "is-cancelled": boolean;
    public "is-swap": boolean;
    public "is-requested": boolean;
    public "normalized-reason": string;

    public 'child' : HasOne;
    public 'group' : HasOne;
	
	public fill(data){
		this.id 		    		= data.id;
        this["date"]                = data["date"]
        this["source"]              = data["source"]
        this["reason"]              = data["reason"]
        this["comment"]             = data["comment"]
        this["duration"]            = data["duration"]
        this["child-id"]            = data["child-id"]
        this["group-id"]            = data["group-id"]
        this["time-from"]           = data["time-from"]
        this["time-to"]             = data["time-to"]
        this["is-present"]          = data["is-present"]
        this["has-checked-in"]      = data["has-checked-in"]
        this["is-cancelled"]        = data["is-cancelled"]
        this["is-swap"]             = data["is-swap"]
        this["is-requested"]        = data["is-requested"]
        this["normalized-reason"]   = data["normalized-reason"]

		return this;
	}

	
    public formatType(){
        let rc = "";

        switch(this.source){
            case "Contract":
                rc = "Vaste dag";
            break;
            default:
                rc = "Onbekende regel";
                if(this.source != null && this.source.length > 0){
                    rc = this.source;
                }
            break;
        }

        return rc;
    }


    public reasonHumanized(){
        let reason = this.reason;

        reason = reason.replace(/\+/g, '');
        reason = reason.replace(/\-/g, '');

        return reason;
    }
	

}


