import { HasOne }  from "./jsonapi/hasone";
import { HasMany } from "./jsonapi/hasmany";
import { Image }   from "./image";
import {Movie} from './movie'

export class DiaryEvent{

    public 'id' : number;
    public 'child-id' : number;
    public 'eventable-id' : number;
    public 'eventable-type' : string;
    public 'title' : string;
    public 'updated-at' : Date;
    public 'created-at' : any;

    public 'image' : HasOne; // Deprecated in future versions
    public 'images' : any;
    public 'movies' : HasMany;

	
	public fill(data){
        this.id 			    = data.id;
        this.title              = data.title;
        this['eventable-id']    = data['eventable-id'];
        this['eventable-type']  = data['eventable-type'];
        this['child-id']        = data['child-id'];
        this['created-at']      = data['created-at'];
        this['updated-at']      = data['updated-at'];
        
		return this;
	}

	

	

}
