import { DiaryEvent } from './../../models/diary-event';
import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';
import { Transformer } from '../../interface/transformer';
import { HasOne } from '../../models/jsonapi/hasone';
import { HasMany } from '../../models/jsonapi/hasmany';

/*
  Generated class for the DiaryEventsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class DiaryEventsProvider extends JsonApiHelpProvider implements Transformer {

  constructor( public api: JsonapiProvider ) {
    super(api);
    this.baseEndpoint = "/v1/diary-events";
  }


  transform(data: JsonAPI){
    
      let rc;
      if(data.multiple()){
        // Transform multiple objects
        rc = [];
        data.data.forEach(photo => {
  
          let tmp = this.generate(photo);
          rc.push(tmp);
        });
  
      }else{
        // Transform one object
        rc = this.generate(data.data);
      }
  
      return rc;
    }
  
    cursor( input, cursor, direction, field, limit, page ){
      let vm = this;
      let filter = this.buildFilter(input);
      let _cursor = cursor;
      let _direction = direction || "desc";
      let _field = field || "created_at";
      let _limit = limit ||  10;
      let _page = page || 1;

      // Get live data
      return this.api.get(vm.baseEndpoint + "?cursor=" + encodeURIComponent(_cursor) + "&cursor_direction=" + _direction + "&cursor_field=" + _field + "&limit=" + _limit + "&page=" + _page + "&"  + filter).then(filterData => {
        return vm.transform(filterData);
      }, err => {
        return;
      })

    }

    // Fill based on input
    protected generate(data){
      let photo = new DiaryEvent();
      let attributes = data.attributes;
      attributes.id = data.id;
      photo.fill(attributes);
      // Set relationships for each
      if(data.relationships.image != null){
        photo.image = new HasOne(this.api, data.relationships.image);
      }
      if(data.relationships.images != null){
        photo.images = new HasMany(this.api, data.relationships.images);
      }
      if(data.relationships.movies != null && data.relationships.movies){
        photo.movies = new HasMany(this.api, data.relationships.movies);
      }

      
      return photo;
    }
}
