export class Product {
  "id": number;
  "type": string;
  "type_id": number;
  "description": string;
  "start": string;
  "end": string;
  "flex": boolean;
  "created_at": string;
  "updated_at": string;
}
