export class ChildRequest implements ChildRequestInterface{

    public "id":  number;
    public "date":  Date | string;
    public "description":  string;
    public "status":  string;
    public "child-request-id": number;
    public "child-id":  number;
    public "group-id":  number;
    public "relation-id": number;
    public "from-time": string;
    public "to-time": string;
    public "request-type": string;
    public "created-at":  Date | string;
    public "updated-at":  Date | string;
    public "to-child-request-data": any;

  public fill(data){
    this.id 			    = data.id;
    this['date'] = data['date'];
    this['description'] = data['description'];
    this['status'] = data['status'];
    this['child-request-id'] = data['child-request-id'];
    this['child-id'] = data['child-id'];
    this['group-id'] = data['group-id'];
    this['relation-id'] = data['relation-id'];
    this['from-time'] = data['from-time'];
    this['to-time'] = data['to-time'];
    this['request-type'] = data['request-type'];
    this['created-at'] = data['created-at'];
    this['updated-at'] = data['updated-at'];
    this['to-child-request-data'] = data['to-child-request-data'];

    return this;
  }
}

export interface ChildRequestInterface {
  "id":  number;
  "date":  Date | string;
  "description":  string;
  "status":  string;
  "child-request-id": number;
  "child-id":  number;
  "group-id":  number;
  "relation-id": number;
  "from-time": string;
  "to-time": string;
  "request-type": string;
  "created-at":  Date | string;
  "updated-at":  Date | string;
  "to-child-request-data": any;
}