export class NutritionCategory{

  public 'id'           : number;
  public 'name'         : string;

  public 'created-at' : Date;
  public 'updated-at' : Date;

  public fill(data){
    this.id 						      = data.id;
    this['name'] 			    	  = data['name'];
    this['created-at'] 				= data['created-at'];
    this['updated-at'] 				= data['updated-at'];

    return this;
  }

}
