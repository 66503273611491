import {Storage} from "@ionic/storage";
import {Injectable} from "@angular/core";
import {ApiProvider} from "@core/providers/api/api";
import {WdcApiProvider} from "@core/providers/wdc-api/wdc-api";
import {TranslateService} from "@ngx-translate/core";
import {Translations} from "@core/providers/translation/translations";
// import * as printf from 'printf'
import printf from 'printf'
import * as moment from "moment";

@Injectable()
export class TranslationService {
    private default = 'nl'
    public language = this.default

    constructor(public storage: Storage, private api: ApiProvider) {
    }

    public setLanguage(data) {
        console.log('SET LANGUAGE', data)
        return this.api.post('/v1/users', {
            locale: data
        }).then(() => {
            return this.setLanguageLocally(data)
        })
    }

    public formatDate(m, format, lang = this.language) {
        m.locale(lang)
        return m ?  m.format(format) : ''
    }

    private setLanguageLocally(data) {
        this.language = data
        moment.locale(data)
        return this.storage.set('language', data  || this.default)
    }

    // Helper functions to get authentication data
    public async getLanguage() {
        this.language = (await this.storage.get('language')) || 'nl'
        return this.language
    }

    public _(text, lang = this.language) {
        let translation = '';
        if(lang == 'en') {
            translation = Translations.en[text]
        }
        return translation ? translation : text;
    }

    public _f(text, format, lang = this.language) {
        if(lang == 'en') {
            return printf(Translations.en[text], format)
        }
        return printf(text, format);
    }

    public initialize() {
        return this.api.get<any>('/v1/users?me=').then((data) => {
            console.log(data)
            const hasAUser = data.data?.id
            if (!hasAUser) {
                console.log('!auser')
                return this.setLanguageLocally(this.default)
            }else {
                console.log('auser')
                return this.setLanguageLocally(data.data.attributes.locale)
            }
        }).catch((e) => {
            return this.setLanguageLocally(this.default)
        })
    }
}
