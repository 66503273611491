import { Injectable } from '@angular/core';

// TODO: Resolve pathing in Tsconfig

import { Config } from '../../../config/config';

@Injectable()
export class ConfigProvider {

	private config: Config;

	constructor() {
		this.config = new Config();
	}

	public get(key) {
		return this.config.get(key);
	}
}
