import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ConfigProvider } from '../config/config';
import * as moment from 'moment';
import { TokenProvider } from '../token/token';
import { HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable()
export class ApiProvider {

	baseUrl: String;
	version: String;

	public lastError: string;

	constructor(public http: HttpClient, public storage: Storage, public config: ConfigProvider, public tokens: TokenProvider) {
    this.baseUrl = config.get('API_PROTO') + "://" + config.get("API_HOST");
  }

  public getO(endpoint: string, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		observe?: 'body';
		params?: HttpParams | {
			[param: string]: string | string[];
		};
		reportProgress?: boolean;
		responseType?: 'json';
		withCredentials?: boolean;
	}) {
		return this.http.get(this.baseUrl + endpoint, options);
	}

	public async get<T>(endpoint: string) {
		try {
			endpoint = endpoint.replace('//', '/');
			let result = await new Promise((resolve, reject) => {
				this.http.get(this.baseUrl + endpoint).subscribe(data => {
					resolve(data);
				}, error => {
					reject(this.mapError(error));
				});
			});
			return result as T;
		}
		catch(error) {
			// Something went horribly wrong, like having no internet. You peasant.
			return Promise.reject(error);
		}
	}

	public async post<T>(endpoint: string, data: any) {
		try {

			let result = await new Promise((resolve, reject) => {
				this.http.post(this.baseUrl + endpoint, data).subscribe(data => {
					resolve(data);
				}, error => {
					console.log(error);
				  this.lastError = error.error;
					reject(this.mapError(error));
				});
			});

			return result as T;
		}
		catch(error) {
			// Something went horribly wrong, like having no internet. You peasant.
			return Promise.reject(error);
		}
	}

  public async put<T>(endpoint: string, data: any) {
    try {
    	console.log(data);
      let result = await new Promise((resolve, reject) => {
        this.http.put(this.baseUrl + endpoint, data).subscribe(data => {
          resolve(data);
        }, error => {
          this.lastError = error.error;
          reject(this.mapError(error));
        });
      });

      return result as T;
    }
    catch(error) {
      // Something went horribly wrong, like having no internet. You peasant.
      return Promise.reject(error);
    }
  }

	public async delete<T>(endpoint: string) {

		try {

			let result = await new Promise((resolve, reject) => {
				this.http.delete(this.baseUrl + endpoint).subscribe(data => {
					resolve(data);
				}, error => {
					reject(this.mapError(error));
				});
			});

			return result as T;
		}
		catch(error) {
			// Something went horribly wrong, like having no internet. You peasant.
			return Promise.reject(error);
		}
	}

	public async patch<T>(endpoint: string, data: any) {
		try {

			var result = await new Promise((resolve, reject) => {
				this.http.patch(this.baseUrl + endpoint, data).subscribe(data => {
					resolve(data);
				}, error => {
					reject(this.mapError(error));
				});
			});

			return result as T;
		}
		catch(error) {
			// Something went horribly wrong, like having no internet. You peasant.
			return Promise.reject(error);
		}
	}

	// Raw calls

	public async postRaw<T>(endpoint: string, data: any) {
		try {

			let result = await new Promise((resolve, reject) => {
				this.http.post(this.baseUrl + endpoint, data).subscribe(data => {
					resolve(data);
				}, error => {
					reject(this.mapError(error));
				});
			});

			return result as T;
		}
		catch(error) {
			// Something went horribly wrong, like having no internet. You peasant.
			return Promise.reject(error);
		}
	}


	public refresh(refresh_token: string){
        // Get current authentication data and refresh the token with new data
        let body = {
            grant_type: "refresh_token",
            refresh_token: refresh_token,
            client_id: this.config.get('API_CLIENT_ID'),
            client_secret: this.config.get('API_CLIENT_SECRET')
        }

        // Repeat API post to retrieve access token
        return this.post('/v1/oauth/access_token', body).then(data => {
            // Custom data item that adds the amount of seconds to this date and outputs the unix time.
			data['expires'] = moment().add(data['expires_in'], 's').format('X');
			console.log('Handling accesss token')
            return this.tokens.set(data).then( set => {
                return true;
            });
        }, error => {
            // Check error status
			console.log('Error access token')
            return this.tokens.clear().then( remove => {
                return false;
            });
        });

    }

	// API errors are a mess.
	// This should make a more generic form hopefully
	private mapError(error) : Array<string> {
		let rc = [];
		let errors = { 'errors': [ {'title' : 'Unknown error'}] };
		console.error(error);
		if(error instanceof HttpErrorResponse){
			// Check if there is a body and add these to the error message
			if(error.error != null && error.error['error_description'] != null){
				errors['errors'] = [{'title' : error.error['error_description']}]
			}else{
				// Convert to error object
				if(error?.error?.message) {
					console.log(error.error)
					errors['errors'] = [{'title': error.error.message}]
				}else {
					errors['errors'] = [{'title': error.message}];
				}
			}
		}else{
			if(error.status != 0){
				try{
					errors = JSON.parse(error.error);
				}catch(e){
					console.log("Error parsing error response");
				}
			}
		}

		// Check if it is an array
		if(errors.errors != null && errors.errors instanceof Array){
			// Local development most likely. Pick the first one in the array
			if(errors.errors.length > 0){
				// Isn't returned by the api (REALLY....)
				for(let i in errors.errors){
					let item = errors.errors[i];
					if(item.title != null){
						rc.push(item['title']);
					}
				}
			}
		}else{
			// I suck at naming things I noticed
			rc.push(errors['error_description']);
		}

		return rc;
	}
}
