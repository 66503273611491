import { Product } from './product';

export interface PlacementFilters {
  filter: {
    child: number;
    group: number;
  };
}

export class Placement {
  id: number; a;
  start: string;
  end: string;
  day: string;
  hours: string;
  'group-id': number;
  'child-id': number;
  'product-id': number;
  'base-hours': string;
  'weeks-per-year': string;
  'created-at': string;
  'updated-at': string;
  'related-product': Product = new Product();
  'placement-type': string;
}
