import { ChatChannel } from './../../models/chat-channel';
import { JsonAPI } from './../../models/jsonapi/jsonapi';
import { JsonapiProvider } from './../jsonapi/jsonapi';
import { Transformer } from './../../interface/transformer';
import { Injectable } from '@angular/core';
import { JsonApiHelpProvider } from '../json-api-help/json-api-help';

/*
  Generated class for the ChatChannelsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ChatChannelsProvider extends JsonApiHelpProvider implements Transformer {

  constructor( public api: JsonapiProvider) {
    super(api);
    this.baseEndpoint = "/v1/chat-channels";
  }

  transform(data: JsonAPI){
    
    let rc;
    if(data.multiple()){
      // Transform multiple objects
      rc = [];
      data.data.forEach(channel => {
        
        let tmp = this.generate(channel);
        rc.push(tmp);
      });
      
    }else{
      // Transform one object
      rc = this.generate(data.data);
    }
    
    return rc;
  }
  
  
  public markRead( channel : ChatChannel ){
    let vm = this;
    return vm.api.get(this.baseEndpoint + '/seen/' + channel.id );
  }

  // Fill based on input
  protected generate(data){
    let chatchannel = new ChatChannel();
    let attributes = data.attributes;
    attributes.id = data.id;
    chatchannel.fill(attributes);
    
    // Set relationships for each
    //child.person = new HasOne(this.api, data.relationships.person);
    // user.relation = new HasOne(this.api, data.relationships.relation);
    
    return chatchannel;
  }
  

}
