import { Injectable, OnInit } from "@angular/core";
import { WdcApiProvider } from "../wdc-api/wdc-api";
import { SwapSettings } from "@core/models/swap-settings";
import { LoadingProvider } from "../loading/loading";
import { Group } from '@core/models/group';

@Injectable({
  providedIn: "root",
})
export class SwapSettingsService implements OnInit {
  swapSettings = new Array<SwapSettings>();
  globalSetting: SwapSettings;

  constructor(
    public wdcApiService: WdcApiProvider,
    public loadingService: LoadingProvider
  ) {}
  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  /**
   * Returns either the setting matching requirements or a global setting if existing
   * @param groupId Default is null
   * @param locationId Default is null
   */
  getSwapSetting(groupId = null, locationId = null) {
    console.log(groupId, locationId)
    if (groupId != null) {
      groupId = parseInt(groupId);
    }
    return new Promise<any>((resolve, reject) => {
      if (this.swapSettings.length) {
        if (groupId == null && locationId == null) {
          resolve(this.globalSetting);
        } else {
          const setting = this.filterSettings(groupId, locationId);
          resolve(setting ? setting : this.globalSetting);
        }
      } else {
        reject('No swap settings')
      }
    });
  }

  private filterSettings(groupId, locationId) {
    console.log(groupId, locationId)
    const swapSettings = this.swapSettings.filter((el) => {
      if (el.groupId === null && locationId === null) {
        return el;
      }
      if (el.groupId === groupId && el.locationId === locationId) {
        return el;
      }
      if (el.groupId === null && el.locationId === locationId) {
        return el;
      }
      if (el.groupId === groupId && el.locationId === null) {
        return el;
      }
    });

    let swapSetting = swapSettings.find(
      (el) => el.groupId === groupId && el.locationId === locationId
    );

    if (!swapSetting) {
      swapSetting = swapSettings.find(
        (el) => el.groupId === groupId && locationId === null
      );
    }
    if (!swapSetting) {
      swapSetting = swapSettings.find(
        (el) => el.groupId === null && locationId === locationId
      );
    }
    if (!swapSetting) {
      swapSetting = swapSettings.find(
        (el) => el.groupId === groupId && locationId === null
      );
    }

    return swapSetting;
  }

  async fetchSettings() {
    if (!this.swapSettings.length)
      await this.wdcApiService
        .getSwapSettings()
        .then(async (data: any) => {
          console.log(data);
          if (data.data && data.data.length) {
            this.swapSettings = data.data.map((el) => {
              const swapSetting = new SwapSettings(
                el.attributes.schooldays,
                el.attributes.holidays,
                el.attributes["location-id"],
                el.attributes["group-id"],
                el.attributes["swap-enabled"],
                el.attributes["min-scope"],
                el.attributes["max-scope"], // No one seems to use this
                el.attributes["min-past"], // No one uses this as well
                el.attributes["max-past"], // Same here
                el.attributes["min-future"],
                el.attributes["max-future"],
                el.attributes["closing-days"]
              );
              if (swapSetting.isGlobal()) {
                this.globalSetting = swapSetting;
              }

              return swapSetting;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
  }
}
